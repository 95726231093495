import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import {
   getCurrentUser,
   getCountries,
   getTotalPendingSubrogation,
   getTotalNewStatusContactUs,
   getTotalUnreadStatusLineNotify
} from 'src/redux/actions/authenAction';
import { useDialog } from 'src/hooks/dialog';

const WaitingUser = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const location = useLocation();

   useEffect(() => {
      const getUser = async () => {
         try {
            const { result } = await dispatch(getCurrentUser());
            if ('reset_password' in result && !result.reset_password) {
               navigate('/change-password', { replace: true, state: { from: location } });
            }
            navigate(location);
         } catch (error) {
            navigate('/login', { replace: true, state: { from: location } });
         }
      };
      getUser();
   }, [dispatch, location, navigate]);

   return <LoadingScreen />;
};

export function PrivateRoute({ component: Component }) {
   const { user } = useAuth();
   const countries = useSelector((state) => state?.authen?.countries);
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const dialog = useDialog();

   let isAuth = localStorage.getItem('access-token');
   // console.log('private route is in prossesing ')

   useEffect(() => {
      const dispatchCurrentUser = async () => {
         try {
            if ((countries ?? []).length === 0) {
               await dispatch(getCountries());
            }
            await dispatch(getCurrentUser());
            await dispatch(getTotalPendingSubrogation());
            await dispatch(getTotalNewStatusContactUs());
            await dispatch(getTotalUnreadStatusLineNotify());
         } catch (error) {
            console.log('error', error);
            // dialog.error(error);
            navigate('/login', { replace: true, state: { from: location } });
         }
      };
      dispatchCurrentUser();
      // eslint-disable-next-line
   }, [location]);
   

   if (Boolean(isAuth)) {
      //accessToken
      if (!user) {
         return <WaitingUser />;
      } else {
         if (
            user.hasOwnProperty('reset_password') &&
            user.reset_password === false &&
            location.pathname !== '/change-password'
         ) {
            return <Navigate to="/change-password" state={{ from: location }} />;
         }
         return <Component />;
      }
   }
   return <Navigate to="/login" state={{ from: location }} />;
}
PrivateRoute.propTypes = {
   component: PropTypes.any.isRequired
};
