import * as React from "react";
import { SurveyQuestionUncontrolledElement } from "./reactquestion_element";
import { QuestionTextModel } from "survey-core";
import { ReactQuestionFactory } from "./reactquestion_factory";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";

export class SurveyQuestionText extends SurveyQuestionUncontrolledElement<
  QuestionTextModel
> {
  private _isWaitingForEnter = false;
  //controlRef: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props);
    //this.controlRef = React.createRef();
  }

  // enable survey => add handleDateChange
  handleDateChange = (value: moment.Moment | null, type: string) => {
    let finalValue: string = '';
    if (value) {
      switch (type) {
        case "date":
          finalValue = moment(value).format("DD/MM/YYYY");
          break;
        case "time":
          finalValue = moment(value).format("HH:mm");
          break;
        default:
          finalValue = moment(value).startOf('minute').format("YYYY-MM-DDTHH:mm");
          break;
      }
    }

    this.updateValueOnEvent({ target: { value: finalValue } });
  };

  protected renderInput() {
    const inputClass = (this.question as QuestionTextModel).getControlClass();
    var onKeyDown: any = null;
    var onKeyUp: any = null;
    var onCompositionUpdate: any = null;
    if (this.question.isInputTextUpdate) {
      onKeyDown = (e: any) => (this._isWaitingForEnter = e.keyCode === 229);
      onKeyUp = (e: any) => {
        if (!this._isWaitingForEnter || e.keyCode === 13) {
          this.updateValueOnEvent(e);
          this._isWaitingForEnter = false;
        }
      };
      onCompositionUpdate = (e: any) => {
        e.persist();
        setTimeout(() => {
          this.updateValueOnEvent(e);
        }, 1);
      };
    } else {
      //https://github.com/surveyjs/survey-library/issues/3384
      onKeyUp = (e: any) => {
        if (e.keyCode === 13) {
          this.updateValueOnEvent(e);
        }
      };
    }

    var onChange = (e: any) => {
      if (e.target === document.activeElement) {
        if (this.question.isInputTextUpdate) {
          this.updateValueOnEvent(e);
        }
      } else {
        this.updateValueOnEvent(e);
      }
    };
    const placeholder = this.question.renderedPlaceholder;
    if (this.question.isReadOnlyRenderDiv()) {
      return <div>{this.question.value}</div>;
    }
    // enable survey => add this.question.inputType === "date" | "time" | "datetime-local"
    return (
      <React.Fragment>
        {this.question.inputType === "date" ? (
          <DatePicker
            disabled={this.isDisplayMode}
            format={"DD/MM/YYYY"}
            value={
              this.question.value
                ? moment(this.question.value , "DD/MM/YYYY")
                : null
            }
            onChange={value => this.handleDateChange(value, "date")}
          />
        ) : this.question.inputType === "time" ? (
          <TimePicker
            format={"HH:mm"}
            disabled={this.isDisplayMode}
            value={
              this.question.value ? moment(this.question.value, "HH:mm") : null
            }
            onChange={value => this.handleDateChange(value, "time")}
          />
        ) : this.question.inputType === "datetime-local" ? (
          <DatePicker
            disabled={this.isDisplayMode}
            value={this.question.value ? moment(this.question.value) : null}
            showTime
            format="DD/MM/YYYY HH:mm"
            onChange={value => this.handleDateChange(value, "datetime")}
          />
        ) : (
          <input
            id={this.question.inputId}
            disabled={this.isDisplayMode}
            className={inputClass}
            type={this.question.inputType}
            //ref={this.controlRef}
            ref={(input) => (this.control = input)}
            style={this.question.inputStyle}
            maxLength={this.question.getMaxLength()}
            min={this.question.renderedMin}
            max={this.question.renderedMax}
            step={this.question.renderedStep}
            size={this.question.inputSize}
            placeholder={placeholder}
            list={this.question.dataListId}
            autoComplete={this.question.autoComplete}
            onBlur={this.updateValueOnEvent}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onCompositionUpdate={onCompositionUpdate}
            aria-required={this.question.ariaRequired}
            aria-label={this.question.ariaLabel}
            aria-invalid={this.question.ariaInvalid}
            aria-describedby={this.question.ariaDescribedBy}
          />
        )}
       </React.Fragment>
    );
  }
  protected renderElement(): JSX.Element {
    return (
      this.question.dataListId ?
        <div>
          { this.renderInput() }
          { this.renderDataList() }
        </div>:
        this.renderInput()
    );
  }
  private renderDataList(): JSX.Element | null {
    if (!this.question.dataListId) return null;
    var items = this.question.dataList;
    if (items.length == 0) return null;
    var options: JSX.Element[]  = [];
    for (var i = 0; i < items.length; i++) {
      options.push(<option key={"item"+i} value={items[i]}></option>);
    }
    return <datalist id={this.question.dataListId}>{options}</datalist>;
  }
  // protected updateDomElement() {
  //   this.control = this.controlRef.current;
  //   super.updateDomElement();
  // }
}

ReactQuestionFactory.Instance.registerQuestion("text", (props) => {
  return React.createElement(SurveyQuestionText, props);
});
