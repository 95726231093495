import { useRef, useEffect } from 'react';

export default function useIsMountedRef() {
   const isMounted = useRef(true);

   useEffect(
      () => () => {
         isMounted.current = false;
      },
      []
   );

   return isMounted;
}

// ------- วิธีใช้  ----------//

// import useIsMountedRef from 'src/hooks/useIsMountedRef';

// const isMountedRef = useIsMountedRef();
// const [products, setProducts] = useState(null);
// const [isLoading, setIsLoading] = useState(null);

// const getProducts = useCallback(() => {
//     setIsLoading(true)
//     axios
//         .get('/api/get-products')
//         .then((response) => {
//             if (isMountedRef.current) {
//                 setIsLoading(false)
//                 setProducts(response.data.products);
//             }
//         })
//         .catch(error => {
//             setIsLoading(false)
//             console.log(error)
//         });
// }, [isMountedRef]);

// useEffect(() => {
//     getProducts();
// }, [getProducts]);
