import React, { useState, useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { axiosToken } from 'src/common/AxiosToken';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Page from 'src/components/Page';
import Header from 'src/components/Header';
import Toolbar from './components/Toolbar';
import CustomersChart from './components/CustomersChart';
import NoPermissionView from 'src/views/errors/noPermission/NoPermissionView';
import { usePermissions } from 'src/hooks/usePermissions';
import historyParamsPage from 'src/hooks/storeParams';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
   },
   content: {
      padding: 16,
      height: '100%'
   }
}));

const INITIAL_PARAMS = {
   start_date: moment(new Date()).startOf('month').toISOString(),
   end_date: moment().toISOString(),
   date_type: 'one_month',
   sort_by: 'count',
   sort_direction: 'desc'
};

const SurvayResponseReport = () => {
   const classes = useStyles();
   const isMountedRef = useIsMountedRef();
   const [searchParams, setSearchParams] = useSearchParams();
   const { can } = usePermissions();
   const { getParamHistory, storeParamsAsHistory } = historyParamsPage();
   const [isLoading, setIsLoading] = useState(false);
   const [surveyResponseCount, setSurveyResponseCount] = useState([]);
   const [params, setParams] = useState({
      ...INITIAL_PARAMS,
      ...(Array.from(searchParams.keys()).length > 0 && {
         date_type: searchParams.get('date_type') ?? 'one_month',
         start_date: searchParams.get('start_date') ?? moment(new Date()).startOf('month').toISOString(),
      }),
      ...(Array.from(searchParams.keys()).length === 0 && getParamHistory())
   });
   const isFireOnFirstLoadRef = useRef(searchParams.get('date_type'));

   const getQuestionTypeUsageData = useCallback(
      (queryParams) => {
         setIsLoading(true);
         axiosToken
            .get('survey-response-report', { params: queryParams })
            .then((response) => {
               if (isMountedRef.current) {
                  setSurveyResponseCount(response.data.survey_response);
                  setIsLoading(false);
               }
            })
            .catch((error) => {
               setIsLoading(false);
               console.log('error', error);
               //dialog.error(error)
            });
      },
      [isMountedRef]
   );

   useEffect(() => {
      if (can(['VIEW'], 'SURVEY_RESPONSE_REPORT')) {
         if (isFireOnFirstLoadRef.current) {
            getQuestionTypeUsageData(params);
         }
         isFireOnFirstLoadRef.current = false;
      }
   }, [getQuestionTypeUsageData]);

   const startSearching = (value = {}) => {
      const newParams = { ...params, ...value, page_index: 0 };
      getQuestionTypeUsageData(newParams);
      setParams(newParams);
      return newParams;
   };

   const startSearchingAndStoreParams = (value) => {
      const newParams = startSearching(value);
      storeParamsAsHistory(newParams);
   };

   const onResetClick = () => {
      getQuestionTypeUsageData(INITIAL_PARAMS);
      setParams(INITIAL_PARAMS);
      setSearchParams({});
   };

   if (!can(['VIEW'], 'SURVEY_RESPONSE_REPORT')) {
      return <NoPermissionView />;
   }
   return (
      <Page className={classes.root} title="Survey response report">
         <Container maxWidth={false}>
            <Header beadcrumbs={[{ text: 'Dashboard', link: '/app' }, { text: 'Survey response report' }]} title="Survey response report" />
            <Toolbar
               params={params}
               setParams={setParams}
               startSearching={startSearching}
               startSearchingAndStoreParams={startSearchingAndStoreParams}
               onResetClick={onResetClick}
               isLoading={isLoading}
            />
            <Box mt={3}>
               <CustomersChart
                  params={params}
                  setParams={setParams}
                  startSearching={startSearching}
                  surveyResponseCount={surveyResponseCount}
                  isLoading={isLoading}
               />
            </Box>
         </Container>
      </Page>
   );
};

export default SurvayResponseReport;
