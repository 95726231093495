import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { DialogProvider } from 'src/hooks/dialog';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/common/chartjs';
import theme from 'src/theme';
import Routes from 'src/Routes';
import store from 'src/redux/store';
import { PermissionsProvider } from './hooks/usePermissions';
import ErrorBoundary from './views/errors/errorBoundary/ErrorBoundaryView';
// import 'enable-survey-react/style.css';
// import { SurveyConfig } from 'enable-survey-react';

// import 'enable-survey-react/style.css';
// const CREATOR_API = process.env.REACT_APP_CREATOR_API_URL || '';
import { SurveyConfig as SurveyConfigInit } from 'enable-survey-react';

const App = () => {
   moment.tz.setDefault('Asia/Bangkok');

   return (
      <ThemeProvider theme={theme}>
         <ErrorBoundary>
            <Provider store={store}>
               <PermissionsProvider store={store}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                     <DialogProvider>
                        <SnackbarProvider maxSnack={3}>
                           <SurveyConfigInit uploadUrlService={''} />
                           <GlobalStyles />
                           <Routes />
                        </SnackbarProvider>
                     </DialogProvider>
                  </MuiPickersUtilsProvider>
               </PermissionsProvider>
            </Provider>
         </ErrorBoundary>
      </ThemeProvider>
   );
};

export default App;
