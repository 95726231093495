import { axiosToken } from 'src/common/AxiosToken';

export function getCurrentUser() {
   return async (dispatch, getState) => {
      const linkCheckAuth = '/self';
      try {
         let json = await axiosToken.get(linkCheckAuth);
         const { my_filter = [], ...restJson } = json.data;
         // ประวัติการ filter ของหน้าต่าง
         const historyPagesParamsFromDb = my_filter.reduce((result, item) => {
            const { page_name, filter_params } = item;
            result[page_name] = filter_params;
            return result;
         }, {});
         const prevParamsHistory = getState().authen.historyPagesParams;
         dispatch(
            authenticationSuccess(
               {
                  avatarColor: randomColors[rendomNum(10)],
                  ...restJson
               },
               { ...historyPagesParamsFromDb, ...prevParamsHistory }
            )
         );
         localStorage.setItem('userid', json.data.id);
         return { data: 'success', result: json.data };
      } catch (error) {
         localStorage.removeItem('access-token');
         localStorage.removeItem('userid');
         dispatch(authenticationFailure(error));
         throw error;
      }
   };
}

export function setToken(token) {
   return async (dispatch) => {
      try {
         localStorage.setItem('access-token', token);
         return dispatch(getCurrentUser());
      } catch (error) {
         localStorage.removeItem('access-token');
         dispatch(authenticationFailure(error));
         return { data: 'unsuccess', error };
      }
   };
}

export function getCountries() {
   return async (dispatch) => {
      try {
         const countries = await axiosToken.get('location/countries');
         return dispatch(getAllCountries(countries.data));
      } catch (error) {
         return getAllCountriesFailure(error);
      }
   };
}

export function getHostPdf() {
   return async (dispatch) => {
      try {
         const host = await axiosToken.get('prefix-pdf');
         return dispatch(getCurrentHostPdf(host.data));
      } catch (error) {
         return getCurrentHostPdfFailure(error);
      }
   };
}
export function getTotalPendingSubrogation() {
   return async (dispatch) => {
      try {
         const host = await axiosToken.get('subrogate-pending-request');
         return dispatch(setTotalPendingSubrogation(host.data.totalPending));
      } catch (error) {
         console.log('error', error);
      }
   };
}

export function getTotalNewStatusContactUs() {
   return async (dispatch) => {
      try {
         const host = await axiosToken.get('new-status');
         return dispatch(setTotalNewStatusContactUs(host.data.totalNewStatus));
      } catch (error) {
         console.log('error', error);
      }
   };
}

export function storeParams(pageName, params) {
   return async (dispatch, getState) => {
      const data = {
         page_name: pageName,
         filter_params: params
      };
      try {
         const res = await axiosToken.post('user-filter', data);
      } catch (error) {
         console.log('error', error);
      }
      const newHistoryParams = {
         [pageName]: params
      };
      const prevParamsHistory = getState().authen.historyPagesParams;
      return dispatch(
         setHistoryParamsPages({
            ...prevParamsHistory,
            ...newHistoryParams
         })
      );
   };
}

export function getTotalUnreadStatusLineNotify() {
   return async (dispatch) => {
      try {
         const host = await axiosToken.get('count-unread-status');
         return dispatch(setTotalUnreadStatusLineNotify(host.data.totalUnreadStatus));
      } catch (error) {
         console.log('error', error);
      }
   };
}

function rendomNum(num) {
   return Math.floor(Math.random() * num);
}
const randomColors = [
   // "#4A90E2",
   // "#50E3C2",
   // "#F5A623",
   // "#9013FE",
   // "#D0021B",
   // "#F8E71C",
   // "#7ED321",
   // "#9B9B9B",
   // "#BD10E0",
   // "#8B572A",

   // "#55efc4",
   // "#ffeaa7",
   // "#81ecec",
   // "#fab1a0",
   // "#74b9ff",
   // "#ff7675",
   // "#b8e994",
   // "#a29bfe",
   // "#fd79a8",
   // "#b2bec3",

   '#698396',
   '#A9C8C0',
   '#DBBC8E',
   '#AE8A8C',
   '#7C98AB',
   '#F5BFD2',
   '#E5DB9C',
   '#D0BCAC',
   '#BEB4C5',
   '#E6A57E'
];

// // Handle HTTP errors since fetch won't.
// function handleErrors(response) {
//     if (!response.ok) {
//         throw Error(response.statusText);
//     }
//     return response;
// }

export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';
export const ALL_COUNTRIES = 'ALL_COUNTRIES';
export const ALL_COUNTRIES_FAILURE = 'ALL_COUNTRIES_FAILURE';
export const CURRENT_HOST_PDF = 'CURRENT_HOST_PDF';
export const CURRENT_HOST_PDF_FAILURE = 'CURRENT_HOST_PDF_FAILURE';
export const LOGGED_OUT = 'LOGGED_OUT';
export const CURRENT_TOTAL_PENDING_SUBROGATION = 'CURRENT_TOTAL_PENDING_SUBROGATION';
export const CURRENT_TOTAL_NEW_STATUS_CONTACT_US = 'CURRENT_TOTAL_NEW_STATUS_CONTACT_US';
export const STORE_HISTORY_PARAMS_PAGES = 'STORE_HISTORY_PARAMS_PAGES';
export const CURRENT_TOTAL_UNREAD_STATUS_LINE_NOTIFY = 'CURRENT_TOTAL_UNREAD_STATUS_LINE_NOTIFY';

export const authenticationSuccess = (user, historyPagesParamsFromDb) => ({
   type: AUTHENTICATION_SUCCESS,
   payload: { user, historyPagesParams: historyPagesParamsFromDb }
});

export const authenticationFailure = (error) => ({
   type: AUTHENTICATION_FAILURE,
   payload: { error }
});

export const getAllCountries = (countries) => ({
   type: ALL_COUNTRIES,
   payload: { countries }
});
export const getAllCountriesFailure = (error) => ({
   type: ALL_COUNTRIES_FAILURE,
   payload: { error }
});

export const getCurrentHostPdf = (host) => ({
   type: CURRENT_HOST_PDF,
   payload: { host }
});

export const getCurrentHostPdfFailure = (error) => ({
   type: CURRENT_HOST_PDF_FAILURE,
   payload: { error }
});

export const setTotalPendingSubrogation = (totalPendingSubrogation) => ({
   type: CURRENT_TOTAL_PENDING_SUBROGATION,
   payload: { totalPendingSubrogation }
});

export const setTotalNewStatusContactUs = (totalNewStatusContactUs) => ({
   type: CURRENT_TOTAL_NEW_STATUS_CONTACT_US,
   payload: { totalNewStatusContactUs }
});

export const setHistoryParamsPages = (historyPagesParams) => ({
   type: STORE_HISTORY_PARAMS_PAGES,
   payload: { historyPagesParams }
});

export const setTotalUnreadStatusLineNotify = (totalUnreadStatusLineNotify) => ({
   type: CURRENT_TOTAL_UNREAD_STATUS_LINE_NOTIFY,
   payload: { totalUnreadStatusLineNotify }
});

export const loggedOut = () => ({
   type: LOGGED_OUT
});
