import React from 'react';
import clsx from 'clsx';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/core';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
   root: {
      position: 'relative'
   }
}));

const bgColor = [
   '#EF476F',
   '#FFD166',
   '#06D6A0',
   '#118AB2',
   '#00CED1',
   '#1E90FF',
   '#A338FA',
   '#6A0572',
   '#4682B4',
   '#4CAF50',
   '#00FF7F',
   '#FFD700',
   '#FFA500',
   '#FF6B6B',
   '#CB2555',
   '#FF69B4',
   '#8B4513',
   '#B8860B',
   '#808080',
   '#00008B',
   '#800080',
   '#FA5332',
   "#FEF500",
   "#B2FF01",
   "#91A63C",
   "#6FF2DF",
   "#9B7CF2",
   "#5125CC"
];


function Chart({ labels, data_count, survey_team, survey_team_owner_id, survey_team_owner_name, className, ...rest }) {
   const classes = useStyles();
   const theme = useTheme();
   const location = useLocation();
   const data = {
      labels: labels,
      datasets: [
         {
            label: 'จำนวนการตอบ',
            xAxisID: "1",
            backgroundColor: data_count?.map((_, idx) => bgColor[idx % 20]),
            data: data_count?.length === 0 ? [0] : data_count,
            categoryPercentage: 0.8,
         },
      ],
   };

   const options = {
      plugins: {
         datalabels: {
            align: 'end',
            anchor: 'end',
            color: theme.palette.text.secondary,
         }
      },
      responsive: true,
      maintainAspectRatio: false,
      cornerRadius: 0,
      legend: {
         display: false
      },
      layout: {
         padding: {
            left: 0, // เพิ่ม padding ด้านซ้ายเพื่อเพิ่มพื้นที่ให้กับ label ในแกน y
         }
      },
      scales: {
         yAxes:
            [
               {
                  id: 'A',
                  position: 'left',
                  display: true,
                  gridLines: { color: theme.palette.text.secondary, },
                  ticks: {
                     fontColor: theme.palette.text.primary,
                     fontSize: 12,
                     callback: function (value, index) {
                        const teamOwner = survey_team_owner_name[index] ? survey_team_owner_name[index]?.length < 20 ? survey_team_owner_name[index] : survey_team_owner_name[index].substring(0, 20) + '...' : null;
                        const newvalue = value?.length < 30 ? value : value.substring(0, 30) + '...';
                        if (teamOwner) {
                           return `${newvalue} (เจ้าของทีม: ${teamOwner})`;
                        }
                        return `${value}`;
                     },
                  },
                  gridLines: {
                     display: false,
                     color: theme.palette.text.secondary,
                  },
               },
            ],
         xAxes: [
            {
               stacked: false,
               barPercentage: 1,
               display: true,
               type: 'linear',
               id: '1',
               position: 'top',
               ticks: {
                  fontColor: theme.palette.text.primary,
               },
               fontSize: 500,
               scaleLabel: {
                  display: true,
                  labelString: 'จำนวนการตอบแบบสอบถาม',
                  fontColor: theme.palette.text.primary,
                  fontSize: 14,
               },
            },
         ],
      },
      tooltips: {
         enabled: true,
         mode: 'index',
         intersect: false,
         caretSize: 10,
         yPadding: 20,
         xPadding: 20,
         borderWidth: 1,
         borderColor: theme.palette.divider,
         backgroundColor: theme.palette.background.default,
         titleFontColor: theme.palette.text.primary,
         bodyFontColor: theme.palette.text.secondary,
         footerFontColor: theme.palette.text.secondary,
         callbacks: {
            legend: () => { },
            title: ([tooltipItem], data) => {
               const index = tooltipItem.index;
               return `${tooltipItem.yLabel} (ทีม: ${survey_team[index]})`;
            },
            label: (tooltipItem, data) => {
               let label = data.datasets[tooltipItem.datasetIndex].label;
               let dataTooltip = `${label} ${tooltipItem.xLabel} ครั้ง`
               return dataTooltip;
            }
         }
      }
   };

   const handleOnClickElement = ([chartElement]) => {
      if (!chartElement) return;
      if (location.pathname !== '/app/survey-response-report') return;
      const index = chartElement._index;
      const survey_name = labels[index];
      const teamowner = survey_team_owner_id[index];

      const searchParams = {
         survey_name,
         teamowner,
         date_type: 'all',
         package_order_type: 'all',
         created_date_type: 'all',
      };
      const url = `/app/survey-report?${createSearchParams(searchParams)}`;
      window.open(url, '_blank');
   };


   return (
      <div className={clsx(classes.root, className)
      } {...rest}>
         <HorizontalBar data={data} options={options} onElementsClick={handleOnClickElement} />
      </div >
   );

}

// Chart.propTypes = {
// className: PropTypes.string,
// data: PropTypes.array.isRequired,
// labels: PropTypes.array.isRequired
// };

export default Chart;
