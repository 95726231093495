/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
   Avatar,
   Box,
   Divider,
   Drawer,
   Hidden,
   Link,
   List,
   ListItem,
   ListItemText,
   ListItemIcon,
   ListSubheader,
   Typography,
   makeStyles
} from '@material-ui/core';
// import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
   ShoppingBag as ShoppingBagIcon,
   Folder as FolderIcon,
   BarChart as BarChartIcon,
   // AlertCircle as AlertCircleIcon,
   User as UserIcon,
   Smile as CustomerIcon,
   List as ListIcon,
   Gift as GiftIcon,
   Box as BoxIcon,
   PieChart as PieChartIcon,
   Users as UsersIcon,
   // Loader as LoaderIcon,
   CheckSquare as RoleIcon,
   CheckCircle as UsageIcon,
   MessageSquare as contactIcon,
   Package as PackageIcon,
   Tag as SellerCustomerIcon,
   LogOut as LogoutIcon,
   PenTool as SubrogateIcon,
   Database as SurveybankIcon,
   Clipboard as ClipboardIcon,
   Percent as PercentIcon,
   Shuffle as ShuffleIcon,
   Edit as ArticleIcon,
   Lock as PrivacyPolicyIcon,
   Flag as TermsAndConditions,
   Image as SystemImage,
   Database as StorageIcon,
   CreditCard as PaymentIcon,
   Globe as AdsIcon,
   Volume2 as PromotionIcon,
   MessageSquare as QandAIcon,
   Mic as PartnerIcon,
   Settings as SystemConfigIcon,
   Server as CodeRedemption,
   Airplay as InternalApi,
   Book as CustomersReport,
   FileText as SellerReport,
   Calendar as MonthlySaleReport,
   File as SaleCommissionReport,
   Archive as ArchiveIcon,
   Briefcase as BriefcaseIcon,
   Edit3 as EditIcon,
   Link2 as LinkIcon,
   Mail as MailIcon,
   Grid as GridIcon,
   MessageCircle as LineNotify,
   Pocket as SurveyVerify,
   Table as customOrder,
   Download,
   Codepen as CustomerUsageIcon,
   Codesandbox as TeamUsageIcon,
   CheckSquare
} from 'react-feather';
import NProgress from 'nprogress';
import { usePermissions } from 'src/hooks/usePermissions';
import { useSnackbar } from 'notistack';
import { useDialog } from 'src/hooks/dialog';
import { useAuth } from 'src/hooks/useAuth';
import { loggedOut } from 'src/redux/actions/authenAction';
import getInitials from 'src/common/getInitials';
import NavItem from './NavItem';

export const navConfig = [
   {
      subheader: '',
      items: [
         {
            title: 'Dashboard',
            icon: PieChartIcon,
            href: '/app/dashboard',
            permission: (_, role) => role('SELLER')
         }
         // {
         //     title: 'Dashboard Alternative',
         //     icon: BarChartIcon,
         //     href: '/app/alternative-dashboard'
         // }
      ]
   },
   {
      subheader: 'Management',
      items: [
         {
            title: 'Users',
            icon: UserIcon,
            href: '/app/users',
            permission: (can) => can(['EDIT', 'VIEW'], 'USERS')
         },
         {
            title: 'Roles',
            icon: RoleIcon,
            href: '/app/roles',
            permission: (can) => can(['EDIT', 'VIEW'], 'ROLE')
         },
         {
            title: 'Packages',
            icon: PackageIcon,
            href: '/app/packages',
            permission: (can) => can(['EDIT', 'VIEW'], 'PACKAGE')
         },
         {
            title: 'Surveybank',
            icon: SurveybankIcon,
            href: '/app/surveybanks',
            permission: (can) => can(['EDIT', 'VIEW'], 'SURVEYBANK')
         },
         {
            title: 'Customers',
            icon: CustomerIcon,
            href: '/app/customers',
            permission: (can) => can(['VIEW'], 'CUSTOMER')
         },
         {
            title: 'Subrogation Approval',
            icon: SubrogateIcon,
            href: '/app/subrogation-approval',
            permission: (can) => can(['VIEW'], 'SUBROGATE_APPROVE')
         },
         {
            title: 'Seller-Customers',
            icon: SellerCustomerIcon,
            href: '/app/seller-customers',
            permission: (can) => can(['VIEW'], 'SELLER_CUSTOMER')
         },
         {
            title: 'Advisor-Customers',
            icon: UsersIcon,
            href: '/app/advisor-customers',
            permission: (can) => can(['VIEW'], 'ADVISOR_CUSTOMER')
         },
         {
            title: 'Contact us',
            icon: contactIcon,
            href: '/app/contacts',
            permission: (can) => can(['VIEW'], 'CONTACT_US')
         },
         // {
         //     title: 'Products',
         //     icon: ShoppingCartIcon,
         //     href: '/app/products',
         // },
         {
            title: 'Commission',
            icon: GiftIcon,
            href: '/app/commission',
            permission: (can) => can(['VIEW'], 'SALE_COMMISSION')
         },
         {
            title: 'Orders',
            icon: FolderIcon,
            href: '/app/orders/all-customers',
            permission: (can) =>
               can(['VIEW'], 'ORDER_QUOTATION') || can(['VIEW'], 'ORDER_INVOICE') || can(['VIEW'], 'ORDER_RECEIPT')
            // href: '/app/orders',
            // items: [
            //     {
            //         title: 'List Customers Order',
            //     },
            //     // {
            //     //     title: 'Order Statistics',
            //     //     href: '/app/orders/statistics'
            //     // }
            // ]
         },
         {
            title: 'Custom Order',
            icon: customOrder,
            href: '/app/custom-order',
            permission: (can) => can(['VIEW'], 'ORDER_CUSTOM')
         },
         {
            title: 'Survey Transfer',
            icon: ShuffleIcon,
            href: '/app/survey-transfer',
            permission: (can) => can(['VIEW'], 'SURVEY_TRANSFER')
         },
         {
            title: 'Import/Export Survey',
            icon: Download,
            href: '/app/import-export-survey',
            permission: (can) => can(['VIEW'], 'IMPORT_SURVEY')
         },
         {
            title: 'Article',
            icon: ArticleIcon,
            href: '/app/articles',
            permission: (can) => can(['VIEW'], 'ARTICLE')
         },
         {
            title: 'Article Plus',
            icon: ArticleIcon,
            href: '/app/articles-plus',
            permission: (can) => can(['VIEW'], 'ARTICLE')
         },
         {
            title: 'Privacy Policy',
            icon: PrivacyPolicyIcon,
            href: '/app/privacy-policy',
            permission: (can) => can(['VIEW'], 'PRIVACY')
         },
         {
            title: 'Terms & Conditions',
            icon: TermsAndConditions,
            href: '/app/term-and-condition',
            permission: (can) => can(['VIEW'], 'TERM_AND_CONDITION')
         },
         {
            title: 'System images',
            icon: SystemImage,
            href: '/app/system-images',
            permission: (can) => can(['VIEW'], 'SYSTEM_IMAGE'),
            items: [
               {
                  title: 'Image Categories',
                  href: '/app/image-categories',
                  permission: (can) => can(['VIEW'], 'SYSTEM_IMAGE')
               },
               {
                  title: 'Image Gallery',
                  href: '/app/image-gallery',
                  permission: (can) => can(['VIEW'], 'SYSTEM_IMAGE')
               }
            ]
         },
         {
            title: 'Assistant Choices',
            icon: QandAIcon,
            href: '/app/assistant-answer',
            permission: (can) => can(['VIEW'], 'ASSISTANT_CHOICES')
         },
         {
            title: 'System-Config',
            icon: SystemConfigIcon,
            href: '/app/system-config',
            permission: (can) => can(['VIEW'], 'SYSTEM_CONFIG')
         },
         {
            title: 'Code Redemption',
            icon: CodeRedemption,
            href: '/app/code-redemption',
            permission: (can) => can(['VIEW'], 'REDEMPTION_CODE')
         },
         {
            title: 'Line Notify',
            icon: LineNotify,
            href: '/app/creator-notifications',
            permission: (can) => can(['VIEW'], 'CREATOR_NOTIFICATIONS')
         },
         {
            title: 'Survey Verify',
            icon: SurveyVerify,
            href: '/app/survey-verify',
            permission: true
            // permission: (can) => can(['VIEW'], 'SURVEY_VERIFY')
         }

         // canceled
         // {
         //     title: 'Sample Research',
         //     icon: Research,
         //     href: '/app/sample-research',
         //     permission: (can)=> can(['VIEW'], "SAMPLE_RESEARCH"),
         // },
      ]
   },
   {
      subheader: 'Partner & Promotion Code',
      items: [
         {
            title: 'Partners',
            icon: PartnerIcon,
            href: '/app/partners',
            permission: (can) => can(['VIEW'], 'PARTNER')
         },
         {
            title: 'Promotion Code',
            icon: PromotionIcon,
            href: '/app/promotion-code',
            permission: (can) => can(['VIEW'], 'PROMOTION_CODE')
         },
         {
            title: 'Promotion Codes Payment',
            icon: PaymentIcon,
            href: '/app/partner-promotion-codes',
            permission: (can) => can(['VIEW'], 'PARTNER_PAYMENT')
         }
      ]
   },
   {
      subheader: 'Report',
      items: [
         {
            title: 'Customers report',
            icon: CustomersReport,
            href: '/app/report',
            permission: (can) => can(['VIEW'], 'CUSTOMER_REPORT')
         },
         {
            title: 'Seller report',
            icon: SellerReport,
            href: '/app/seller-report',
            permission: (can) => can(['VIEW'], 'SELLER_REPORT')
         },
         {
            title: 'Monthly Sale report',
            icon: MonthlySaleReport,
            href: '/app/monthly-sale-report',
            permission: (can) => can(['VIEW'], 'MONTHLY_SALE_REPORT')
         },
         {
            title: 'Sale commission report',
            icon: SaleCommissionReport,
            href: '/app/sell-commission-report',
            permission: (can) => can(['VIEW'], 'SALE_COMMISSION_REPORT')
         },
         {
            title: 'Customer by type of package report',
            icon: GridIcon,
            href: '/app/customer-by-package-type-report',
            permission: (can) => can(['VIEW'], 'CUSTOMER_BY_PACKAGE_TYPE_REPORT')
         },
         {
            title: 'Customer by sale report',
            icon: ArchiveIcon,
            href: '/app/customer-by-sale-report',
            permission: (can) => can(['VIEW'], 'CUSTOMER_BY_SALE_REPORT')
         },
         {
            title: 'Customer by Advisor report',
            icon: BriefcaseIcon,
            href: '/app/customer-by-advisor-report',
            permission: (can) => can(['VIEW'], 'CUSTOMER_BY_ADVISOR_REPORT')
         },
         {
            title: 'Registration report',
            icon: BarChartIcon,
            href: '/app/registration-report',
            permission: (can) => can(['VIEW'], 'REGISTRATION_REPORT')
         },
         {
            title: 'Survey response summary report',
            icon: CheckSquare,
            href: '/app/survey-response-summary-report',
            permission: (can) => can(['VIEW'], 'SURVEY_RESPONSE_SUMMATY_REPORT')
         },
         {
            title: 'Survey response report',
            icon: CheckSquare,
            href: '/app/survey-response-report',
            permission: (can) => can(['VIEW'], 'SURVEY_RESPONSE_REPORT')
         },
         {
            title: 'Survey  report',
            icon: ClipboardIcon,
            href: '/app/survey-report',
            permission: (can) => can(['VIEW'], 'SURVEY_REPORT')
         },
         {
            title: 'Customer Storage Report',
            icon: PieChartIcon,
            href: '/app/customer-storage-report',
            permission: (can) => can(['VIEW'], 'CUSTOMER_STORAGE_REPORT')
         },
         {
            title: 'Payment Report',
            icon: ShoppingBagIcon,
            href: '/app/payment-report',
            permission: (can) => can(['VIEW'], 'PAYMENT_REPORT') || can(['VIEW'], 'PAYMENT_ORDER_REPORT') || can(['VIEW'], 'PAYMENT_ALL_ORDER_REPORT')
         },
         {
            title: 'Tracking Ads Report',
            icon: AdsIcon,
            href: '/app/tracking-ads-report',
            permission: (can) => can(['VIEW'], 'TRACKING_ADS_REPORT')
         },
         {
            title: 'Tracking Visit Report',
            icon: LinkIcon,
            href: '/app/tracking-visit-report',
            permission: (can) => can(['VIEW'], 'TRACKING_VISIT_REPORT')
         },
         {
            title: 'Tracking Register Report',
            icon: EditIcon,
            href: '/app/tracking-register-report',
            permission: (can) => can(['VIEW'], 'TRACKING_REGISTER_REPORT')
         },
         {
            title: 'Tracking Package Bought Report',
            icon: BoxIcon,
            href: '/app/tracking-package-bought-report',
            permission: (can) => can(['VIEW'], 'TRACKING_PACKAGE_BOUGHT_REPORT')
         },
         {
            title: 'SMS Report',
            icon: MailIcon,
            href: '/app/sms-report',
            permission: (can) => can(['VIEW'], 'SMS_SERVICE_REPORT')
         },
         {
            title: 'Question Type Usage Report',
            icon: UsageIcon,
            href: '/app/question-type-usage-report',
            permission: (can) => can(['VIEW'], 'QUESTION_TYPE_USAGE_REPORT')
         },
         {
            title: 'Customer Usage Report',
            icon: CustomerUsageIcon,
            href: '/app/customer-usage-report',
            permission: (can) => can(['VIEW'], 'CUSTOMER_USAGE_REPORT')
         },
         {
            title: 'Team Usage Report',
            icon: TeamUsageIcon,
            href: '/app/team-usage-report',
            permission: (can) => can(['VIEW'], 'TEAM_USAGE_REPORT')
         },
         // {
         //     title: 'Dashboard Alternative',
         //     icon: BarChartIcon,
         //     href: '/app/alternative-dashboard'
         // }
      ]
   },
   {
      subheader: 'System',
      items: [
         {
            title: 'Customer logs',
            icon: ListIcon,
            href: '/app/creator-logs',
            permission: (can) => can(['VIEW'], 'CREATORLOGS')
         },
         {
            title: 'Backoffice logs',
            icon: ListIcon,
            href: '/app/backoffice-logs',
            permission: (can) => can(['VIEW'], 'CREATORLOGS')
         },
         {
            title: 'Customer Api logs',
            icon: ListIcon,
            href: '/app/customer-api-log-error',
            permission: (can) => can(['VIEW'], 'CUSTOMER_API_LOGS_ERROR')
         },
         {
            title: 'Device logs',
            icon: ListIcon,
            href: '/app/device-logs',
            permission: (can) => can(['VIEW'], 'DEVICELOGS')
         },
         {
            title: 'Customer Activities',
            icon: ListIcon,
            href: '/app/creator-customer-logs',
            permission: (can) => can(['VIEW'], 'CREATOR_CUSTOMER_LOGS')
         },
         {
            title: 'Backoffice User Activities',
            icon: ListIcon,
            href: '/app/backoffice-user-logs',
            permission: (can) => can(['VIEW'], 'BACKOFFICE_USER_LOGS')
         },

         {
            title: 'Customer Api Activities',
            icon: ListIcon,
            href: '/app/customer-api-activity',
            permission: (can) => can(['VIEW'], 'CUSTOMER_API_LOGS')
         },
         {
            title: 'Internal APIs',
            icon: InternalApi,
            href: '/app/internal-api',
            permission: (can) => can(['VIEW'], 'INTERNAL_API')
         }
      ]
   }
   // {
   //     subheader: 'Pages',
   //     href: '/app/pages',
   //     items: [
   //         {
   //             title: 'Account',
   //             href: '/app/account',
   //             icon: UserIcon
   //         },
   //         // {
   //         //     title: 'Error',
   //         //     href: '/app/404',
   //         //     icon: AlertCircleIcon
   //         // },
   //         // {
   //         //     title: 'Settings',
   //         //     href: '/app/settings',
   //         //     icon: SettingsIcon
   //         // },
   //         // {
   //         //     title: 'Loading',
   //         //     href: '/app/loading',
   //         //     icon: LoaderIcon
   //         // }
   //     ]
   // },
   // {
   //     subheader: 'Extra',
   //     items: [
   //         {
   //             title: 'Charts',
   //             href: '/app/extra/charts',
   //             icon: BarChartIcon,
   //             items: [
   //                 {
   //                     title: 'Apex Charts',
   //                     href: '/app/extra/charts/apex'
   //                 }
   //             ]
   //         },
   //         {
   //             title: 'Forms',
   //             href: '/app/extra/forms',
   //             icon: EditIcon,
   //             items: [
   //                 {
   //                     title: 'Formik',
   //                     href: '/app/extra/forms/formik'
   //                 },
   //                 {
   //                     title: 'Redux Forms',
   //                     href: '/app/extra/forms/redux'
   //                 },
   //             ]
   //         },
   //         {
   //             title: 'Editors',
   //             href: '/app/extra/editors',
   //             icon: LayoutIcon,
   //             items: [
   //                 {
   //                     title: 'DraftJS Editor',
   //                     href: '/app/extra/editors/draft-js'
   //                 },
   //                 {
   //                     title: 'Quill Editor',
   //                     href: '/app/extra/editors/quill'
   //                 }
   //             ]
   //         }
   //     ]
   // }
];

function NavItems({ items, ...rest }) {
   const { can, role } = usePermissions();
   return (
      <List disablePadding>
         {items
            .filter((navItem) =>
               navItem.permission instanceof Function ? navItem.permission(can, role) : navItem.permission
            )
            .reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}
      </List>
   );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
   const key = item.title + depth;

   if (item.items) {
      const open = matchPath(
         {
            path: item.href,
            end: false
         },
         pathname
      );

      acc.push(
         <NavItem depth={depth} icon={item.icon} key={key} info={item.info} open={Boolean(open)} title={item.title}>
            <NavItems depth={depth + 1} pathname={pathname} items={item.items} />
         </NavItem>
      );
   } else {
      acc.push(
         <NavItem depth={depth} href={item.href} icon={item.icon} key={key} info={item.info} title={item.title} />
      );
   }

   return acc;
}

const useStyles = makeStyles((theme) => ({
   mobileDrawer: {
      width: 256
   },
   desktopDrawer: {
      width: 256,
      top: 64,
      height: 'calc(100% - 64px)'
   },
   avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64,
      backgroundColor: ({ avatarColor }) => avatarColor || theme.palette.background.dark
   }
}));

function NavBar({ openMobile, onMobileClose }) {
   const { can, role } = usePermissions();
   const { user } = useSelector((state) => state.authen);
   const classes = useStyles({ avatarColor: user?.avatarColor });
   const location = useLocation();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();
   const dialog = useDialog();
   const { logout } = useAuth();

   useEffect(() => {
      if (openMobile && onMobileClose) {
         onMobileClose();
      }
      // eslint-disable-next-line
   }, [location.pathname]);

   const handleLogoutClick = async () => {
      const res = await dialog.confirm('Confirm Logout ? ');
      if (res) {
         NProgress.start();
         try {
            const result = await logout();
            NProgress.done();
            if (result.status === 'logout-success') {
               enqueueSnackbar('Logout success', {
                  variant: 'success',
                  autoHideDuration: 2000
               });
               navigate('/login', { replace: true });
            } else {
               dialog.error('Ops !! some error occurred please contact your system administrator');
            }
         } catch (error) {
            NProgress.done();
            dialog.error(error);
            console.log('something wrong !!', error);
            if (!Boolean(localStorage.getItem('access-token'))) {
               dispatch(loggedOut());
               navigate('/login', { replace: true });
            }
         }
      }
   };

   const content = (
      <Box height="100%" display="flex" flexDirection="column">
         <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box p={2}>
               <Box display="flex" justifyContent="center">
                  <RouterLink to="/app/account">
                     <Avatar alt="User" className={classes.avatar}>
                        {getInitials(user?.name || '')}
                     </Avatar>
                  </RouterLink>
               </Box>
               <Box mt={2} textAlign="center">
                  <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
                     {user?.name || ''}
                  </Link>
                  <Typography variant="body2" color="textSecondary">
                     {user?.email || ''}
                  </Typography>
               </Box>
            </Box>
            <Divider />
            <Box p={2}>
               {navConfig
                  .filter((nc) =>
                     nc.items.some((item) =>
                        item.permission instanceof Function ? item.permission(can, role) : item.permission
                     )
                  )
                  .map((config) => (
                     <List
                        key={config.subheader}
                        subheader={
                           <ListSubheader disableGutters disableSticky>
                              {config.subheader}
                           </ListSubheader>
                        }
                     >
                        <NavItems items={config.items} pathname={location.pathname} />
                     </List>
                  ))}
            </Box>
            <Divider />
            <Box p={2}>
               <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
                  <Typography variant="h6" color="textPrimary">
                     Need Help?
                  </Typography>
                  <Link variant="subtitle1" color="secondary" component={RouterLink} to="/docs">
                     Check our docs
                  </Link>
               </Box>
            </Box>
            <Divider />
            <Box p={2}>
               <List dense>
                  <ListItem button onClick={handleLogoutClick}>
                     <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                     </ListItemIcon>
                     <ListItemText primary="Log Out" />
                  </ListItem>
               </List>
            </Box>
         </PerfectScrollbar>
      </Box>
   );

   return (
      <>
         <Hidden lgUp>
            <Drawer
               anchor="left"
               classes={{ paper: classes.mobileDrawer }}
               onClose={onMobileClose}
               open={openMobile}
               variant="temporary"
            >
               {content}
            </Drawer>
         </Hidden>
         <Hidden mdDown>
            <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
               {content}
            </Drawer>
         </Hidden>
      </>
   );
}

NavBar.propTypes = {
   onMobileClose: PropTypes.func,
   openMobile: PropTypes.bool
};

export default NavBar;
