import {
   BEGIN_GET_CUSTOMER_INFO,
   BEGIN_GET_ORDER,
   CLEAN_UP_ORDER,
   DISPLAY_LOADING,
   FAILURE_GET_CUSTOMER_INFO,
   FAILURE_GET_ORDER,
   SUCCESS_GET_CUSTOMER_INFO,
   SUCCESS_GET_ORDER
} from '../actions/customerOrderAction';

const initialState = {
   isLoading: false,
   order: {},
   defaultAdditionalTaxRate: 3,
   packages: [],
   pdfHost: '',
   unuseDays: 0,
   customer: {},
   canCreate: false,
   error: null, // error for order
   customerError: null, // error for customer info
   usageStorage: 0,
   defaultTeamRole: { minimum: 2, step: 2 }
};

export default function customerOrderReducer(state = initialState, action) {
   switch (action.type) {
      case DISPLAY_LOADING:
         return {
            ...state,
            isLoading: action.payload.isLoading
         };
      case BEGIN_GET_ORDER:
         return {
            ...state,
            isLoading: true,
            error: null
         };
      case SUCCESS_GET_ORDER:
         return {
            ...state,
            isLoading: false,
            order: action.payload.order,
            defaultAdditionalTaxRate: action.payload.defaultAdditionalTaxRate,
            packages: action.payload.packages,
            pdfHost: action.payload.pdfHost,
            unuseDays: action.payload.unuseDays,
            usageStorage: action.payload.usageStorage,
            defaultTeamRole: action.payload.teamRole
            // error: null
         };
      case FAILURE_GET_ORDER:
         return {
            ...state,
            isLoading: false,
            error: action.payload.error
         };
      case BEGIN_GET_CUSTOMER_INFO:
         return {
            ...state,
            isLoading: true,
            customerError: null
         };
      case SUCCESS_GET_CUSTOMER_INFO:
         return {
            ...state,
            isLoading: false,
            customer: action.payload.customer,
            canCreate: action.payload.canCreate
         };
      case FAILURE_GET_CUSTOMER_INFO:
         return {
            ...state,
            isLoading: false,
            customerError: action.payload.error
         };
      case CLEAN_UP_ORDER:
         return initialState;
      default:
         return {
            ...state
         };
   }
}
