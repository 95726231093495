import React from 'react';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// import Divider from "@material-ui/core/Divider";
// Icon
// import IconClose from "@material-ui/icons/Close";

const BaseDialog = ({ open, title, content, action, dialogProps }) => {
   const handleClose = (e, reason) => {
      return !(reason === 'backdropClick');
   };

   return (
      <Dialog fullWidth onClose={handleClose} open={open} {...dialogProps}>
         {title && <DialogTitle disableTypography>{title}</DialogTitle>}

         <DialogContent>{content}</DialogContent>

         <DialogActions>{action}</DialogActions>
      </Dialog>
   );
};

export default BaseDialog;
