import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FilterSearchDate from 'src/components/FilterDateSearch';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
   root: {},
   timing: {
      width: 200
   },
   container: {
      display: 'flex',
      alignItems: 'flex-start',
      [theme.breakpoints.down(720)]: {
         flexDirection: 'column',
         '&& > *': {
            width: '100%'
         }
      }
   },
   btnAction: {
      paddingTop: '8px',
      gap: 8,
      display: 'flex',
      [theme.breakpoints.down(720)]: {
         justifyContent: 'center'
      }
   }
}));

const Toolbar = ({
   params,
   setParams,
   onResetClick,
   startSearching,
   startSearchingAndStoreParams,
   isLoading,
   className = '',
   disabled = true,
   ...rest
}) => {
   const classes = useStyles();

   const handleChangeDate = (date, name) => {
      setParams((prevState) => ({
         ...prevState,
         [`${name}_date`]: date
      }));
   };

   const handleChangeTiming = (e) => {
      const { value } = e.target;
      let typeOfDate;
      if (value === 'day') {
         typeOfDate = moment(new Date()).toISOString();
      } else if (value === 'month') {
         typeOfDate = moment(new Date()).startOf('month').toISOString();
      } else if (value === 'year') {
         typeOfDate = moment(new Date()).endOf('month').toISOString();
      } else {
         setParams((pState) => ({
            ...pState,
            date_type: value,
            start_date: null,
            end_date: null
         }));
         return;
      }
      setParams((pState) => ({
         ...pState,
         date_type: value,
         start_date: typeOfDate
      }));
   };
   const handleClickSearch = () => startSearching();
   const handleClickSearchStoreParams = () => startSearchingAndStoreParams();

   return (
      <div className={clsx(classes.root, className)} {...rest}>
         <Box className={classes.container}>
            <Box
               width={
                  params.date_type === 'between'
                     ? '700px'
                     : params.date_type === 'day' || params.date_type === 'month' || params.date_type === 'year'
                        ? '500px'
                        : '220px'
               }
            >
               <FilterSearchDate
                  title={'วันที่และเวลา'}
                  filterSearch={{
                     date: {
                        start: params.start_date,
                        end: params.end_date,
                        type: params.date_type
                     }
                  }}
                  handleChangeTiming={handleChangeTiming}
                  handleChangeDate={handleChangeDate}
               />
            </Box>
         </Box>{' '}
         <Box className={classes.btnAction}>
            <Button
               endIcon={isLoading ? null : <SearchIcon />}
               className={classes.btnSearch}
               disabled={
                  isLoading ||
                  (params.start_date === null && params.end_date === null && params.date_type === 'between')
               }
               onClick={handleClickSearch}
               variant="contained"
               color="primary"
            >
               {isLoading ? <CircularProgress size={24} /> : 'Search'}
            </Button>
            <Button
               endIcon={isLoading ? null : <SearchIcon />}
               className={classes.btnSearch}
               disabled={
                  isLoading ||
                  (params.start_date === null && params.end_date === null && params.date_type === 'between')
               }
               onClick={handleClickSearchStoreParams}
               variant="contained"
               color="primary"
            >
               {isLoading ? <CircularProgress size={24} /> : 'Search and store filter'}
            </Button>
            <Button
               disabled={isLoading}
               className={classes.btnSearch}
               variant="contained"
               color="primary"
               onClick={onResetClick}
            >
               Reset
            </Button>
         </Box>
      </div>
   );
};

export default Toolbar;
