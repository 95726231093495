import React, { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { PublicRoute, PrivateRoute } from 'src/components/helper';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import AuthenLayout from 'src/layouts/authenLayout/AuthenLayout';
import UnAuthenLayout from 'src/layouts/unAuthenLayout/UnAuthenLayout';
import OrderInfo from './views/orders/orderInfo/OrderInfo';
import SurvayResponseSummaryReport from './views/reports/SurvayResponseSummaryReport/SurvayResponseSummaryReport';
import SurvayResponseReport from './views/reports/SurvayResponseReport/SurvayResponseReport';

const UserAccount = lazy(() => import('src/views/account/userAccount/UserAccount'));
const UserCreate = lazy(() => import('src/views/users/userCreate/UserCreate'));
const UserList = lazy(() => import('src/views/users/usersList/UserList'));
const UserEdit = lazy(() => import('src/views/users/userEdit/UserEdit'));
const RoleCreate = lazy(() => import('src/views/roles/roleCreate/RoleCreate'));
const RoleList = lazy(() => import('src/views/roles/rolesList/RolesList'));
const RoleEdit = lazy(() => import('src/views/roles/roleEdit/RoleEdit'));
const ContactUs = lazy(() => import('src/views/contactUs/ContactUs'));
const PackageCreate = lazy(() => import('src/views/packages/packageCreate/PackageCreate'));
const PackageCreator = lazy(() => import('src/views/packages/packageCreator/PackageCreator'));
const PackageList = lazy(() => import('src/views/packages/packagesList/PackagesList'));
const PackageEdit = lazy(() => import('src/views/packages/packageEdit/PackageEdit'));
const PackageMultiEdit = lazy(() => import('src/views/packages/packageMultiEdit/PackageMultiEdit'));
const SurveybankCreate = lazy(() => import('src/views/surveybank/surveybankCreate/SurveybankCreate'));
const SurveybankList = lazy(() => import('src/views/surveybank/surveybanksList/SurveybanksList'));
const SurveybankEdit = lazy(() => import('src/views/surveybank/surveybankEdit/SurveybankEdit'));
const SurveybankMultiEdit = lazy(() => import('src/views/surveybank/surveybankMultiEdit/SurveybankMultiEdit'));
const AlternativeDashboard = lazy(() => import('src/views/dashboard/userDashboard/UserDashboard'));
const Dashboard = lazy(() => import('src/views/dashboard/dashboardSeller/DashboardSeller'));
const LoginView = lazy(() => import('src/views/auth/login/Login'));
const ForgotPassword = lazy(() => import('src/views/auth/forgotPassword/ForgotPassword'));
const ChangeFirstPassword = lazy(() => import('src/views/auth/changeFirstPassword/ChangeFirstPassword'));
const ResetPassword = lazy(() => import('src/views/auth/resetPassword/ResetPassword'));
const CustomerList = lazy(() => import('src/views/customers/customersList/CustomersList'));
const CustomerEdit = lazy(() => import('src/views/customers/customerEdit/CustomerEdit'));
const CustomerCreate = lazy(() => import('src/views/customers/customerCreate/CustomerCreate'));
const ListAdvisorCustomer = lazy(() => import('src/views/advisorCustomers/advisorCustomersList/AdvisorCustomersList'));
const ListSellerCustomer = lazy(() => import('src/views/sellerCustomers/sellerCustomersList/SellerCustomersList'));
const OrderCustomers = lazy(() => import('src/views/orders/customersList/CustomersList'));
const OrdersList = lazy(() => import('src/views/orders/ordersList/OrderList'));
const ProductListView = lazy(() => import('src/views/product/ProductListView'));
const InvoiceListView = lazy(() => import('src/views/invoice/InvoiceListView'));
const DraftEditorView = lazy(() => import('src/views/editors/DraftEditorView'));
const SettingsView = lazy(() => import('src/views/settings/SettingsView'));
const LoadingView = lazy(() => import('src/components/loading/LoadingScreen'));
const NotFoundView = lazy(() => import('src/views/errors/pageNotFound/NotFoundView'));
const Report = lazy(() => import('src/views/reports/customerReport/CustomerReport'));
const SellerReport = lazy(() => import('src/views/reports/sellerReport/SellerReport'));
const MonthlySaleReport = lazy(() => import('src/views/reports/monthlySaleReport/MonthlySaleReport'));
const SellCommissionReport = lazy(() => import('src/views/reports/sellCommissionReport/SellCommissionReport'));
const CustomerByPackageTypeReport = lazy(() =>
   import('src/views/reports/customerByPackageTypeReport/CustomerByPackageTypeReport')
);
const CustomerBySaleReport = lazy(() => import('src/views/reports/customerBySaleReport/CustomerBySaleReport'));
const CustomerByAdvisorReport = lazy(() => import('src/views/reports/customerByAdvisorReport/CustomerByAdvisorReport'));
const CreatorLogs = lazy(() => import('src/views/reports/creatorLogs/CreatorLogsApp'));
const CustomerApiLogError = lazy(() => import('src/views/reports/customerApiLogError/CustomerApiLogError'));
const CustomerApiActivities = lazy(() => import('src/views/reports/customerApiActivities/CustomerApiActivities'));
const BackofficeLogs = lazy(() => import('src/views/reports/backofficeLogs/BackofficeLogs'));
const DeviceLogs = lazy(() => import('src/views/reports/deviceLogs/DeviceLogs'));
const CreatorCustomerLogs = lazy(() => import('src/views/reports/creatorCustomerLogs/CreatorCustomerLogs'));
const BackofficeUserLogs = lazy(() => import('src/views/reports/backofficeUserLogs/BackofficeUserLogs'));
const CustomersRegistReport = lazy(() => import('src/views/reports/CustomersRegistReport/CustomersRegistReport'));
const CustomersStorageTeamReport = lazy(() =>
   import('src/views/reports/customerCloudStorageReport/CustomerStorageTeamReport')
);
const CustomersStorageSurveyReport = lazy(() =>
   import('src/views/reports/customerCloudStorageReport/CustomerStorageSurveyReport')
);
const SurveyReport = lazy(() => import('src/views/reports/surveyReport/SurveyReport'));
const TeamUsageReport = lazy(() => import('src/views/reports/teamUsageReport/TeamUsageReport'));
const QuestionTypeUsageReport = lazy(() => import('src/views/reports/questionTypeUsageReport/QuestionTypeUsageReport'));
const CustomerUsageReport = lazy(() => import('src/views/reports/customerUsageReport/CustomerUsageReport'));
const PaymentReport = lazy(() => import('src/views/reports/paymentReport/PaymentReport'));
const SubrogationApprove = lazy(() => import('src/views/subrogationApprove/SubrogationApprove'));
const CreateCommission = lazy(() => import('src/views/commission/CreateCommission'));
const SurveyTransfer = lazy(() => import('src/views/surveyTransfer/SurveyTransfer'));
const ImportExportSurvey = lazy(() => import('src/views/importExportSurvey/ImportExportSurvey'));
const ArticleCreate = lazy(() => import('src/views/articles/articleCreate/ArticleCreate'));
const ArticleList = lazy(() => import('src/views/articles/articlesList/ArticlesList'));
const ArticleEdit = lazy(() => import('src/views/articles/articleEdit/ArticleEdit'));
const ArticlePlusCreate = lazy(() => import('src/views/articlesPlus/articlePlusCreate/ArticlePlusCreate'));
const ArticlePlusList = lazy(() => import('src/views/articlesPlus/articlesPlusList/ArticlesPlusList'));
const ArticlePlusEdit = lazy(() => import('src/views/articlesPlus/articlePlusEdit/ArticlePlusEdit'));
const PrivacyPolicyCreate = lazy(() => import('src/views/privacyPolicy/privacyPolicyCreate/PrivacyPolicyCreate'));
const PrivacyPolicyList = lazy(() => import('src/views/privacyPolicy/privacyPolicyList/PrivacyPolicyList'));
const PrivacyPolicyEdit = lazy(() => import('src/views/privacyPolicy/privacyPolicyEdit/PrivacyPolicyEdit'));
const TermAndConditionCreate = lazy(() =>
   import('src/views/termAndCondition/termAndConditionCreate/TermAndConditionCreate')
);
const TermAndConditionList = lazy(() => import('src/views/termAndCondition/termAndConditionList/TermAndConditionList'));
const TermAndConditionEdit = lazy(() => import('src/views/termAndCondition/termAndConditionEdit/TermAndConditionEdit'));
const ImageCategories = lazy(() => import('src/views/systemImages/imageCategories/ImageCategories'));
const ImageGellary = lazy(() => import('src/views/systemImages/imageGellary/ImageGellary'));
const TrackingAdsReport = lazy(() => import('src/views/reports/trackingAdsReport/TrackingAdsReport'));
const TrackingVisitReport = lazy(() => import('src/views/reports/trackingVisitReport/TrackingVisitReport'));
const TrackingRegisterReport = lazy(() => import('src/views/reports/trackingRegisterReport/TrackingRegisterReport'));
const TrackingPackageBoughtReport = lazy(() =>
   import('src/views/reports/trackingPackageBoughtReport/TrackingPackageBoughtReport')
);
// const SampleResearchCreate = lazy(() => import('src/views/sampleResearch/SampleResearchCreate'));
// const SampleResearchEdit = lazy(() => import('src/views/sampleResearch/SampleResearchEdit'));
// const SampleResearch = lazy(() => import('src/views/sampleResearch/SampleResearchList'));

const PromotionCodeCreate = lazy(() => import('src/views/promotionCode/promotionCodeCreate/PromotionCodeCreate'));
const PromotionCodeList = lazy(() => import('src/views/promotionCode/promotionCodesList/PromotionCodesList'));
const PromotionCodeEdit = lazy(() => import('src/views/promotionCode/promotionCodeEdit/PromotionCodeEdit'));

const AssistantAnswer = lazy(() => import('src/views/assistantAnswer/AssistantAnswer'));
const TypeAssistantAnswer = lazy(() => import('src/views/assistantAnswer/TypeAssistantAnswer'));
const CreateAssistantChoice = lazy(() => import('src/views/assistantAnswer/assistantForm/CreateAssistantChoice'));
const EditAssistantChoices = lazy(() => import('src/views/assistantAnswer/assistantForm/EditAssistantChoices'));
const SmsReport = lazy(() => import('src/views/reports/smsReport/SmsReport'));

const PartnerCreate = lazy(() => import('src/views/partner/partnerCreate/PartnerCreate'));
const PartnerList = lazy(() => import('src/views/partner/partnersList/PartnersList'));
const PartnerEdit = lazy(() => import('src/views/partner/partnerEdit/PartnerEdit'));
const PartnerPromotionCode = lazy(() => import('src/views/partner/partnerPromotionCode/PartnerPromotionCodes'));
const PromotionCodeDetails = lazy(() => import('src/views/partner/partnerPromotionCode/PromotionCodeDetails'));

const SystemConfigList = lazy(() => import('src/views/systemConfig/systemConfigList/SystemConfigList'));
const SystemConfigEdit = lazy(() => import('src/views/systemConfig/systemConfigEdit/SystemConfigEdit'));

const CodeRedemptionList = lazy(() => import('src/views/codeRedemption/codeRedemptionList/CodeRedemptionList'));
const CodeRedemptionCreate = lazy(() => import('src/views/codeRedemption/codeRedemptionCreate/CodeRedemptionCreate'));
const CodeRedemptionEdit = lazy(() => import('src/views/codeRedemption/codeRedemptionEdit/CodeRedemptionEdit'));

const InternalApi = lazy(() => import('src/views/intenalApi/InternalApi'));

const LineNotify = lazy(() => import('src/views/lineNotify/LineNotify'));
const LineNotifyDetail = lazy(() => import('src/views/lineNotify/components/LineNotifyDetail'));

const SurveyVerify = lazy(() => import('src/views/reports/surveyVerify/SurveyVerify'));
const CustomOrder = lazy(() => import('src/views/customOrder/CustomOrderList'));
const CustomOrderInfo = lazy(() => import('src/views/customOrder/customOrderInfo/CustomOrderInfo'));

const pfx = '/app';
// prettier-ignore
const routesConfig = [
   {
      path: pfx,
      element: <PrivateRoute component={AuthenLayout} />,
      children: [
         { path: `${pfx}/`, element: <Navigate to={`${pfx}/account`} /> },
         { path: `${pfx}/dashboard`, name: 'Dashboard', element: <Dashboard /> },
         { path: `${pfx}/commission`, name: 'CreateCommission', element: <CreateCommission /> },
         { path: `${pfx}/report`, name: 'Report', element: <Report /> },
         { path: `${pfx}/seller-report`, name: 'SellerReport', element: <SellerReport /> },
         { path: `${pfx}/monthly-sale-report`, name: 'MonthlySaleReport', element: <MonthlySaleReport /> },
         { path: `${pfx}/sell-commission-report`, name: 'SellCommissionReport', element: <SellCommissionReport /> },
         {
            path: `${pfx}/customer-by-package-type-report`,
            name: 'CustomerByPackageTypeReport',
            element: <CustomerByPackageTypeReport />
         },
         { path: `${pfx}/customer-by-sale-report`, name: 'CustomerBySaleReport', element: <CustomerBySaleReport /> },
         {
            path: `${pfx}/customer-by-advisor-report`,
            name: 'CustomerByAdvisorReport',
            element: <CustomerByAdvisorReport />
         },
         { path: `${pfx}/tracking-ads-report`, name: 'TrackingAdsReport', element: <TrackingAdsReport /> },
         { path: `${pfx}/tracking-visit-report`, name: 'TrackingVisitReport', element: <TrackingVisitReport /> },
         {
            path: `${pfx}/tracking-register-report`,
            name: 'TrackingRegisterReport',
            element: <TrackingRegisterReport />
         },
         {
            path: `${pfx}/tracking-package-bought-report`,
            name: 'TrackingPackageBoughtReport',
            element: <TrackingPackageBoughtReport />
         },
         { path: `${pfx}/creator-logs`, name: 'CreatorLogs', element: <CreatorLogs /> },
         { path: `${pfx}/customer-api-activity`, name: 'CustomerApiActivities', element: <CustomerApiActivities /> },
         { path: `${pfx}/customer-api-log-error`, name: 'CustomerApiLogError', element: <CustomerApiLogError /> },
         { path: `${pfx}/backoffice-logs`, name: 'BackofficeLogs', element: <BackofficeLogs /> },
         { path: `${pfx}/device-logs`, name: 'DeviceLogs', element: <DeviceLogs /> },
         { path: `${pfx}/creator-customer-logs`, name: 'CreatorCustomerLogs', element: <CreatorCustomerLogs /> },
         { path: `${pfx}/backoffice-user-logs`, name: 'BackofficeUserLogs', element: <BackofficeUserLogs /> },
         { path: `${pfx}/registration-report`, name: 'CustomersRegistReport', element: <CustomersRegistReport /> },
         { path: `${pfx}/survey-response-summary-report`, name: 'SurvayResponseSummaryReport', element: <SurvayResponseSummaryReport /> },
         { path: `${pfx}/survey-response-report`, name: 'SurvayResponseReport', element: <SurvayResponseReport /> },
         {
            path: `${pfx}/customer-storage-report`,
            name: 'CustomersStorageTeamReport',
            element: <CustomersStorageTeamReport />
         },
         {
            path: `${pfx}/customer-storage-report/:teamId`,
            name: 'CustomersStorageSurveyReport',
            element: <CustomersStorageSurveyReport />
         },
         { path: `${pfx}/survey-report`, name: 'SurveyReport', element: <SurveyReport /> },
         { path: `${pfx}/question-type-usage-report`, name: 'QuestionTypeUsageReport', element: <QuestionTypeUsageReport /> },
         { path: `${pfx}/team-usage-report`, name: 'TeamUsageReport', element: <TeamUsageReport /> },
         { path: `${pfx}/customer-usage-report`, name: 'CustomerUsageReport', element: <CustomerUsageReport /> },
         { path: `${pfx}/alternative-dashboard`, name: 'AlternativeDashboard', element: <AlternativeDashboard /> },
         { path: `${pfx}/account`, name: 'UserAccount', element: <UserAccount /> },
         { path: `${pfx}/users`, name: 'UserList', element: <UserList /> },
         { path: `${pfx}/users/create`, name: 'UserCreate', element: <UserCreate /> },
         { path: `${pfx}/users/:userId/edit`, name: 'UserEdit', element: <UserEdit /> },
         { path: `${pfx}/roles`, name: 'RoleList', element: <RoleList /> },
         { path: `${pfx}/roles/create`, name: 'RoleCreate', element: <RoleCreate /> },
         { path: `${pfx}/roles/:code/edit`, name: 'RoleEdit', element: <RoleEdit /> },
         { path: `${pfx}/promotion-code`, name: 'PromotionCodeList', element: <PromotionCodeList /> },
         { path: `${pfx}/promotion-code/create`, name: 'PromotionCodeCreate', element: <PromotionCodeCreate /> },
         { path: `${pfx}/promotion-code/:id/edit`, name: 'PromotionCodeEdit', element: <PromotionCodeEdit /> },
         { path: `${pfx}/contacts`, name: 'ContactUs', element: <ContactUs /> },
         { path: `${pfx}/packages`, name: 'PackageList', element: <PackageList /> },
         { path: `${pfx}/packages/create`, name: 'PackageCreate', element: <PackageCreate /> },
         { path: `${pfx}/packages/creator`, name: 'PackageCreator', element: <PackageCreator /> },
         { path: `${pfx}/packages/:id/edit`, name: 'PackageEdit', element: <PackageEdit /> },
         { path: `${pfx}/packages/multi-edit`, name: 'PackageMultiEdit', element: <PackageMultiEdit /> },
         { path: `${pfx}/surveybanks`, name: 'SurveybankList', element: <SurveybankList /> },
         { path: `${pfx}/surveybanks/create`, name: 'SurveybankCreate', element: <SurveybankCreate /> },
         { path: `${pfx}/surveybanks/:id/edit`, name: 'SurveybankEdit', element: <SurveybankEdit /> },
         { path: `${pfx}/surveybanks/multi-edit`, name: 'SurveybankMultiEdit', element: <SurveybankMultiEdit /> },
         { path: `${pfx}/customers`, name: 'CustomerList', element: <CustomerList /> },
         { path: `${pfx}/customer/create`, name: 'CustomerCreate', element: <CustomerCreate /> },
         { path: `${pfx}/customer/:id/edit`, name: 'CustomerEdit', element: <CustomerEdit /> },
         { path: `${pfx}/subrogation-approval`, name: 'SubrogationApprove', element: <SubrogationApprove /> },
         { path: `${pfx}/advisor-customers`, name: 'ListAdvisorCustomer', element: <ListAdvisorCustomer /> },
         { path: `${pfx}/seller-customers`, name: 'ListSellerCustomer', element: <ListSellerCustomer /> },
         { path: `${pfx}/products`, name: 'ProductListView', element: <ProductListView /> },
         { path: `${pfx}/orders/all-customers`, name: 'OrderCustomers', element: <OrderCustomers /> },
         { path: `${pfx}/orders/:cusid`, name: 'OrdersList', element: <OrdersList /> },
         { path: `${pfx}/order/:orderid`, name: 'OrderInfo', element: <OrderInfo /> },
         { path: `${pfx}/invoices`, name: 'InvoiceListView', element: <InvoiceListView /> },
         { path: `${pfx}/survey-transfer`, name: 'SurveyTransfer', element: <SurveyTransfer /> },
         { path: `${pfx}/import-export-survey`, name: 'ImportExportSurvey', element: <ImportExportSurvey /> },
         { path: `${pfx}/articles`, name: 'ArticleList', element: <ArticleList /> },
         { path: `${pfx}/articles/create`, name: 'ArticleCreate', element: <ArticleCreate /> },
         { path: `${pfx}/articles/:id/edit`, name: 'ArticleEdit', element: <ArticleEdit /> },
         { path: `${pfx}/articles-plus`, name: 'ArticlePlusList', element: <ArticlePlusList /> },
         { path: `${pfx}/articles-plus/create`, name: 'ArticlePlusCreate', element: <ArticlePlusCreate /> },
         { path: `${pfx}/articles-plus/:id/edit`, name: 'ArticlePlusEdit', element: <ArticlePlusEdit /> },
         { path: `${pfx}/privacy-policy`, name: 'PrivacyPolicyList', element: <PrivacyPolicyList /> },
         { path: `${pfx}/privacy-policy/create`, name: 'PrivacyPolicyCreate', element: <PrivacyPolicyCreate /> },
         { path: `${pfx}/privacy-policy/:id/edit`, name: 'PrivacyPolicyEdit', element: <PrivacyPolicyEdit /> },
         { path: `${pfx}/term-and-condition`, name: 'TermAndConditionList', element: <TermAndConditionList /> },
         {
            path: `${pfx}/term-and-condition/create`,
            name: 'TermAndConditionCreate',
            element: <TermAndConditionCreate />
         },
         {
            path: `${pfx}/term-and-condition/:id/edit`,
            name: 'TermAndConditionEdit',
            element: <TermAndConditionEdit />
         },
         { path: `${pfx}/image-categories`, name: 'ImageCategories', element: <ImageCategories /> },
         { path: `${pfx}/image-gallery`, name: 'ImageGellary', element: <ImageGellary /> },
         { path: `${pfx}/payment-report`, name: 'PaymentReport', element: <PaymentReport /> },
         { path: `${pfx}/assistant-answer`, name: 'AssistantAnswer', element: <AssistantAnswer /> },
         { path: `${pfx}/assistant-answer/:type`, name: 'TypeAssistantAnswer', element: <TypeAssistantAnswer /> },
         {
            path: `${pfx}/assistant-answer/create/:type`,
            name: 'CreateAssistantChoice',
            element: <CreateAssistantChoice />
         },
         { path: `${pfx}/assistant-answer/edit/:id`, name: 'CreateAssistantChoice', element: <EditAssistantChoices /> },
         { path: `${pfx}/sms-report`, name: 'SmsReport', element: <SmsReport /> },
         { path: `${pfx}/partners`, name: 'PartnerList', element: <PartnerList /> },
         { path: `${pfx}/partner/create`, name: 'PartnerCreate', element: <PartnerCreate /> },
         { path: `${pfx}/partner/:partnerId/edit`, name: 'PartnerEdit', element: <PartnerEdit /> },
         { path: `${pfx}/partner-promotion-codes`, name: 'PartnerPromotionCode', element: <PartnerPromotionCode /> },
         {
            path: `${pfx}/partner/:id/promotion-codes-details`,
            name: 'PromotionCodeDetails',
            element: <PromotionCodeDetails />
         },

         { path: `${pfx}/system-config`, name: 'SystemConfigList', element: <SystemConfigList /> },
         { path: `${pfx}/system-config/:code/edit`, name: 'SystemConfigEdit', element: <SystemConfigEdit /> },

         { path: `${pfx}/code-redemption`, name: 'CodeRedemptionList', element: <CodeRedemptionList /> },
         { path: `${pfx}/code-redemption/create`, name: 'CodeRedemptionCreate', element: <CodeRedemptionCreate /> },
         { path: `${pfx}/code-redemption/:id/edit`, name: 'CodeRedemptionEdit', element: <CodeRedemptionEdit /> },

         { path: `${pfx}/internal-api`, name: 'InternalApi', element: <InternalApi /> },

         { path: `${pfx}/creator-notifications`, name: 'LineNotify', element: <LineNotify /> },
         { path: `${pfx}/creator-notifications/:id`, name: 'LineNotifyDetail', element: <LineNotifyDetail /> },

         { path: `${pfx}/survey-verify`, name: 'SurveyVerify', element: <SurveyVerify /> },
         { path: `${pfx}/custom-order`, name: 'CustomOrder', element: <CustomOrder /> },
         { path: `${pfx}/custom-order/:id`, name: 'CustomOrderInfo', element: <CustomOrderInfo /> },
         // {
         //    path: `${pfx}/custom-order/:customOrderId/invoice`,
         //    name: 'CustomOrderInvoice',
         //    element: <CustomOrderInvoice />
         // },
         // {
         //    path: `${pfx}/custom-order/:customOrderId/upload`,
         //    name: 'CustomOrderUpload',
         //    element: <CustomOrderUpload />
         // },
         // {
         //    path: `${pfx}/custom-order/:customOrderId/receipt`,
         //    name: 'CustomOrderReceipt',
         //    element: <CustomOrderReceipt />
         // },

         // canceled
         // { path: `${pfx}/sample-research`, element: <SampleResearch /> },
         // { path: `${pfx}/sample-research/create`, element: <SampleResearchCreate /> },
         // { path: `${pfx}/sample-research/:id/edit`, element: <SampleResearchEdit /> },
         { path: `${pfx}/extra/editors/draft-js`, name: 'DraftEditorView', element: <DraftEditorView /> },
         { path: `${pfx}/settings`, name: 'SettingsView', element: <SettingsView /> },
         { path: `${pfx}/loading`, name: 'LoadingView', element: <LoadingView /> },
         { path: `${pfx}/*`, name: 'NotFoundView', element: <NotFoundView /> }
      ]
   },
   {
      path: '/',
      element: <UnAuthenLayout />,
      children: [
         { path: '/', element: <Navigate to="/login" /> },
         { path: 'login', element: <PublicRoute component={LoginView} /> },
         { path: 'forgot-password', element: <PublicRoute component={ForgotPassword} /> },
         { path: '404', element: <NotFoundView /> },
         { path: '*', element: <NotFoundView /> },
         { path: '404', element: <NotFoundView /> },
         { path: 'reset-password', element: <ResetPassword /> },

         { path: 'change-password', element: <PrivateRoute component={ChangeFirstPassword} /> }
      ]
   }
];

export const allRoutes = routesConfig.flatMap((route) => route.children).map(({ element, ...rest }) => rest);

function Routes() {
   const routing = useRoutes(routesConfig);
   return <Suspense fallback={<LoadingScreen />}>{routing}</Suspense>;
}

export default Routes;
