import {
   AUTHENTICATION_SUCCESS,
   AUTHENTICATION_FAILURE,
   LOGGED_OUT,
   CURRENT_HOST_PDF,
   CURRENT_HOST_PDF_FAILURE,
   ALL_COUNTRIES,
   ALL_COUNTRIES_FAILURE,
   CURRENT_TOTAL_PENDING_SUBROGATION,
   CURRENT_TOTAL_NEW_STATUS_CONTACT_US,
   STORE_HISTORY_PARAMS_PAGES,
   CURRENT_TOTAL_UNREAD_STATUS_LINE_NOTIFY
} from '../actions/authenAction';

let isAuth = localStorage.getItem('access-token');
const initialState = {
   isAuth: Boolean(isAuth),
   user: null,
   error: null,
   countries: [],
   hostPdf: null,
   totalPendingSubrogation: null,
   totalNewStatusContactUs: null,
   historyPagesParams: {},
   totalUnreadStatusLineNotify: null
};

export default function authenReducer(state = initialState, action) {
   switch (action.type) {
      case AUTHENTICATION_SUCCESS:
         return {
            ...state,
            isAuth: true,
            user: action.payload.user,
            historyPagesParams: action.payload.historyPagesParams
         };

      case AUTHENTICATION_FAILURE:
         return {
            ...state,
            isAuth: false,
            user: null,
            error: action.payload.error
         };

      case ALL_COUNTRIES:
         return {
            ...state,
            countries: action.payload.countries
         };

      case ALL_COUNTRIES_FAILURE:
         return {
            ...state,
            countries: []
         };
         
      case CURRENT_HOST_PDF:
         return {
            ...state,
            hostPdf: action.payload.host
         };

      case CURRENT_HOST_PDF_FAILURE:
         return {
            ...state,
            hostPdf: null
         };

      case LOGGED_OUT:
         return {
            ...state,
            isAuth: false,
            user: null,
            error: null
         };

      case CURRENT_TOTAL_PENDING_SUBROGATION:
         return {
            ...state,
            totalPendingSubrogation: action.payload.totalPendingSubrogation
         };

      case CURRENT_TOTAL_NEW_STATUS_CONTACT_US:
         return {
            ...state,
            totalNewStatusContactUs: action.payload.totalNewStatusContactUs
         };
      case STORE_HISTORY_PARAMS_PAGES:
         return {
            ...state,
            historyPagesParams: action.payload.historyPagesParams
         };
      case CURRENT_TOTAL_UNREAD_STATUS_LINE_NOTIFY:
         return {
            ...state,
            totalUnreadStatusLineNotify: action.payload.totalUnreadStatusLineNotify
         };

      default:
         return state;
   }
}
