/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
   Box,
   Button,
   IconButton,
   Link,
   List,
   ListItem,
   // ListItemIcon,
   ListItemText,
   Tooltip,
   Typography,
   ListItemAvatar,
   Avatar,
   makeStyles
} from '@material-ui/core';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import ClearIcon from '@material-ui/icons/Clear';
import bytesToSize from 'src/common/bytesToSize';
import LoadingBackdrop from 'src/components/loading/LoadingBackdrop';

const useStyles = makeStyles((theme) => ({
   root: {
      position: 'relative'
   },
   dropZone: {
      border: `1px dashed ${theme.palette.divider}`,
      padding: theme.spacing(3),
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      alignItems: 'center',
      '&:hover': {
         backgroundColor: theme.palette.action.hover,
         opacity: 0.5,
         cursor: 'pointer'
      }
   },
   dragActive: {
      backgroundColor: theme.palette.action.active,
      opacity: 0.5
   },
   image: {
      width: 130
   },
   info: {
      marginTop: theme.spacing(1)
   },
   list: {
      // maxHeight: 320
   },
   actions: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'sticky',
      bottom: '-8px',
      backgroundColor: '#fff',
      '& > * + *': {
         marginLeft: theme.spacing(2)
      }
   },
   previewImg: {
      width: 50,
      height: 50,
      marginRight: 24,
      borderRadius: 8,
      border: 'solid 2px #ddd'
   }
}));

function FilesDropzone({ className, onUploadFiles, ...rest }) {
   const classes = useStyles();
   const [files, setFiles] = useState([]);
   const [loading, setLoading] = useState(false);

   const handleDrop = useCallback((acceptedFiles) => {
      setFiles((prevFiles) =>
         [...prevFiles].concat(
            acceptedFiles.map((file) =>
               Object.assign(file, {
                  preview: URL.createObjectURL(file)
               })
            )
         )
      );
   }, []);

   const handleClickUploadFiles = () => {
      onUploadFiles(files, handleRemoveAll, setLoading);
   };
   const handleRemoveAll = () => {
      setFiles([]);
   };
   const handleRemove = (index) => {
      setFiles((prevFiles) => [...prevFiles.slice(0, index), ...prevFiles.slice(index + 1)]);
   };

   const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: 'image/*',
      onDrop: handleDrop
   });

   return (
      <div className={clsx(classes.root, className)} {...rest}>
         <LoadingBackdrop loading={loading} />
         <div
            className={clsx({
               [classes.dropZone]: true,
               [classes.dragActive]: isDragActive
            })}
            {...getRootProps()}
         >
            <input {...getInputProps()} />
            <div>
               <img alt="Select file" className={classes.image} src="/static/images/undraw_add_file2_gvbb.svg" />
            </div>
            <div>
               <Typography gutterBottom variant="h3">
                  Select files
               </Typography>
               <Box mt={2}>
                  <Typography color="textPrimary" variant="body1">
                     Drop files here or click <Link underline="always">browse</Link> thorough your machine
                  </Typography>
               </Box>
            </div>
         </div>
         {files.length > 0 && (
            <Box m={2}>
               <Typography color="textPrimary" variant="body1">
                  Uploading Images preview
               </Typography>
            </Box>
         )}
         {files.length > 0 && (
            <>
               <PerfectScrollbar options={{ suppressScrollX: true }}>
                  <List className={classes.list}>
                     {files.map((file, i) => (
                        <ListItem divider={i < files.length - 1} key={i}>
                           {/* <ListItemIcon>
                                        <FileCopyIcon />
                                    </ListItemIcon> */}
                           <ListItemAvatar>
                              <Avatar
                                 classes={{ root: classes.previewImg }}
                                 alt={`Avatar n°${file.name}`}
                                 src={file.preview}
                              />
                           </ListItemAvatar>
                           <ListItemText
                              primary={file.name}
                              primaryTypographyProps={{ variant: 'h5' }}
                              secondary={bytesToSize(file.size)}
                           />
                           <Tooltip title="Remove">
                              <IconButton onClick={() => handleRemove(i)} edge="end">
                                 <ClearIcon />
                              </IconButton>
                           </Tooltip>
                        </ListItem>
                     ))}
                  </List>
               </PerfectScrollbar>
               <div className={classes.actions}>
                  <Button onClick={handleRemoveAll} size="small">
                     Remove all
                  </Button>
                  <Button onClick={handleClickUploadFiles} color="secondary" size="small" variant="contained">
                     Upload files
                  </Button>
               </div>
            </>
         )}
      </div>
   );
}

FilesDropzone.propTypes = {
   className: PropTypes.string,
   onUploadFiles: PropTypes.func
};

export default FilesDropzone;
