import React from 'react';
import 'src/components/loading/loadingSquare.css';

const SquareSpinner = () => {
   return (
      <div className="App">
         <div className="loading">
            {[...Array(7)].map((_, i) => (
               <div key={i} className="loading__square" />
            ))}
         </div>
      </div>
   );
};

export default SquareSpinner;
