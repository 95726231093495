export const parseNumber = (num) => {
   if (isNaN(Number(num))) {
      return null;
   }
   return Number(num);
};

export function toFixed(number, decimals) {
   return Number(Math.round(number + 'e' + decimals) + 'e-' + decimals);
   // Number.prototype.round = function(places) {
   //    return +(Math.round(this + 'e+' + places) + 'e-' + places);
   // };
   // return Number.

   //    var x = Math.pow(10, Number(decimals) + 1);
   //    return (Number(number) + 1 / x).toFixed(decimals);
}
export const parse2Decimal = (num) => toFixed(num, 2);
export const parseCustomDecimal = (num, decimal) => toFixed(num, decimal);
export const parseWithoutDecimal = (num) => toFixed(num, 0);

export const parsePriceForm = (num) => {
   if (!parseNumber(num)) {
      return '0.00';
   }
   return (
      parseNumber(num)?.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2
      }) || '0.00'
   );
};

export const parseDecimalForm = (num) => {
   if (!parseNumber(num)) {
      return '0.00';
   }
   return (
      parseNumber(num)?.toLocaleString(undefined, {
         minimumFractionDigits: 0,
         maximumFractionDigits: 7
      }) || '0.00'
   );
};

export const calculateDiscountedPrice = (originalPrice, discountData) => {
   const parsedOriginalPrice = parseNumber(originalPrice);
   if (Array.isArray(discountData) && discountData.length > 0) {
      let discountedPrice = parsedOriginalPrice;
      discountData.forEach((discountData) => {
         const newOriginalPrice = discountedPrice;
         if (discountData.discount_cal_type === 'percentage') {
            // discounted_price = original_price - (original_price * discount / 100)
            discountedPrice = parseWithoutDecimal(
               // newOriginalPrice - (newOriginalPrice * parseNumber(discountData.discount)) / 100
               newOriginalPrice - Math.round((newOriginalPrice * parseNumber(discountData.discount)) / 100)
            );
         } else {
            discountedPrice = newOriginalPrice - parseNumber(discountData.discount);
         }
      });
      return discountedPrice;
   }
   return parsedOriginalPrice;
};

export const calculatePackageTotalPrice = ({ selectedPackage, seats, packageDiscount }) => {
   const pricePerSeatNum = parseNumber(selectedPackage?.price_per_seat);
   const seatsNum = parseNumber(seats);
   const priceB4Discount = pricePerSeatNum * seatsNum;
   return calculateDiscountedPrice(priceB4Discount, packageDiscount);
};

export const calculateUnusePrice = (packageTotalPrice, quotationSetDiscount, unuseDays, durationDays) => {
   const basePrice = calculateDiscountedPrice(packageTotalPrice, quotationSetDiscount);
   const unusePrice = (basePrice * parseNumber(unuseDays)) / parseNumber(durationDays);
   return parseWithoutDecimal(unusePrice);
};

export const netTotalPrice = (grandTotal, quotationSetWithholdingTax) => {
   const withHolding = (grandTotal * parseNumber(quotationSetWithholdingTax)) / 107;
   const netTotal = grandTotal - withHolding;
   //    return netTotal
   return parse2Decimal(netTotal);

   // const excludeVat = (grandTotal * 100) / 107;
   // const withHolding = (excludeVat * parseNumber(quotationSetWithholdingTax)) / 100;
   // const netTotal = grandTotal - withHolding;
   // return parse2Decimal(netTotal);
};
