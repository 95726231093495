import {
   FETCH_ORDER_BEGIN,
   FETCH_ORDER_SUCCESS,
   FETCH_ORDER_FAILURE,
   FETCH_CREATE_ORDER_SUCCESS,
   DURING_FETCH_ORDER,
   DISPLAY_LOADING
} from '../actions/customerOrdersAction';

const initialState = {
   isLoading: false,
   customer: {},
   default_additional_tax_rate: null,
   orders: [],
   packages: [],
   can_create: false,
   can_upgrade: false,
   error: null,
   pdf_host: ''
};

export default function customerOrdersReducer(state = initialState, action) {
   switch (action.type) {
      case FETCH_ORDER_BEGIN:
         return {
            ...initialState,
            isLoading: true
         };
      case DURING_FETCH_ORDER:
         return {
            ...state,
            isLoading: true
         };

      case FETCH_ORDER_SUCCESS:
         return {
            ...state,
            isLoading: false,
            customer: action.payload.customer,
            default_additional_tax_rate: action.payload.default_additional_tax_rate,
            orders: action.payload.orders,
            packages: action.payload.packages,
            can_create: action.payload.can_create,
            error: null,
            pdf_host: action.payload.pdf_host
         };

      case FETCH_CREATE_ORDER_SUCCESS:
         return {
            ...state,
            isLoading: false,
            can_create: false,
            error: null
         };

      case FETCH_ORDER_FAILURE:
         return {
            ...state,
            isLoading: false,
            // customer: {},
            // default_additional_tax_rate: null,
            // orders: [],
            // packages: [],
            error: action.payload.error
         };

      case DISPLAY_LOADING:
         return {
            ...state,
            isLoading: action.payload.isLoading
         };

      default:
         return state;
   }
}
