import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { storeParams } from 'src/redux/actions/authenAction';

const historyParamsPage = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const { historyPagesParams } = useSelector((state) => state.authen);

   const pageName = location.pathname;

   const storeParamsAsHistory = (params) => {
      dispatch(storeParams(pageName, params));
   };

   const getParamHistory = () => {
      return historyPagesParams[pageName] || {};
   };

   return { storeParamsAsHistory, getParamHistory };
};

export default historyParamsPage;
