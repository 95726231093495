import React, { useState } from 'react';
import { Divider, FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
   filterDate: {
      display: 'flex',
      alignItems: 'flex-start',
      // padding: '8px',
      gap: '4px',
      [theme.breakpoints.down('sm')]: {
         display: 'flex',
         flexDirection: 'column',
         width: '100%',
         padding: '0px'
      }
   }
}));

const FilterDateSearch = ({
   className,
   children,
   onFilterSearch,
   filterSearch,
   handleChangeTiming,
   handleChangeDate,
   disabled,
   title,
   ...rest
}) => {
   const classes = useStyles();
   const [error, setError] = useState();

   const handleChange = (date, name) => {
      console.log('filterSearch.date.end', filterSearch.date.end)
      if (name === 'start' && filterSearch.date.end) {
         if (date >= filterSearch.date.end) {
            setError({ start: 'วันที่เริ่มจะต้องอยู่ก่อนวันที่สิ้นสุด' });
            return;
         } else {
            setError(null);
         }
      } else if (name === 'end' && filterSearch.date.start) {
         if (filterSearch.date.start >= date) {
            setError({ end: 'วันที่สิ้นสุดจะต้องอยู่หลังวันที่เริ่ม' });
            return;
         } else {
            setError(null);
         }
      }
      handleChangeDate(date, name)
   }

   const changeTiming = (e) => {
      setError(null)
      handleChangeTiming(e)
   }

   return (
      <Box className={clsx(classes.filterDate)}>
         <FormControl fullWidth variant="outlined" margin="dense" size="small">
            <InputLabel id="demo-simple-select-label">{title}</InputLabel>
            <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={filterSearch.date.type}
               onChange={(e) => changeTiming(e)}
               label={title}
            >
               <MenuItem disabled={disabled} value={'all'}>
                  ทั้งหมด
               </MenuItem>
               <Divider />
               <MenuItem disabled={disabled} value={'day'}>
                  วัน
               </MenuItem>
               <MenuItem value={'month'}>เดือน</MenuItem>
               <MenuItem disabled={disabled} value={'year'}>
                  ปี
               </MenuItem>
               <MenuItem disabled={disabled} value={'between'}>
                  ระหว่างวันที่
               </MenuItem>
               <Divider />
               <MenuItem disabled={disabled} value={'today'}>
                  วันนี้
               </MenuItem>
               <MenuItem disabled={disabled} value={'yesterday'}>
                  เมื่อวาน
               </MenuItem>
               <MenuItem value={'this_month'}>เดือนนี้</MenuItem>
               <MenuItem value={'last_month'}>เดือนก่อน</MenuItem>
               <MenuItem disabled={disabled} value={'this_year'}>
                  ปีนี้
               </MenuItem>
               <MenuItem disabled={disabled} value={'last_year'}>
                  ปีก่อน
               </MenuItem>
               <Divider />
               <MenuItem disabled={disabled} value={'one_day'}>
                  ข้อมูลย้อนหลัง 1 วัน
               </MenuItem>
               <MenuItem disabled={disabled} value={'three_days'}>
                  ข้อมูลย้อนหลัง 3 วัน
               </MenuItem>
               <MenuItem disabled={disabled} value={'one_week'}>
                  ข้อมูลย้อนหลัง 1 สัปดาห์
               </MenuItem>
               <MenuItem value={'one_month'}>ข้อมูลย้อนหลัง 1 เดือน</MenuItem>
               <MenuItem value={'three_months'}>ข้อมูลย้อนหลัง 3 เดือน</MenuItem>
               <MenuItem value={'six_months'}>ข้อมูลย้อนหลัง 6 เดือน</MenuItem>
               <MenuItem disabled={disabled} value={'one_year'}>
                  ข้อมูลย้อนหลัง 1 ปี
               </MenuItem>
            </Select>
         </FormControl>
         {/* {['', null, undefined].includes(filterSearch?.date?.type) ? null : <Box mx={0.5} />} */}
         {filterSearch?.date?.type === 'day' ? (
            <KeyboardDatePicker
               inputVariant="outlined"
               fullWidth
               size="small"
               margin="dense"
               label="วัน"
               name="start_date"
               value={filterSearch.date.start}
               format="DD/MM/YYYY"
               minDate={moment(new Date()).subtract(10, 'year').toISOString()}
               maxDate={moment(new Date()).toISOString()}
               onChange={(newValue) => handleChangeDate(newValue, 'start')}
               views={['date']}
            />
         ) : null}
         {filterSearch?.date?.type === 'month' ? (
            <KeyboardDatePicker
               inputVariant="outlined"
               fullWidth
               size="small"
               margin="dense"
               label="เดือน"
               name="start_date"
               value={filterSearch.date.start}
               format="MMMM YYYY"
               minDate={moment(new Date()).subtract(10, 'year').toISOString()}
               maxDate={moment(new Date()).toISOString()}
               onChange={(newValue) => handleChangeDate(newValue, 'start')}
               views={['year', 'month']}
            />
         ) : null}
         {filterSearch?.date?.type === 'year' ? (
            <KeyboardDatePicker
               inputVariant="outlined"
               fullWidth
               size="small"
               margin="dense"
               label="ปี"
               name="start_date"
               value={filterSearch.date.start}
               format="YYYY"
               minDate={moment(new Date()).subtract(10, 'year').toISOString()}
               maxDate={moment(new Date()).endOf('year').toISOString()}
               onChange={(newValue) => handleChangeDate(newValue, 'start')}
               views={['year']}
            />
         ) : null}
         {filterSearch?.date?.type === 'between' ? (
            <>
               <KeyboardDateTimePicker
                  ampm={false}
                  inputVariant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  label="วันที่เริ่ม"
                  name="start_date"
                  value={filterSearch.date.start}
                  format="DD/MM/YYYY HH:mm"
                  minDate={moment(new Date()).subtract(10, 'year').toISOString()}
                  maxDate={moment(new Date()).toISOString()}
                  onChange={(newValue) => handleChange(newValue, 'start')}
                  helperText={error?.start}
                  error={Boolean(error?.start)}
               />
               {/* <Box mx={0.5} /> */}
               <KeyboardDateTimePicker
                  ampm={false}
                  inputVariant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  label="วันที่สิ้นสุด"
                  name="end_date"
                  value={filterSearch.date.end}
                  format="DD/MM/YYYY HH:mm"
                  minDate={moment(new Date()).subtract(10, 'year').toISOString()}
                  maxDate={moment(new Date()).toISOString()}
                  onChange={(newValue) => handleChange(newValue, 'end')}
                  helperText={error?.end}
                  error={Boolean(error?.end)}
               />
            </>
         ) : null}
         {/* {filterSearch?.date?.type === 'previous' ? (
            <FormControl fullWidth variant="outlined" margin="dense" size="small">
               <InputLabel id="demo-simple-select-label">{title}</InputLabel>
               <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterSearch.date.type}
                  onChange={handleChangeTiming}
                  label={title}
               >
                  <MenuItem disabled={disabled} value={'all'}>
                     ทั้งหมด
                  </MenuItem>
                  <MenuItem value={'day'}>วัน</MenuItem>
                  <MenuItem value={'month'}>เดือน</MenuItem>
                  <MenuItem value={'year'}>ปี</MenuItem>
                  <MenuItem value={'between'}>ระหว่างวันที่</MenuItem>
                  <MenuItem value={'previous'}>ข้อมูลย้อนหลัง</MenuItem>
               </Select>
            </FormControl>
         ) : null} */}
      </Box>
   );
};

export default FilterDateSearch;
