import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Badge, Button, Collapse, ListItem, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
   item: {
      display: 'block',
      paddingTop: 0,
      paddingBottom: 0
   },
   itemLeaf: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0
   },
   button: {
      color: theme.palette.text.secondary,
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%'
   },
   buttonLeaf: {
      color: theme.palette.text.secondary,
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
      fontWeight: theme.typography.fontWeightRegular,
      '&.depth-0': {
         '& $title': {
            fontWeight: theme.typography.fontWeightMedium
         }
      }
   },
   icon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
   },
   title: {
      marginRight: 'auto'
   },
   active: {
      color: theme.palette.primary.main,
      '& $title': {
         fontWeight: theme.typography.fontWeightMedium
      },
      '& $icon': {
         color: theme.palette.primary.main
      }
   }
}));

const RouterLink = React.forwardRef((props, ref) => {
   const classes = useStyles();
   const { className, ...filteredProps } = props;


   return (
      <NavLink ref={ref} {...filteredProps} className={({ isActive }) => clsx(isActive && classes.active, className)} />
   );
});

function NavItem({ title, href, depth, children, icon: Icon, className, open: openProp, info: Info, ...rest }) {
   const classes = useStyles();
   const [open, setOpen] = useState(openProp);
   const totalPendingSubrogation = useSelector(state => state?.authen?.totalPendingSubrogation);
   const totalNewStatusContactUs = useSelector(state => state?.authen?.totalNewStatusContactUs);
   const totalUnreadStatusLineNotify = useSelector((state) => state?.authen?.totalUnreadStatusLineNotify);
   const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   let paddingLeft = 8;

   if (depth > 0) {
      paddingLeft = 32 + 8 * depth;
   }

   const style = { paddingLeft };

   if (children) {
      return (
         <ListItem className={clsx(classes.item, className)} disableGutters key={title} {...rest}>
            <Button className={classes.button} onClick={handleToggle} style={style}>
               {Icon && <Icon className={classes.icon} size="20" />}
               <span className={classes.title}>{title}</span>
               {open ? (
                  <ExpandLessIcon size="small" color="inherit" />
               ) : (
                  <ExpandMoreIcon size="small" color="inherit" />
               )}
            </Button>
            <Collapse in={open}>{children}</Collapse>
         </ListItem>
      );
   }

   return (
      <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
         <Button
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={RouterLink}
            end={false}
            style={style}
            to={href}
         >
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
            {title === 'Subrogation Approval' ? (
               <Badge badgeContent={totalPendingSubrogation} color="primary">
                  {Info && <Info className={classes.info} />}
               </Badge>
            ) : (
               <Badge badgeContent={0} color="primary">
                  {Info && <Info className={classes.info} />}
               </Badge>
            )}
            {title === 'Contact us' ? (
               <Badge badgeContent={totalNewStatusContactUs} color="primary">
                  {Info && <Info className={classes.info} />}
               </Badge>
            ) : (
               <Badge badgeContent={0} color="primary">
                  {Info && <Info className={classes.info} />}
               </Badge>
            )}
            {title === 'Line Notify' ? (
               <Badge badgeContent={totalUnreadStatusLineNotify} color="primary">
                  {Info && <Info className={classes.info} />}
               </Badge>
            ) : (
               <Badge badgeContent={0} color="primary">
                  {Info && <Info className={classes.info} />}
               </Badge>
            )}
         </Button>
      </ListItem>
   );
}

NavItem.propTypes = {
   children: PropTypes.node,
   className: PropTypes.string,
   depth: PropTypes.number.isRequired,
   href: PropTypes.string,
   icon: PropTypes.any,
   info: PropTypes.any,
   open: PropTypes.bool,
   title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
   open: false
};

export default NavItem;
