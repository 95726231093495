import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
   Box,
   Card,
   CardHeader,
   CardContent,
   Divider,
   makeStyles,
   FormControl,
   InputLabel,
   Select,
   MenuItem
} from '@material-ui/core';
import LoadingBackdrop from 'src/components/loading/LoadingBackdrop';
import BarChart from './BarChart';

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: 16,
      position: 'relative'
   },
   chart: {
      height: '100%'
   },
   legend: {
      marginRight: '15px',
      display: 'flex'
   },
   headerRoot: {
      [theme.breakpoints.down(720)]: {
         alignItems: 'flex-start'
      },
      [theme.breakpoints.down(536)]: {
         flexDirection: 'column',
         alignItems: 'center'
      }
   },
   headerAction: {
      display: 'flex',
      paddingTop: '5px',
      [theme.breakpoints.down(720)]: {
         margin: '10px',
         flexDirection: 'column'
      }
   },
   headerActionContainer: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(720)]: {
         flexDirection: 'column-reverse',
         alignItems: 'flex-end'
      },
      [theme.breakpoints.down(536)]: {
         alignItems: 'center'
      }
   }
}));

const HeaderActions = ({ params, startSearching, setParams }) => {
   const classes = useStyles();

   const handleChangeSort = (event) => {
      const { value } = event.target;
      let [sort, direction] = value.split('|');
      startSearching({ sort_by: sort, sort_direction: direction });
   };
   return (
      <Box className={classes.headerActionContainer}>
         <Box className={classes.headerAction}>
            <FormControl fullWidth variant="outlined" margin="dense" size="small" style={{ minWidth: '250px' }}>
               <InputLabel id="demo-simple-select-label">เรียงจาก</InputLabel>
               <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={params.sort_by + '|' + params.sort_direction}
                  onChange={handleChangeSort}
                  label="เรียงจาก"
               >
                  <MenuItem value={'name|asc'}>ชื่อ (a → z)</MenuItem>
                  <MenuItem value={'name|desc'}>ชื่อ (z → a)</MenuItem>
                  <MenuItem value={'count|asc'}>จำนวนการตอบ (น้อย → มาก)</MenuItem>
                  <MenuItem value={'count|desc'}>จำนวนการตอบ (มาก → น้อย)</MenuItem>
               </Select>
            </FormControl>
         </Box>
      </Box>
   );
};

function CustomersChart({ params, setParams, startSearching, surveyResponseCount, isLoading, className, ...rest }) {
   const classes = useStyles();
   const survey_name = surveyResponseCount.map((data) => data.name);
   const survey_team = surveyResponseCount.map((data) => data.team);
   const survey_team_owner_id = surveyResponseCount.map((data) => data.team_owner?.id);
   const survey_team_owner_name = surveyResponseCount.map((data) => data.team_owner?.name);
   const count = surveyResponseCount.map((data) => data.count);

   return (
      <Card className={clsx(classes.root, className)} {...rest}>
         {isLoading && <LoadingBackdrop loading={isLoading} />}
         <CardHeader
            classes={{ root: classes.headerRoot, action: classes.headerAction }}
            action={<HeaderActions params={params} setParams={setParams} startSearching={startSearching} />}
            title="กราฟแสดงผลข้อมูลจำนวนการตอบแต่ละแบบสอบถาม"
         />
         <Divider />
         <CardContent>
            <PerfectScrollbar>
               <Box height={(survey_name?.length ?? 0) * 40} minHeight={400} minWidth={500}>
                  {
                     <BarChart
                        className={classes.chart}
                        dateType={params.date_type}
                        labels={survey_name ?? []}
                        survey_team={survey_team ?? []}
                        survey_team_owner_id={survey_team_owner_id ?? []}
                        survey_team_owner_name={survey_team_owner_name ?? []}
                        data_count={count ?? []}
                     />
                  }
               </Box>
            </PerfectScrollbar>
         </CardContent>
      </Card>
   );
}

CustomersChart.propTypes = {
   className: PropTypes.string
};

export default CustomersChart;
