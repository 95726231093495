import { combineReducers } from 'redux';
import authen from './authenReducer';
import customerOrders from './customerOrdersReducer';
import quotation from './quotationReducer';
import customerOrder from './customerOrderReducer';

export default combineReducers({
   authen,
   customerOrders,
   quotation,
   customerOrder
});
