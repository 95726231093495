import axios from 'axios';
import JSONBigInt from 'json-bigint';
import { getConfiguration } from 'src/config';

let refreshing = 0;
let failedQueue = [];

const processQueue = (error, token = null) => {
   failedQueue.forEach((prom) => {
      if (error) {
         prom.reject(error);
      } else {
         prom.resolve(token);
      }
   });

   failedQueue = [];

   if (error) {
      localStorage.removeItem('access-token');
      localStorage.removeItem('userid');

      window.location.reload();
   }
};

const axiosToken = axios.create();

axiosToken.defaults.baseURL = getConfiguration().apiUrl;

// interceptors สำหรับจัดการแทรก Jwt token ในส่วนของ header
axiosToken.interceptors.request.use(
   async (config) => {
      const jwtToken = localStorage.getItem('access-token');

      if (jwtToken != null) {
         config.headers = { Authorization: `Bearer ${jwtToken}` };
         // config.transformResponse = (data) => JSONBigInt.parse(data);
      }

      return config;
   },
   (error) => {
      return Promise.reject(error);
   }
);

// interceptors สำหรับจัดการ Jwt token ที่หมดอายุ
axiosToken.interceptors.response.use(
   (response) => {
      if (response.data?.status !== 'Token is Expired') {
         return response;
      }

      // ทำการ request เพื่อเอา token ใหม่ลงมา
      if (!refreshing++) {
         axios
            .post(`${getConfiguration().apiUrl}/refresh`, null, {
               headers: { Authorization: 'Bearer ' + localStorage['access-token'] }
            })
            .then(({ data: { access_token } }) => {
               localStorage['access-token'] = access_token;

               // นำ request ทั้งหมดใน queue ยิง api อีกครั้งด้วย token ใหม่
               processQueue(null, access_token);
            })
            .catch((error) => {
               // บอก request ทั้งหมดใน queue ว่า token เกิดปัญหา ทำให้ไม่สามารถ refresh token ได้
               processQueue(error, null);
            })
            .finally(() => {
               refreshing = 0;
            });
      }

      // นำทุก request ที่ token หมดอายุเก็บลง queue
      return new Promise(function (resolve, reject) {
         failedQueue.push({ resolve, reject });
      })
         .then((token) => {
            const originalRequest = response.config;
            originalRequest.headers = {
               ...originalRequest.headers,
               Authorization: `Bearer ${token}`
            };
            return axios(originalRequest);
         })
         .catch((error) => {
            return Promise.reject(error);
         });
   },
   (error) => {
      return Promise.reject(error);
   }
);

export { axiosToken };
export default axiosToken;
