import React, { useState, } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, alpha, Button, Tooltip, IconButton, SvgIcon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LoadingBackdrop from 'src/components/loading/LoadingBackdrop';
import EditFeature from './EditFeature';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDialog } from 'src/hooks/dialog';
import axiosToken from 'src/common/AxiosToken';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.main,
        position: 'absolute',
        opacity: '0.5 !important',
        backgroundColor: '#fff'
    },
    thead: {
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        '& .MuiTableCell-head': {
            color: theme.palette.primary.main,
            fontWeight: 600,
            borderBottom: 'unset'
        }
    },
    manualIcon: {
        color: theme.palette.primary.main,
        borderRadius: 8,
        paddingLeft: 8
    },
    editIcon: {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        padding: 8,
        borderRadius: 8,
        margin: '0 4px'
    },
    delIcon: {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.08),
        padding: 8,
        borderRadius: 8,
        margin: '0 4px'
    },
    noWrap: {
        whiteSpace: 'nowrap'
    }
}));

function ManualIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg width='20' height='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                <g transform="matrix(0.74 0 0 0.74 12 12)" >
                    <path transform=" translate(-18.5, -18.5)" d="M 16 5 C 12.145666 5 9 8.1456661 9 12 C 9 14.450073 10.345521 16.510309 12.261719 17.761719 C 8.0572865 19.3114 5 23.277903 5 28 L 7 28 C 7 23.045455 11.045455 19 16 19 C 18.933334 19 21.543831 20.423265 23.189453 22.609375 L 19.099609 26.699219 L 18 32 L 23.300781 30.900391 L 31.099609 23.099609 C 31.699609 22.499609 32 21.75 32 21 C 32 20.25 31.699609 19.500391 31.099609 18.900391 C 30.499609 18.300391 29.75 18 29 18 C 28.25 18 27.500391 18.300391 26.900391 18.900391 L 24.605469 21.195312 C 23.348618 19.643471 21.665814 18.457259 19.753906 17.75 C 21.660948 16.496972 23 14.443072 23 12 C 23 8.1456661 19.854334 5 16 5 z M 16 7 C 18.773666 7 21 9.2263339 21 12 C 21 14.773666 18.773666 17 16 17 C 13.226334 17 11 14.773666 11 12 C 11 9.2263339 13.226334 7 16 7 z M 29 20 C 29.25 20 29.499219 20.100781 29.699219 20.300781 C 30.099219 20.700781 30.099219 21.299219 29.699219 21.699219 L 22.300781 29.099609 L 20.599609 29.400391 L 20.900391 27.699219 L 28.300781 20.300781 C 28.500781 20.100781 28.75 20 29 20 z" />
                </g>
            </svg>
        </SvgIcon>
    );
}

const FeatureLimitTable = ({
    className,
    featuresCode,
    activePackage,
    orderFeature,
    isLoading,
    handleLoadOrderFeature,
    canEdit,
    ...rest
}) => {
    const classes = useStyles();
    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();
    const [modeFeature, setModeFeature] = useState(null);
    const [openEditFeature, setOpenEditFeature] = useState(false);
    const [dataEditFeature, setDataEditFeature] = useState(null);

    const handleClickEdit = (feature) => () => {
        console.log('feature', feature)
        setOpenEditFeature(true);
        setDataEditFeature(feature);
        setModeFeature('edit');
    };

    const handleClickAdd = (activePackage) => () => {
        setOpenEditFeature(true);
        setDataEditFeature(activePackage);
        setModeFeature('add');
    };

    const handleCloseEditFeature = () => {
        setDataEditFeature(null);
        setOpenEditFeature(false);
    };

    const handleClickDelete = (id) => () => {
        dialog.confirm('Are you sure to Delete this order feature').then(async (ok) => {
            if (ok) {
                try {
                    const res = await axiosToken.delete(`order2/delete-feature/${id}`);
                    enqueueSnackbar(res.data.message, {
                        variant: 'success',
                        autoHideDuration: 2000
                    });
                    handleLoadOrderFeature()
                } catch (error) {
                    dialog.error(error);
                }
            }
        });
    };

    const listOrderFeatureCode = orderFeature.map((f) => f.feature_code)
    const listFeatureCode = featuresCode.filter((f) => !listOrderFeatureCode.includes(f.code))

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            {isLoading && <LoadingBackdrop loading={isLoading} />}
            {canEdit && (
                <Box display={'flex'} justifyContent={'flex-end'} mb={1}>
                    <Button
                        size="small"
                        onClick={handleClickAdd(activePackage)}
                        variant="contained"
                        color="primary"
                    >Add Feature
                    </Button>
                </Box>)}

            <Box minWidth={'100%'} minHeight={300} >
                <Table>
                    <TableHead classes={{ root: classes.thead }}>
                        <TableRow>
                            <TableCell align="center">
                                Feature
                            </TableCell>
                            <TableCell align="center">
                                Limit Type
                            </TableCell>
                            <TableCell align="center">
                                Value Type
                            </TableCell>
                            <TableCell align="center">
                                Limit
                            </TableCell>
                            <TableCell align="center">
                                Remain
                            </TableCell>
                            {canEdit && (<TableCell align="center">Action</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderFeature.map((feature) => (
                            <TableRow hover key={feature.id}>
                                <TableCell align="left">
                                    {feature?.detail?.description}
                                    {feature?.is_manual && (
                                        <Tooltip title="manual" arrow>
                                            <IconButton size="small" className={classes.manualIcon} >
                                                <ManualIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </TableCell>
                                <TableCell align="center">{feature.limit_type}</TableCell>
                                <TableCell align="center">{feature?.detail?.value_type}</TableCell>
                                <TableCell align="center">{feature.limit}</TableCell>
                                <TableCell align="center">{feature.remain}</TableCell>
                                {canEdit && (
                                    <TableCell align="center">
                                        <Tooltip title="Edit" arrow>
                                            <IconButton
                                                aria-label="edit"
                                                className={classes.editIcon}
                                                onClick={handleClickEdit(feature)}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete" arrow>
                                            <IconButton
                                                disabled={!feature.is_manual}
                                                aria-label="delete"
                                                className={classes.delIcon}
                                                onClick={handleClickDelete(feature.id)}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <EditFeature
                open={openEditFeature}
                close={handleCloseEditFeature}
                mode={modeFeature}
                featuresCode={listFeatureCode}
                dataEditFeature={dataEditFeature}
                handleLoadOrderFeature={handleLoadOrderFeature}
            />
        </div >
    );
};

FeatureLimitTable.propTypes = {
    className: PropTypes.string,
    orders: PropTypes.array.isRequired,
    total: PropTypes.number
};
FeatureLimitTable.defaultProps = {
    className: '',
    total: 1
};

export default FeatureLimitTable;
