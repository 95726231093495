import React from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';

const emptyValue = [null, undefined];

const Autocomplete = ({
   options,
   getOptionLabel,
   getOptionSelected = (option, selected) => option.id === selected.value || option.id === value,
   value,
   ...restProps
}) => {
   const getOptionValue = () => {
      if (emptyValue.includes(value)) {
         return null;
      }
      if (Array.isArray(value)) {
         // ถ้ามีข้อมูลไม่ครบ อาจจะเป็นเพราะ กำลัง fetch อยู่ return null ไปก่อน
         if (!value.every((val) => options.some((opt) => getOptionSelected(opt, val)))) {
            return null;
         }
         return value;
      }
      const valueIndex = options?.findIndex((opt) => getOptionSelected(opt, { value }));
      // ถ้ายังไม่มีข้อมูล อาจจะเป็นเพราะ กำลัง fetch อยู่ return null ไปก่อน
      if (valueIndex !== -1) {
         return { value };
      }
      return null;
   };

   return (
      <MuiAutocomplete
         {...restProps}
         options={options}
         getOptionLabel={(option) => {
            let label = undefined;
            if (option) {
               label = getOptionLabel(option);
            }
            if (label === undefined) {
               const selectedObj = options?.find((opt) => getOptionSelected(opt, option));
               if (selectedObj) {
                  label = getOptionLabel(selectedObj);
               }
            }
            return label || '';
         }}
         getOptionSelected={getOptionSelected}
         value={getOptionValue()}
      />
   );
};

export default Autocomplete;
