import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
   Box,
   Card,
   CardHeader,
   CardContent,
   Divider,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   makeStyles,
   Typography
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SvgIcon from '@material-ui/core/SvgIcon';
import moment from 'moment';
import LoadingBackdrop from 'src/components/loading/LoadingBackdrop';
import { BarChart as BarChartIcon } from 'react-feather';
import { isEmpty } from 'lodash';
import BarChart from './BarChart';

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: 16,
      position: 'relative'
   },
   chart: {
      height: '100%'
   },
   timing: {
      width: 200
   },
   btnGroupWrapper: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      marginTop: 4,
      marginRight: 10,

   },
   showCountYear: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: ' #ebf5fb  ',
      marginTop: 4,
      marginRight: 15,
      [theme.breakpoints.down(720)]: {
         marginRight: 0
      }
   },
   showCountPreviousYear: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '  #e8f8f5   ',
      marginTop: 4,
      marginRight: 10,
      [theme.breakpoints.down(720)]: {
         marginRight: 0
      }
   },
   showCountStaff: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '  #FFF8DC   ',
      marginTop: 4,
      marginRight: 10,
      [theme.breakpoints.down(720)]: {
         marginRight: 0
      }
   },
   btnGroup: {},
   grouped: {
      margin: theme.spacing(0.5),
      border: 'none',
      '&:not(:first-child)': {
         borderRadius: theme.shape.borderRadius
      },
      '&:first-child': {
         borderRadius: theme.shape.borderRadius
      }
   },
   tglBtn: {
      paddingBottom: 8,
      paddingTop: 8
   },
   headerRoot: {
      [theme.breakpoints.down(720)]: {
         alignItems: 'flex-start'
      },
      [theme.breakpoints.down(536)]: {
         flexDirection: 'column',
         alignItems: 'center'
      }
   },
   headerTiltle: {
      [theme.breakpoints.down(720)]: {
         marginTop: 8
      },
      [theme.breakpoints.down(536)]: {
         marginTop: 0,
         marginBottom: 8
      }
   },
   headerAction: {
      [theme.breakpoints.down(536)]: {
         alignSelf: 'center'
      }
   },
   headerActionContainer: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(720)]: {
         flexDirection: 'column-reverse',
         alignItems: 'flex-end'
      },
      [theme.breakpoints.down(536)]: {
         alignItems: 'center'
      }
   }
}));

const HeaderActions = ({
   handleChangeTiming,
   period,
   countAllPackageFree,
   countAllPackage,
   countAllPackageStaff,
}) => {
   const classes = useStyles();
   return (
      <Box className={classes.headerActionContainer}>
         <Box>
            <Box className={classes.showCountYear}>
               <Typography variant="subtitle2">
                  จำนวนการตอบแบบสอบถามเสียเงิน: {isEmpty(countAllPackageFree) ? '0' : countAllPackageFree[0]}
               </Typography>
            </Box>
            <Box className={classes.showCountPreviousYear}>
               <Typography variant="subtitle2">
                  จำนวนการตอบแบบสอบถามฟรี: {isEmpty(countAllPackage) ? '0' : countAllPackage[0]}
               </Typography>
            </Box>
            <Box className={classes.showCountStaff}>
               <Typography variant="subtitle2">
                  จำนวนการตอบแบบสอบถาม staff: {isEmpty(countAllPackageStaff) ? '0' : countAllPackageStaff[0]}
               </Typography>
            </Box>

         </Box>
         <FormControl fullWidth variant="outlined" margin="dense" size="small" className={classes.timing}>
            <InputLabel id="demo-simple-select-label">เลือกรูปแบบการแสดงผล</InputLabel>
            <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={period}
               onChange={handleChangeTiming}
               label="เลือกรูปแบบการแสดงผล"
            >
               {/* <MenuItem value={period}>{periodValue[period]}</MenuItem> */}
               <MenuItem value={'in_day'}>รายวัน</MenuItem>
               <MenuItem value={'in_month'}>รายเดือน</MenuItem>
               <MenuItem value={'in_year'}>รายปี</MenuItem>
            </Select>
         </FormControl>
      </Box>
   );
};

function groupBy(elements, duration, token, resultdate) {
   if (elements.length === 0) {
      return [];
   }
   if (isEmpty(resultdate)) {
      var elements = resultdate?.all_date?.map((date) => date);
   }
   const formatted = elements.map((elem) => {
      return {
         date: moment(elem || elem).startOf(duration).format('YYYY/MM/DD'),
         label: moment(elem || elem).startOf(duration).format(token)
      };
   });
   const dates = formatted.map((elem) => elem.date).sort();

   const startDate = resultdate?.start_date
      ? moment(resultdate?.start_date).startOf(duration)
      : moment(dates[0], 'YYYY/MM/DD').startOf(duration);
   const endDate = resultdate?.end_date ? moment(resultdate?.end_date).startOf(duration) : moment().startOf(duration);

   const datePeriods = [];
   while (endDate.diff(startDate, `${duration}s`) >= 0) {
      datePeriods.push(startDate.format(token));
      startDate.add(1, `${duration}s`);
   }
   const countAll = formatted.length;
   return datePeriods.map((date) => {
      const count = formatted.filter((elem) => elem.label === date).length;
      return { label: date, count: count, countAll: countAll };
   });
}

function getLabel(pack, free, staff) {
   if (pack.length >= free.length && pack.length >= staff.length) {
      return pack;
   } else if (free.length >= pack.length && free.length >= staff.length) {
      return free;
   } else if (staff.length >= pack.length && staff.length >= free.length) {
      return staff;
   }
}

function CustomersChart({
   params,
   responsePackage,
   responsePackageFree,
   responsePackageStaff,
   resultdate,
   displayPeriod,
   setDisplayPeriod,
   isLoading,
   className,
   ...rest
}) {
   const classes = useStyles();
   const [chartType, setChartType] = useState('bar');
   const handleChangeTiming = (e) => {
      setDisplayPeriod(e.target.value);
   };

   const handleChartType = (event, newType) => {
      if (newType !== null && newType !== chartType) {
         setChartType(newType);
      }
   };

   const inDay = useMemo(() => groupBy(responsePackage, 'day', 'DD/MM/YYYY', resultdate), [responsePackage, resultdate]);
   const inMonth = useMemo(() => groupBy(responsePackage, 'month', 'MMM/YYYY', resultdate), [responsePackage, resultdate]);
   const inYear = useMemo(() => groupBy(responsePackage, 'year', 'YYYY', resultdate), [responsePackage, resultdate]);

   const inDayResponsePackageFree = useMemo(
      () => groupBy(responsePackageFree, 'day', 'DD/MM/YYYY', resultdate),
      [responsePackageFree, resultdate]
   );
   const inMonthResponsePackageFree = useMemo(
      () => groupBy(responsePackageFree, 'month', 'MMM/YYYY', resultdate),
      [responsePackageFree, resultdate]
   );
   const inYearResponsePackageFree = useMemo(
      () => groupBy(responsePackageFree, 'year', 'YYYY', resultdate),
      [responsePackageFree, resultdate]
   );

   const inDayResponsePackageStaff = useMemo(
      () => groupBy(responsePackageStaff, 'day', 'DD/MM/YYYY', resultdate),
      [responsePackageStaff, resultdate]
   );
   const inMonthResponsePackageStaff = useMemo(
      () => groupBy(responsePackageStaff, 'month', 'MMM/YYYY', resultdate),
      [responsePackageStaff, resultdate]
   );
   const inYearResponsePackageStaff = useMemo(
      () => groupBy(responsePackageStaff, 'year', 'YYYY', resultdate),
      [responsePackageStaff, resultdate]
   );

   const responsePackageChartData = {
      in_day: {
         data:
            inDay.length >= inDayResponsePackageFree.length && inDay.length >= inDayResponsePackageStaff.length
               ? inDay.map((d) => d.count)
               : getLabel(inDay, inDayResponsePackageFree, inDayResponsePackageStaff)
                  .map((item) => {
                     const existingItem = inDay.find((d) => d.label === item.label);
                     if (existingItem) {
                        return existingItem.count;
                     } else {
                        return 0;
                     }
                  })
                  .concat(inDay.slice(getLabel(inDay, inDayResponsePackageFree, inDayResponsePackageStaff).length)),
         labels:
            getLabel(inDay, inDayResponsePackageFree, inDayResponsePackageStaff).map((d) => d.label)
      },
      in_month: {
         data:
            inMonth.length >= inMonthResponsePackageFree.length && inMonth.length >= inMonthResponsePackageStaff.length
               ? inMonth.map((d) => d.count)
               : getLabel(inMonth, inMonthResponsePackageFree, inMonthResponsePackageStaff)
                  .map((item) => {
                     const existingItem = inMonth.find((d) => d.label === item.label);
                     if (existingItem) {
                        return existingItem.count;
                     } else {
                        return 0;
                     }
                  })
                  .concat(inMonth.slice(getLabel(inMonth, inMonthResponsePackageFree, inMonthResponsePackageStaff).length)),
         labels:
            getLabel(inMonth, inMonthResponsePackageFree, inMonthResponsePackageStaff).map((d) => d.label)
      },
      in_year: {
         data:
            inYear.length >= inYearResponsePackageFree.length && inYear.length >= inYearResponsePackageStaff.length
               ? inYear.map((d) => d.count)
               : getLabel(inYear, inYearResponsePackageFree, inYearResponsePackageStaff)
                  .map((item) => {
                     const existingItem = inYear.find((d) => d.label === item.label);
                     if (existingItem) {
                        return existingItem.count;
                     } else {
                        return 0;
                     }
                  })
                  .concat(inYear.slice(getLabel(inYear, inYearResponsePackageFree, inYearResponsePackageStaff).length)),
         labels: getLabel(inYear, inYearResponsePackageFree, inYearResponsePackageStaff).map((d) => d.label)
      },
      countAll: inYear.map((d) => d.countAll)
   };

   const responsePackageFreeChartData = {
      in_day: {
         data:
            inDayResponsePackageFree.length >= inDay.length && inDayResponsePackageFree.length >= inDayResponsePackageStaff.length
               ? inDayResponsePackageFree.map((d) => d.count)
               : getLabel(inDay, inDayResponsePackageFree, inDayResponsePackageStaff)
                  .map((item) => {
                     const existingItem = inDayResponsePackageFree.find((d) => d.label === item.label);
                     if (existingItem) {
                        return existingItem.count;
                     } else {
                        return 0;
                     }
                  })
                  .concat(inDayResponsePackageFree.slice(getLabel(inDay, inDayResponsePackageFree, inDayResponsePackageStaff).length)),
         labels: getLabel(inDay, inDayResponsePackageFree, inDayResponsePackageStaff).map((d) => d.label)
      },
      in_month: {
         data:
            inMonthResponsePackageFree.length >= inMonth.length && inMonthResponsePackageFree.length >= inMonthResponsePackageStaff.length
               ? inMonthResponsePackageFree.map((d) => d.count)
               : getLabel(inMonth, inMonthResponsePackageFree, inMonthResponsePackageStaff)
                  .map((item) => {
                     const existingItem = inMonthResponsePackageFree.find((d) => d.label === item.label);
                     if (existingItem) {
                        return existingItem.count;
                     } else {
                        return 0;
                     }
                  })
                  .concat(inMonthResponsePackageFree.slice(getLabel(inMonth, inMonthResponsePackageFree, inMonthResponsePackageStaff).length)),
         labels: getLabel(inMonth, inMonthResponsePackageFree, inMonthResponsePackageStaff).map((d) => d.label)
      },
      in_year: {
         data:
            inYearResponsePackageFree.length >= inYear.length && inYearResponsePackageFree.length >= inYearResponsePackageStaff.length 
               ? inYearResponsePackageFree.map((d) => d.count)
               : getLabel(inYear, inYearResponsePackageFree, inYearResponsePackageStaff)
                  .map((item) => {
                     const existingItem = inYearResponsePackageFree.find((d) => d.label === item.label);
                     if (existingItem) {
                        return existingItem.count;
                     } else {
                        return 0;
                     }
                  })
                  .concat(inYearResponsePackageFree.slice(getLabel(inYear, inYearResponsePackageFree, inYearResponsePackageStaff).length)),
         labels: getLabel(inYear, inYearResponsePackageFree, inYearResponsePackageStaff).map((d) => d.label)
      },
      countAll: inYearResponsePackageFree.map((d) => d.countAll)
   };

   const responsePackageStaffChartData = {
      in_day: {
         data:
            inDayResponsePackageStaff.length >= inDay.length && inDayResponsePackageStaff.length >= inDayResponsePackageFree.length
               ? inDayResponsePackageStaff.map((d) => d.count)
               : getLabel(inDay, inDayResponsePackageFree, inDayResponsePackageStaff)
                  .map((item) => {
                     const existingItem = inDayResponsePackageStaff.find((d) => d.label === item.label);
                     if (existingItem) {
                        return existingItem.count;
                     } else {
                        return 0;
                     }
                  })
                  .concat(inDayResponsePackageStaff.slice(getLabel(inDay, inDayResponsePackageFree, inDayResponsePackageStaff).length)),
         labels: getLabel(inDay, inDayResponsePackageFree, inDayResponsePackageStaff).map((d) => d.label)
      },
      in_month: {
         data:
            inMonthResponsePackageStaff.length >= inMonth.length && inMonthResponsePackageStaff.length >= inMonthResponsePackageFree.length
               ? inMonthResponsePackageStaff.map((d) => d.count)
               : getLabel(inMonth, inMonthResponsePackageFree, inMonthResponsePackageStaff)
                  .map((item) => {
                     const existingItem = inMonthResponsePackageStaff.find((d) => d.label === item.label);
                     if (existingItem) {
                        return existingItem.count;
                     } else {
                        return 0;
                     }
                  })
                  .concat(inMonthResponsePackageStaff.slice(getLabel(inMonth, inMonthResponsePackageFree, inMonthResponsePackageStaff).length)),
         labels: getLabel(inMonth, inMonthResponsePackageFree, inMonthResponsePackageStaff).map((d) => d.label)
      },
      in_year: {
         data:
            inYearResponsePackageStaff.length >= inYear.length && inYearResponsePackageStaff.length >= inYearResponsePackageFree.length
               ? inYearResponsePackageStaff.map((d) => d.count)
               : getLabel(inYear, inYearResponsePackageFree, inYearResponsePackageStaff)
                  .map((item) => {
                     const existingItem = inYearResponsePackageStaff.find((d) => d.label === item.label);
                     if (existingItem) {
                        return existingItem.count;
                     } else {
                        return 0;
                     }
                  })
                  .concat(inYearResponsePackageStaff.slice(getLabel(inYear, inYearResponsePackageFree, inYearResponsePackageStaff).length)),
         labels: getLabel(inYear, inYearResponsePackageFree, inYearResponsePackageStaff).map((d) => d.label)
      },
      countAll: inYearResponsePackageStaff.map((d) => d.countAll)
   };


   return (
      <Card className={clsx(classes.root, className)} {...rest}>
         {isLoading && <LoadingBackdrop loading={isLoading} />}
         <CardHeader
            classes={{ root: classes.headerRoot, title: classes.headerTiltle, action: classes.headerAction }}
            action={
               <HeaderActions
                  handleChangeTiming={handleChangeTiming}
                  period={displayPeriod}
                  countAllPackageFree={responsePackageChartData.countAll}
                  countAllPackage={responsePackageFreeChartData.countAll}
                  countAllPackageStaff={responsePackageStaffChartData.countAll}
               />
            }
            title="กราฟแสดงผลข้อมูลจำนวนการตอบแบบสอบถาม"
         />
         <Divider />
         <CardContent>
            <PerfectScrollbar>
               <Box height={375} minWidth={500}>
                  <BarChart
                     className={classes.chart}
                     responsePackageChartData={responsePackageChartData[displayPeriod].data}
                     responsePackageFreeChartData={responsePackageFreeChartData[displayPeriod].data}
                     responsePackageStaffChartData={responsePackageStaffChartData[displayPeriod].data}
                     labels={
                        responsePackageFreeChartData[displayPeriod].labels.length >=
                           responsePackageChartData[displayPeriod].labels.length
                           ? responsePackageFreeChartData[displayPeriod].labels
                           : responsePackageChartData[displayPeriod].labels.length >= responsePackageStaffChartData[displayPeriod].labels.length ?
                              responsePackageChartData[displayPeriod].labels : responsePackageStaffChartData[displayPeriod].labels
                     }
                     displayPeriod={displayPeriod}
                     params={params}
                  />
               </Box>
            </PerfectScrollbar>
         </CardContent>
      </Card>
   );
}

CustomersChart.propTypes = {
   className: PropTypes.string
};

export default CustomersChart;
