import { axiosToken } from 'src/common/AxiosToken';

export const getOrderById =
   (id, isWithLoading = true) =>
      async (dispatch, getState) => {
         isWithLoading && dispatch(beginGetOrder());
         try {
            const res = await axiosToken.get(`order2/${id}`);
            const customerId = res.data.order.user_id;
            await dispatch(getCustomerOrderInfo(customerId, false));
            dispatch(successGetOrder(res.data));
         } catch (error) {
            dispatch(failureGetOrder(error));
         }
      };

export const getCustomerOrderInfo =
   (cusid, isWithLoading = true) =>
      async (dispatch, getState) => {
         try {
            isWithLoading && dispatch(beginGetCustomerInfo());
            const urlGetCustomer = `/customer/${cusid}/order2-detail`;
            const {
               data: { customer, ...rest }
            } = await axiosToken.get(urlGetCustomer);
            dispatch(successCustomerInfo(customer, rest));
         } catch (error) {
            dispatch(failureGetCustomerInfo(error));
         }
      };

export const DISPLAY_LOADING = 'DISPLAY_LOADING';
export const BEGIN_GET_ORDER = 'BEGIN_GET_ORDER';
export const SUCCESS_GET_ORDER = 'SUCCESS_GET_ORDER';
export const FAILURE_GET_ORDER = 'FAILURE_GET_ORDER';
export const BEGIN_GET_CUSTOMER_INFO = 'BEGIN_GET_CUSTOMER_INFO';
export const SUCCESS_GET_CUSTOMER_INFO = 'SUCCESS_GET_CUSTOMER_INFO';
export const FAILURE_GET_CUSTOMER_INFO = 'FAILURE_GET_CUSTOMER_INFO';
export const CLEAN_UP_ORDER = 'CLEAN_UP_ORDER';

export const displayLoading = (bool = true) => ({
   type: DISPLAY_LOADING,
   payload: { isLoading: bool }
});
export const beginGetOrder = () => ({
   type: BEGIN_GET_ORDER
});
export const successGetOrder = (data) => ({
   type: SUCCESS_GET_ORDER,
   payload: {
      order: data.order,
      defaultAdditionalTaxRate: data.default_additional_tax_rate,
      packages: data.packages,
      pdfHost: data.pdf_host,
      unuseDays: data.unuse_days,
      usageStorage: data.usage_storage ?? 0,
      teamRole: data.team_role
   }
});
export const failureGetOrder = (error) => ({
   type: FAILURE_GET_ORDER,
   payload: { error: error }
});
export const beginGetCustomerInfo = () => ({
   type: BEGIN_GET_CUSTOMER_INFO
});
export const successCustomerInfo = (customerData, otherData) => ({
   type: SUCCESS_GET_CUSTOMER_INFO,
   payload: {
      customer: customerData,
      canCreate: otherData.can_create,
      pdfHost: otherData.pdf_host
   }
});
export const failureGetCustomerInfo = (error) => ({
   type: FAILURE_GET_CUSTOMER_INFO,
   payload: { error: error }
});
export const cleanOrder = () => ({
   type: CLEAN_UP_ORDER
});
