/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FilesDropzone from 'src/components/FilesDropzone';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles, alpha, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Upload as UploadIcon } from 'react-feather';
import AxiosToken from 'src/common/AxiosToken';
import { useDialog } from 'src/hooks/dialog';
import { displayLoading, getOrderById } from 'src/redux/actions/customerOrderAction';
import { useParams } from 'react-router';
import { usePermissions } from 'src/hooks/usePermissions';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
   previewImg: {
      borderRadius: 8,
      border: 'solid 2px #ddd'
   },
   dContent: {},
   mediaImg: {
      objectFit: 'unset',
      height: 'auto'
   },
   uploadBtn: {
      backgroundColor: '#f8f8f8',
      width: 200,
      fontWeight: 600,
      textTransform: 'unset'
   }
}));
const DialogUploadSlip = ({ open, closeDialog, orderId, currentPaymentVerification }) => {
   const classes = useStyles();
   const dispatch = useDispatch();
   const param = useParams();
   const dialog = useDialog();

   const handleUploadFiles = async (files, clearFiles, setUploadLoading) => {
      if (currentPaymentVerification?.approved_at) {
         return;
      }
      const formData = new FormData();
      files.forEach((file) => {
         formData.append('assets[]', file, file.name);
      });
      dispatch(displayLoading(true));
      let res;
      setUploadLoading(true);
      try {
         if (currentPaymentVerification !== null && !currentPaymentVerification.canceled_at) {
            // for update currrent paymentVerification
            formData.append('payment_verification_id', currentPaymentVerification.id);
            res = await AxiosToken.post(`order2/${orderId}/upload-more-slips`, formData);
         } else {
            // for create new paymentVerification
            res = await AxiosToken.post(`order2/${orderId}/upload-slips`, formData);
            console.log('formData1', formData);
         }
         console.log('res', res);
         // should be show loading..
         clearFiles();
         setUploadLoading(false);
         closeDialog();
         dispatch(getOrderById(param.orderid));
      } catch (error) {
         console.log('ERROR.STATUS', error?.status);
         console.log('ERROR.RESPONSE', error?.response);
         console.log('ERROR.RESPONSE.STATUS', error?.response?.status);
         console.log('error', error);
         dialog.error(error);
         setUploadLoading(false);
         dispatch(displayLoading(false));
      }
   };
   return (
      <Dialog
         open={open}
         // TransitionComponent={Transition}
         keepMounted
         onClose={closeDialog}
         maxWidth="sm"
         fullWidth
         aria-labelledby="alert-dialog-slide-title"
         aria-describedby="alert-dialog-slide-description"
      >
         <DialogTitle id="alert-dialog-slide-title">Show Slip</DialogTitle>
         <DialogContent classes={{ root: classes.dContent }}>
            <FilesDropzone onUploadFiles={handleUploadFiles} />
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" onClick={closeDialog} color="primary">
               close
            </Button>
         </DialogActions>
      </Dialog>
   );
};

const DialogShowImage = ({ open, closeDialog, source }) => {
   const classes = useStyles();
   return (
      <Dialog
         open={open}
         // TransitionComponent={Transition}
         keepMounted
         onClose={closeDialog}
         maxWidth="sm"
         fullWidth
         aria-labelledby="alert-dialog-slide-title"
         aria-describedby="alert-dialog-slide-description"
      >
         <DialogTitle id="alert-dialog-slide-title">Upload Files</DialogTitle>
         <DialogContent classes={{ root: classes.dContent }}>
            <Card>
               <CardMedia
                  classes={{ img: classes.mediaImg }}
                  component="img"
                  alt="Contemplative Reptile"
                  height="160"
                  image={source}
                  title="Contemplative Reptile"
               />
            </Card>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" onClick={closeDialog} color="primary">
               close
            </Button>
         </DialogActions>
      </Dialog>
   );
};

const DialogReceiptDate = ({ open, closeDialog, handleClickApproval, receiptDate, setReceiptDate, isLoading }) => {
   const classes = useStyles();
   return (
      <Dialog
         open={open}
         // TransitionComponent={Transition}
         keepMounted
         onClose={closeDialog}
         maxWidth="sm"
         fullWidth
         aria-labelledby="alert-dialog-slide-title"
         aria-describedby="alert-dialog-slide-description"
      >
         <DialogTitle id="alert-dialog-slide-title">Receipt Date</DialogTitle>
         <DialogContent classes={{ root: classes.dContent }}>
            <Card>
               <KeyboardDatePicker
                  inputVariant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  label="วันที่ชำระ"
                  name="receipt_date"
                  value={receiptDate}
                  format="DD/MM/YYYY"
                  minDate={moment(new Date()).subtract(10, 'year').toISOString()}
                  maxDate={moment(new Date()).add(10, 'year').toISOString()}
                  onChange={(newValue) => setReceiptDate(newValue)}
                  views={['date']}
               />
            </Card>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" onClick={closeDialog} color="primary">
               close
            </Button>
            <Button disabled={isLoading} variant="contained" onClick={() => handleClickApproval('approve')} color="primary">
               save
            </Button>
         </DialogActions>
      </Dialog>
   );
};

const OrderUpload = ({ order, disabled, canEdit, canVerify, handleLoadOrderFeature }) => {
   const { payment_verification } = order;
   const classes = useStyles();
   const dispatch = useDispatch();
   const param = useParams();
   const dialog = useDialog();
   const theme = useTheme();
   const { cannot } = usePermissions();
   const [receiptDate, setReceiptDate] = useState(new Date());
   const [showImage, setShowImage] = useState({ open: false, source: '' });
   const [openUpload, setOpenUpload] = useState(false);
   const [openDialogReceiptDate, setOpenDialogReceiptDate] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const handleClickUploadSlip = () => {
      setOpenUpload(true);
   };
   console.log('openDialogReceiptDate', openDialogReceiptDate)

   const handleRemoveFile = async (fileId) => {
      // should be show loading..
      dispatch(displayLoading(true));
      try {
         const res = await AxiosToken.delete(`upload-slip/${fileId}`);
         // const res = await AxiosToken.delete(`payment-verification-file/${fileId}/remove`)
         console.log('res', res);
         // const shouldLoading = false;
         dispatch(getOrderById(param.orderid));
      } catch (error) {
         dialog.error(error);
         dispatch(displayLoading(false));
      }
   };

   const handleClickApproval = async (approval) => {
      setIsLoading(true)
      const data = {
         payment_verification_id: payment_verification[0].id,
         receipt_date: receiptDate,
         approval
      };
      dispatch(displayLoading(true));
      try {
         const res = await AxiosToken.post(`order2/${order.id}/approval-slip`, data);
         console.log('res', res);
         // const shouldLoading = false;
         dispatch(getOrderById(param.orderid));
         handleLoadOrderFeature()
         setOpenDialogReceiptDate(false)
         setIsLoading(false)
      } catch (error) {
         setIsLoading(false)
         dispatch(displayLoading(false));
         dialog.error(error);
      }
   };

   const getUploadedInfo = (pVrf, type) => {
      const approved = {
         status: 'Approved',
         color: 'secondary',
         date: moment(pVrf.approved_at).format('DD/MM/YYYY HH:mm')
      };
      const canceled = {
         status: 'Rejected',
         color: 'error',
         date: moment(pVrf.canceled_at).format('DD/MM/YYYY HH:mm')
      };
      const pending = {
         status: 'pending',
         color: 'primary',
         date: ''
      };
      return pVrf.approved_at ? approved[type] : pVrf.canceled_at ? canceled[type] : pending[type];
   };

   if (!order.package) {
      return (
         <div>
            <Box display="flex" minHeight="190px" alignItems="center" justifyContent="center">
               <Typography width="auto" component={Box} textAlign="center">
                  <b>Package not found ..!!</b>
                  <p>Make sure package in quotation tab is selected or this order is not canceled</p>
               </Typography>
            </Box>
         </div>
      );
   }

   if (cannot(['VIEW', 'OPERATE'], 'ORDER_RECEIPT')) {
      return (
         <Box display="flex" alignItems="center" justifyContent="center" height="120px">
            <Typography>You are not permission.</Typography>
         </Box>
      );
   }
   const rootBoxProps =
      payment_verification.length === 0
         ? {
            display: 'flex',
            minHeight: '190px',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
         }
         : {
            display: 'block'
         };

   const btnText = () => {
      if (payment_verification[0] && !payment_verification[0].approved_at) {
         if (payment_verification[0].canceled_at) {
            return 'Upload new Slips';
         }
         return 'Upload more Slips';
      }
      return 'Upload Slips';
   };

   return (
      <Box {...rootBoxProps}>
         {(payment_verification.length === 0 || (payment_verification[0] && !payment_verification[0].approved_at)) &&
            !disabled &&
            canEdit && (
               <Box textAlign="center">
                  <Button
                     className={classes.uploadBtn}
                     color="primary"
                     variant="text"
                     startIcon={<UploadIcon size={16} />}
                     onClick={handleClickUploadSlip}
                     disabled={
                        // ((order?.status !== 'receive' && order?.status !== 'invoice') && btnText() === 'Upload Slips') ||
                        // (order?.status !== 'receive' && btnText() === 'Upload new Slips') ||
                        // (order?.status !== 'receive' && btnText() === 'Upload more Slips')
                        (!['invoice', 'credit', 'receive'].includes(order?.status))
                     }
                  >
                     {btnText()}
                  </Button>
               </Box>
            )}
         {payment_verification.length === 0 && (
            <Box textAlign="center" my={2}>
               <Typography>
                  <b> This order has no any payment verification documents...! </b>
               </Typography>
            </Box>
         )}
         {payment_verification.map((pVerification) => (
            <div key={pVerification.id}>
               <Box my={3} border="1px solid #ddd" borderRadius={8}>
                  <Box mx={2} display="flex" alignItems="center">
                     <Typography component={Box} flexGrow={1} py={2} color="textPrimary" variant="body1">
                        <b>Uploaded Slips</b>
                     </Typography>
                     <Typography
                        component={Box}
                        px={2}
                        bgcolor={alpha(theme.palette[getUploadedInfo(pVerification, 'color')].main, 0.08)}
                        color={getUploadedInfo(pVerification, 'color')}
                        variant="body1"
                     >
                        <b>Status : {getUploadedInfo(pVerification, 'status')}</b>
                        <pre>{getUploadedInfo(pVerification, 'date')}</pre>
                     </Typography>
                  </Box>
                  <Divider />
                  <Box>
                     <PerfectScrollbar style={{ maxHeight: '300px' }} options={{ suppressScrollX: true }}>
                        <List className={classes.list}>
                           {pVerification.files?.map((file, i, uploadedFiles) => (
                              <ListItem
                                 onClick={() => setShowImage({ open: true, source: file.file_source })}
                                 button
                                 divider={i < uploadedFiles.length - 1}
                                 key={file.id}
                              >
                                 <ListItemAvatar>
                                    <Avatar
                                       classes={{ root: classes.previewImg }}
                                       alt={'file-name'}
                                       src={file.file_source}
                                    />
                                 </ListItemAvatar>
                                 <ListItemText
                                    primary={file.original_file_name}
                                    primaryTypographyProps={{ variant: 'h5' }}
                                 // secondary={bytesToSize(20000018782)}
                                 />
                                 {!disabled && canEdit && !pVerification.approved_at && !pVerification.canceled_at && (
                                    <ListItemSecondaryAction>
                                       <Tooltip title="Remove">
                                          <IconButton
                                             edge="end"
                                             onClick={() =>
                                                dialog
                                                   .confirm('Are you sure to Delete this file ?')
                                                   .then((res) => res && handleRemoveFile(file.id))
                                             }
                                          >
                                             <DeleteIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </ListItemSecondaryAction>
                                 )}
                              </ListItem>
                           ))}
                        </List>
                     </PerfectScrollbar>
                  </Box>
               </Box>
               {!disabled && canVerify && !pVerification.approved_at && !pVerification.canceled_at && (
                  <Box display="flex" alignItems="center" justifyContent="center">
                     <Button
                        // onClick={() =>
                        //    dialog
                        //       .confirm('Are you sure to approve this payment info')
                        //       .then((res) => res && handleClickApproval('approve'))
                        // }
                        onClick={() => setOpenDialogReceiptDate(true)}
                        variant="contained"
                        color="secondary"
                        size="large"
                        // disabled={order.status !== 'receive'}
                        disabled={(!['invoice', 'credit', 'receive'].includes(order?.status))}
                     >
                        Approve
                     </Button>
                     <Box width={16} />
                     <Button
                        onClick={() =>
                           dialog
                              .confirm('Are you sure to reject this payment info')
                              .then((res) => res && handleClickApproval('reject'))
                        }
                        disabled={isLoading}
                        variant="contained"
                        color="secondary"
                        size="large"
                     >
                        Reject
                     </Button>
                  </Box>
               )}
            </div>
         ))}

         <DialogShowImage
            open={showImage.open}
            source={showImage.source}
            closeDialog={() => setShowImage({ open: false, source: '' })}
         />
         <DialogUploadSlip
            open={openUpload}
            closeDialog={() => setOpenUpload(false)}
            orderId={order.id}
            currentPaymentVerification={payment_verification[0] || null}
         />
         <DialogReceiptDate
            open={openDialogReceiptDate}
            closeDialog={() => setOpenDialogReceiptDate(false)}
            receiptDate={receiptDate}
            setReceiptDate={setReceiptDate}
            handleClickApproval={handleClickApproval}
            isLoading={isLoading}
         />
      </Box>
   );
};

export default OrderUpload;
