import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import App from './App';

const render = () => {
   ReactDOM.render(
      <BrowserRouter>
         <App />
      </BrowserRouter>,
      document.getElementById('root')
   );
};

render();

if (module.hot) {
   module.hot.accept('./App', () => {
      render();
   });
}

serviceWorker.unregister();
