import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
// import { useAuth } from "src/hooks/useAuth";

export function PublicRoute({ component: Component }) {
   const location = useLocation();
   let isAuth = localStorage.getItem('access-token');
   return Boolean(isAuth) ? <Navigate to="/app/account" state={{ from: location }} /> : <Component />;
}
PublicRoute.propTypes = {
   component: PropTypes.any.isRequired
};
