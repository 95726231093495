import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LoadingBackdrop from 'src/components/loading/LoadingBackdrop';
import { axiosToken } from 'src/common/AxiosToken';
import { useDialog } from 'src/hooks/dialog';

const useStyles = makeStyles((theme) => ({
    dContent: {
        position: 'relative'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditFeature({
    open,
    close,
    mode,
    dataEditFeature,
    featuresCode,
    handleLoadOrderFeature
}) {
    const classes = useStyles();
    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();

    const [selectedFeature, setSelectedFeature] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isRequireLimit, setIsRequireLimit] = useState(false);
    const [isRequiredRemain, setIsRequireRemain] = useState(false);
    const closeDialog = (isRefresh) => {
        setSelectedFeature({
            ...dataEditFeature,
            feature_code: { code: null, description: null },
            limit: null,
            remain: null
        });
        setIsError(false)
        setIsRequireLimit(false)
        setIsRequireRemain(false)
        close(isRefresh);
    };

    const onValueEventChange = (event) => {
        let { value, name } = event.target;
        setSelectedFeature((prevState) => ({
            ...prevState,
            [name]: parseInt(value)
        }));
    };

    const onSelectAutocompleteChange = (key) => (e, value, action) => {
        let data;
        if (action === 'select-option') {
            data = value;
        } else if (action === 'clear') {
            data = null;
        }
        setSelectedFeature((prevState) => ({
            ...prevState,
            [key]: data
        }));
    };

    const handleClickSave = async () => {
        if (selectedFeature.feature_code.value_type === 'int' && (selectedFeature.limit < selectedFeature.remain || !selectedFeature?.remain || !selectedFeature?.limit)) {
            setIsError(selectedFeature.limit < selectedFeature.remain)
            setIsRequireLimit(!selectedFeature?.limit)
            setIsRequireRemain(!selectedFeature?.remain)
        } else {
            setIsError(false)
            if (mode === 'edit') {
                setIsLoading(true);
                const data = {
                    id: selectedFeature.id,
                    feature_code: selectedFeature?.feature_code.code,
                    limit: selectedFeature.limit,
                    remain: selectedFeature.remain
                };
                try {
                    const res = await axiosToken.put(`order2/edit-feature`, data);
                    setIsLoading(false);
                    enqueueSnackbar(res.data?.message, {
                        variant: 'success',
                        autoHideDuration: 2000
                    });
                    handleLoadOrderFeature()
                } catch (error) {
                    setIsLoading(false);
                    console.log(error);
                    dialog.error(error);
                }
                closeDialog('refresh');
            } else {
                setIsLoading(true);
                const data = {
                    ...selectedFeature,
                    feature_code: selectedFeature.feature_code?.code,
                    limit: selectedFeature.limit,
                    remain: selectedFeature.remain,
                };
                try {
                    const res = await axiosToken.post(`order2/add-feature`, data);
                    setIsLoading(false);
                    enqueueSnackbar(res.data?.message, {
                        variant: 'success',
                        autoHideDuration: 2000
                    });
                    handleLoadOrderFeature()
                } catch (error) {
                    setIsLoading(false);
                    console.log(error);
                    dialog.error(error);
                }
                closeDialog('refresh');
            }
        }
    };

    const disabledSave = isLoading || selectedFeature === null;

    useEffect(() => {
        if (open && dataEditFeature && mode === 'edit') {
            setSelectedFeature({
                ...dataEditFeature,
                feature_code: { code: dataEditFeature.feature_code, description: dataEditFeature?.detail?.description, value_type: dataEditFeature?.detail?.value_type },
            });
        } else if (open && mode === 'add') {
            setSelectedFeature({
                ...dataEditFeature,
            });
        }
    }, [open, dataEditFeature]);

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeDialog}
                maxWidth="sm"
                fullWidth
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{'Update Feature'}</DialogTitle>
                <Divider />
                <DialogContent classes={{ root: classes.dContent }}>
                    {isLoading && <LoadingBackdrop loading={isLoading} />}
                    <Box mb={2}>
                        <Autocomplete
                            disabled={dataEditFeature?.feature_code}
                            value={selectedFeature?.feature_code || ''}
                            id="list-feature"
                            options={featuresCode.map((option) => ({
                                code: option.code,
                                description: option.description,
                                value_type: option.value_type
                            }))}
                            getOptionLabel={(option) => option.description}
                            getOptionSelected={(option, { value }) => option.code === value}
                            onChange={onSelectAutocompleteChange('feature_code')}
                            renderInput={(inputParams) => (
                                <TextField
                                    {...inputParams}
                                    size="small"
                                    margin="normal"
                                    label="Select Feature"
                                    variant="outlined"
                                />
                            )}
                            renderOption={(option) => `${option.description}`}
                        />
                    </Box>
                    {selectedFeature?.feature_code?.value_type === 'int' && (
                        <>
                            <Box mb={2}>
                                <TextField
                                    size="small"
                                    name='limit'
                                    type='number'
                                    value={selectedFeature?.limit || ''}
                                    onChange={onValueEventChange}
                                    fullWidth
                                    label="Limit"
                                    placeholder="Limit"
                                    variant="outlined"
                                    error={isRequireLimit}
                                    helperText={isRequireLimit && "Limit is required"}
                                ></TextField>
                            </Box>
                            <Box>
                                <TextField
                                    size="small"
                                    name='remain'
                                    type='number'
                                    value={selectedFeature?.remain || ''}
                                    onChange={onValueEventChange}
                                    fullWidth
                                    label="Remain"
                                    placeholder="Remain"
                                    variant="outlined"
                                    error={isRequiredRemain || isError}
                                    helperText={
                                        (isError && "Remain must be less than Limit") ||
                                        (isRequiredRemain && "Remain is required")
                                    }
                                ></TextField>
                            </Box>
                        </>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} variant="outlined" onClick={closeDialog} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={disabledSave} variant="contained" onClick={handleClickSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
