import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   errorImg: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto'
   },
   wrapperErrorImg: {
      height: '50vh',
      width: '50vh',
      margin: 'auto'
   },
   boxErrorImg: {
      height: 'calc(100vh - 116px)',
      display: 'flex'
   }
}));
const NoPermissionView = () => {
   const classes = useStyles();
   return (
      <div className={classes.boxErrorImg}>
         <div className={classes.wrapperErrorImg}>
            <img
               className={classes.errorImg}
               src={'/static/images/undraw_No_data_re_kwbl.svg'}
               alt={'something went wrong'}
            />
            <Typography align="center" color="textPrimary" variant="h1">
               You do not have permission to access
            </Typography>
         </div>
      </div>
   );
};

export default NoPermissionView;
