import { axiosToken } from 'src/common/AxiosToken';

export function getCustomerOrders(customerId, withLoading = true) {
   return async (dispatch) => {
      if (withLoading) {
         dispatch(beginFetchOrders());
      }
      try {
         let {
            data: { customer, default_additional_tax_rate, order, can_create, ...rest }
         } = await axiosToken.get(`customer/${customerId}/order2`);
         return dispatch(
            fetchOrderSuccess(customer, default_additional_tax_rate, order, can_create, rest.package, rest.pdf_host)
         );
         // return { data: "success", result: data }
      } catch (error) {
         dispatch(fetchOrderFailure(error));
         throw error;
      }
   };
}

export function createNewOrder(customerId, order_type, order_id = null) {
   return async (dispatch) => {
      dispatch(duringFetchOrder());
      const data = {
         order_type: order_type,
         order_id: order_id
      };
      try {
         const res = await axiosToken.post(`customer/${customerId}/order2`, data);
         // dispatch(fetchCreateOrderSuccess())
         const shouldLoading = false;
         await dispatch(getCustomerOrders(customerId, shouldLoading));
         return res.data.new_order_id;
      } catch (error) {
         dispatch(fetchOrderFailure(error));
      }
   };
}

export const FETCH_ORDER_BEGIN = 'FETCH_ORDER_BEGIN'; //fro first time only
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const DURING_FETCH_ORDER = 'DURING_FETCH_ORDER';
export const FETCH_CREATE_ORDER_SUCCESS = 'FETCH_CREATE_ORDER_SUCCESS';

export const DISPLAY_LOADING = 'DISPLAY_LOADINGS';

export const beginFetchOrders = () => ({
   type: FETCH_ORDER_BEGIN
});
export const fetchOrderSuccess = (customer, default_additional_tax_rate, orders, can_create, packages, pdf_host) => ({
   type: FETCH_ORDER_SUCCESS,
   payload: {
      customer,
      default_additional_tax_rate,
      orders,
      can_create,
      packages,
      pdf_host
   }
});

export const fetchOrderFailure = (error) => ({
   type: FETCH_ORDER_FAILURE,
   payload: { error }
});
export const duringFetchOrder = () => ({
   type: DURING_FETCH_ORDER
});
export const fetchCreateOrderSuccess = () => ({
   type: FETCH_CREATE_ORDER_SUCCESS
});

export const displayLoading = (isLoading) => ({
   type: DISPLAY_LOADING,
   payload: { isLoading }
});
