import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import NProgress from 'nprogress';
import { makeStyles } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import SquareSpinner from './SquareSpinner';

const useStyles = makeStyles((theme) => ({
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.primary.main,
      position: 'absolute',
      opacity: '0.5 !important',
      backgroundColor: '#fff'
   }
}));

const LoadingBackdrop = ({ className, loading, ...rest }) => {
   const classes = useStyles();
   useEffect(() => {
      NProgress.start();
      if (!loading) {
         NProgress.done();
      }
      return () => {
         NProgress.done();
      };
   }, [loading]);
   return (
      <Backdrop className={clsx(classes.backdrop, className)} open={loading} {...rest}>
         <SquareSpinner />
      </Backdrop>
   );
};

LoadingBackdrop.propTypes = {
   className: PropTypes.string,
   loading: PropTypes.bool
};
LoadingBackdrop.defaultProps = {
   loading: false,
   className: ''
};
export default LoadingBackdrop;
