import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(() => ({
   root: {},
   textEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '300px'
   }
}));

function Header({ className, beadcrumbs, title, ...rest }) {
   const classes = useStyles();
   const { pathname } = useLocation();

   return (
      <div className={clsx(classes.root, className)} {...rest}>
         <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {beadcrumbs.map((beadcrumb, i) =>
               beadcrumb.link ? (
                  <Link key={i} color="inherit" to={beadcrumb.link} component={RouterLink} className={classes.textEllipsis}>
                     {beadcrumb.text}
                  </Link>
               ) : (
                  <Typography key={i} color="textPrimary" className={classes.textEllipsis}>
                     {beadcrumb.text}
                  </Typography>
               )
            )}
         </Breadcrumbs>
         <Box my={2}>
            <Typography variant="h3" color="textPrimary" className={classes.textEllipsis} style={{ width: '100%' }}>
               {title || beadcrumbs.slice(-1)[0]?.text || pathname.split('/').slice(-1)[0] || ''}
            </Typography>
         </Box>
      </div>
   );
}

Header.propTypes = {
   className: PropTypes.string,
   beadcrumbs: PropTypes.array,
   title: PropTypes.node
};
Header.defaultProps = {
   beadcrumbs: []
};
export default Header;
