import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import axiosToken from 'src/common/AxiosToken';

const useStyles = makeStyles((theme) => ({
   dContent: {
      position: 'relative'
   }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogDate({ open, close, data, type }) {
   const classes = useStyles();
   const [date, setDate] = useState(new Date());
   const { enqueueSnackbar } = useSnackbar();

   const handleChangeDate = (date) => {
      setDate(date);
   };

   const saveEditDate = () => {
      axiosToken
         .put(`order2/${data.id}/edit-${type}-date`, { date: date })
         .then((response) => {
            console.log(response);
            enqueueSnackbar(`Edit ${type} date success`, {
               variant: 'success',
               autoHideDuration: 2000
            });
            setDate(new Date());
            close('save');
         })
         .catch((error) => {
            console.log('error', error);
         });
   };

   return (
      <div>
         <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={close} maxWidth="sm" fullWidth>
            <DialogTitle>Edit {type} date</DialogTitle>
            <Divider />
            <DialogContent classes={{ root: classes.dContent }}>
               <Box my={1} pl={1}>
                  {type === 'receipt' ? (
                     <Typography variant="h6">
                        Receipt Date Old Value : {moment(data?.receipt.receipt_date).format('DD/MM/YYYY')}
                     </Typography>
                  ) : (
                     <Typography variant="h6">
                        Invoice Date Old Value : {moment(data?.invoice.invoice_date).format('DD/MM/YYYY')}
                     </Typography>
                  )}
               </Box>
               <Box maxHeight="40vh" overflow="auto">
                  <KeyboardDatePicker
                     inputVariant="outlined"
                     fullWidth
                     size="small"
                     margin="dense"
                     label="วัน"
                     name="start_date"
                     value={date}
                     format="DD/MM/YYYY"
                     minDate={moment(new Date()).subtract(10, 'year').toISOString()}
                     maxDate={moment(new Date()).add(10, 'year').toISOString()}
                     onChange={(newValue) => handleChangeDate(newValue)}
                     views={['date']}
                  />
               </Box>
            </DialogContent>
            <DialogActions>
               <Button variant="outlined" onClick={close} color="primary">
                  Close
               </Button>
               <Button variant="contained" onClick={saveEditDate} color="primary">
                  save
               </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
