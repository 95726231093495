import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Widgets from '@material-ui/icons/Widgets';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
   poweredLink: {
      color: palette.primary.main,
      textDecoration: 'none'
   },
   whiteColor: {
      color: '#fff'
   }
}));

const NomalBrand = () => {
   const classes = useStyles();
   return (
      <Box alignItems={'center'} display="flex" justifyContent="center" p={1}>
         <Box color={'#007bff'} fontSize={64} lineHeight={0}>
            <Widgets fontSize={'medium'} className={classes.whiteColor} />
         </Box>
         <Box p={1}>
            <Typography variant={'h6'} className={classes.whiteColor}>
               ENABLE SURVEY
            </Typography>
            {/* <Typography variant={'caption'} color={'textSecondary'}>
                    powered by <a href="https://www.google.com" target="_blank" className={classes.poweredLink}>enablesurvey.net</a>
                </Typography> */}
         </Box>
      </Box>
   );
};

export default NomalBrand;
