import { merge } from 'lodash';
import {
   calculateDiscountedPrice,
   calculatePackageTotalPrice,
   calculateUnusePrice,
   netTotalPrice,
   parseNumber
} from 'src/utils/orderUtils';
import {
   BEGIN_QUOTATION,
   ADD_PACKAGE_DISCOUNT,
   CLEAN_UP_QUOTATION_SET,
   REMOVE_PACKAGE_DISCOUNT,
   SET_WITH_KEY,
   UPDATE_PACKAGE_DISCOUNT,
   SET_SELECT_PACKAGE,
   ADD_QUOTATION_SET_DISCOUNT,
   UPDATE_QUOTATION_SET_DISCOUNT,
   REMOVE_QUOTATION_SET_DISCOUNT,
   ADD_ADDON,
   REMOVE_ADDON,
   UPDATE_ADDED_ADDON,
   SET_ERROR,
   QUOTATION_LOADING
} from '../actions/quotationAction';

const defaultQuantity = {
   email: 100
};

const getDefaultQutity = (addon, defaultTeamRole) => {
   if (addon.value_type === 'boolean') return 1;
   if (addon.feature_type === 'team-role') return defaultTeamRole.minimum;
   return defaultQuantity[addon.code] || 100;
};

const calculateAddonsTotalPrice = ({ addedAddons }) => {
   if (!(Array.isArray(addedAddons) && addedAddons.length)) {
      return 0;
   }
   const addonsPrice = addedAddons.reduce((prev, cur) => cur.price + prev, 0);
   // return addonsPrice < 0 ? 0 : addonsPrice;
   return addonsPrice;
};

const transFormAddedAddons = (state, calPricePerUnit) => (addon) => {
   const packageDurationDays = parseNumber(state.selectedPackage?.package_duration_days);
   const unuseDays = state.quotationSetUnuseDays;
   const durationDays = packageDurationDays - unuseDays; // ควรจะต้องไม่ตำ่กว่า 0

   // const quantity =  addon.feature_type === null ? durationDays : getDefaultQutity(addon.code)
   // const quantity = addon.quantity ?? (addon.value_type === 'boolean' ? 1 : getDefaultQutity(addon.code));
   const quantity = addon.quantity ?? getDefaultQutity(addon, state.defaultTeamRole);
   const tragetAddon = {
      ...addon,
      addonDiscount: addon.addonDiscount,
      quantity: quantity,
      disabledEditQuantity: addon.value_type === 'boolean',
      days: addon.feature_type === 'amount' ? 1 : durationDays
      // disabledEditPrice: addon
   };
   let pricePerUnit = addon.price_per_unit ?? (calPricePerUnit ? 0 : '');
   if (calPricePerUnit) {
      if (addon.price_step && Array.isArray(addon.price_step) && !addon.isPackageFeature) {
         const priceStepGot = addon.price_step.find((step) => {
            if (step.min_value && step.max_value !== null) {
               return quantity <= step.max_value && quantity >= step.min_value;
            }
            if (step.max_value === null) {
               return quantity >= step.min_value;
            }
            if (step.min_value === null) {
               return quantity <= step.max_value;
            }
         });
         pricePerUnit = priceStepGot?.price ?? pricePerUnit;
      }
   }
   pricePerUnit = pricePerUnit !== '' ? parseNumber(pricePerUnit) : pricePerUnit;
   const price = parseNumber(pricePerUnit) * tragetAddon.quantity * tragetAddon.days;
   const priceAfterDiscount = calculateDiscountedPrice(price, tragetAddon.addonDiscount);
   return {
      ...tragetAddon,
      // price_per_unit: parseNumber(pricePerUnit),
      price_per_unit: pricePerUnit,
      price: !!addon.isPackageFeature ? parseNumber(addon.price) : priceAfterDiscount,
      days: !!addon.isPackageFeature ? addon.days : tragetAddon.days
   };
};
const computeValidation = (state, grandTotal) => {
   let error = {};
   let isOk = true;
   if (!state.selectedPackage?.id) {
      isOk = false;
      // return {
      //    isOk: false,
      //    error: null
      //    // error: { save: 'กรุณาเลือกแพ็กเกจ' }
      // };
   }
   const seats = Number(state.seats);
   if (seats < Number(state.selectedPackage?.minimum_seats)) {
      isOk = false;
      error = {
         ...error,
         seats: 'จำนวนสมาชิกต้องมากกว่า ' + Number(state.selectedPackage?.minimum_seats) + ' คน'
      };
   }
   if ((isNaN(seats) || seats < 1) && !!state.selectedPackage?.id) {
      isOk = false;
      error = {
         ...error,
         seats: 'กรุณาระบุจำนวนให้ถูกต้อง'
      };
   }
   const customizePakageName = ((state.customizePakageName || '') + '').trim();
   if (!customizePakageName && !!state.selectedPackage?.id) {
      isOk = false;
      error = {
         ...error,
         customizePakageName: 'กรุณาระบุชื่อแพ็กเกจ'
      };
   }
   const addedAddonsError = state.addedAddons.map((addOn) => {
      const pricePerUnitIsOk =
         isNaN(parseFloat(addOn.price_per_unit)) ||
         (parseFloat(addOn.price_per_unit) < 0 && !addOn.isPackageFeature) ||
         addOn.price_per_unit === '';
      const quantityIsOk = isNaN(parseInt(addOn.quantity)) || parseInt(addOn.quantity) < 1;
      return {
         price_per_unit: pricePerUnitIsOk ? 'กรุณาระบุจำนวนให้ถูกต้อง' : null,
         quantity: quantityIsOk ? 'กรุณาระบุจำนวนให้ถูกต้อง' : null
      };
   });
   const isAllAddonsError = addedAddonsError.filter(
      (addon) => addon.price_per_unit !== null || addon.quantity !== null
   );
   if (isAllAddonsError.length > 0) {
      isOk = false;
      error = {
         ...error,
         addedAddons: addedAddonsError
      };
   }
   const packageOrderType = state.packageOrderType;

   if (grandTotal <= 0 && ['normal', 'private'].some((type) => type === packageOrderType)) {
      isOk = false;
      error = {
         ...error,
         save: 'ไม่สามารถซื้อแพ็กเกจนี้ได้'
      };
   }
   if (grandTotal !== 0 && ['free', 'staff', 'normal-free'].some((type) => type === packageOrderType)) {
      isOk = false;
      error = {
         ...error,
         save: 'ไม่สามารถซื้อแพ็กเกจนี้ได้'
      };
   }
   return {
      isOk: isOk,
      error: error
   };
};
const mergeWithUpdatedPrices = (state, calPricePerUnit = true) => {
   const addedAddons = {
      addedAddons: (state.addedAddons || []).map(transFormAddedAddons(state, calPricePerUnit))
   };
   // const mergedObject = merge(state, addedAddons);
   const mergedObject = { ...state, ...addedAddons };
   const packageTotalPrice = calculatePackageTotalPrice(mergedObject);
   const addonsTotalPrice = calculateAddonsTotalPrice(mergedObject);
   const subtotal = packageTotalPrice + addonsTotalPrice;
   const subtotalAfterDiscount = calculateDiscountedPrice(subtotal, mergedObject.quotationSetDiscount);
   //    - base_price = Package(price_per_seat x seats) - Discount(package) - Discount(set)
   // - unuse_price = base_price x unuse_days / package_duration_days
   // - remain_price = base_price x remain_days / package_duration_days
   const unusePrice = calculateUnusePrice(
      packageTotalPrice,
      mergedObject.quotationSetDiscount,
      mergedObject.quotationSetUnuseDays,
      mergedObject.selectedPackage?.package_duration_days
   );
   const excludedVAT =
      ((subtotalAfterDiscount - unusePrice - parseNumber(mergedObject.quotationSetRemain)) * 100) / 107;
   const vat = ((subtotalAfterDiscount - unusePrice - parseNumber(mergedObject.quotationSetRemain)) * 7) / 107;
   const grandTotal = subtotalAfterDiscount - unusePrice - parseNumber(mergedObject.quotationSetRemain);
   const netTotal = netTotalPrice(grandTotal, mergedObject.quotationSetWithholdingTax);

   let saveAble = computeValidation(mergedObject, grandTotal, netTotal);
   const priceObject = {
      packageTotalPrice: packageTotalPrice,
      addonsTotalPrice: addonsTotalPrice,
      quotationSetSubTotal: subtotal,
      quotationSetUnusePrice: unusePrice,
      quotationSetExcludedVat: excludedVAT,
      quotationSetVat: vat,
      quotationSetGrandTotal: grandTotal,
      quotationSetNetTotal: netTotal
   };
   return {
      ...merge(mergedObject, priceObject),
      isCanSave: saveAble.isOk,
      error: saveAble.error
   };
};

const initialState = {
   quotationId: '',
   quotationSetId: '',
   isLoading: false,
   error: {},
   listPackages: [],
   listAddons: [],
   selectedPackage: null,
   seats: 0,
   minimumSeats: 1,
   customizePakageName: '',
   packageDiscount: [],
   packageTotalPrice: 0,
   addedAddons: [],
   addonsTotalPrice: 0,
   quotationSetSubTotal: 0,
   quotationSetUnusePrice: 0,
   quotationSetUnuseDays: 0,
   quotationSetRemain: 0,
   quotationSetDiscount: [],
   quotationSetGrandTotal: 0,
   quotationSetWithholdingTax: 3,
   quotationSetNetTotal: 0,
   note: '',
   isCanSave: false,
   defaultTeamRole: { minimum: 2, step: 2 }
};

export default function quotationReducer(state = initialState, action) {
   switch (action.type) {
      case QUOTATION_LOADING:
         return { ...state, isLoading: action.payload.isLoading };
      case BEGIN_QUOTATION:
         return mergeWithUpdatedPrices({
            ...state,
            ...action.payload.quotationData
         });
      case SET_SELECT_PACKAGE:
         return mergeWithUpdatedPrices({
            ...state,
            ...action.payload.quotationData
         });
      case SET_WITH_KEY:
         return mergeWithUpdatedPrices({
            ...state,
            error: null,
            [action.payload.key]: action.payload.value
         });
      case ADD_ADDON:
         return mergeWithUpdatedPrices({
            ...state,
            addedAddons: [...state.addedAddons, action.payload.addonToAdd]
         });
      case REMOVE_ADDON:
         return mergeWithUpdatedPrices({
            ...state,
            addedAddons: state.addedAddons.filter((addon) => addon.id !== action.payload.id)
         });
      case UPDATE_ADDED_ADDON:
         return mergeWithUpdatedPrices(
            {
               ...state,
               addedAddons: [
                  ...state.addedAddons.slice(0, action.payload.index),
                  action.payload.updatedTargetAddon,
                  ...state.addedAddons.slice(action.payload.index + 1)
               ]
            },
            action.payload.calPricePerUnit
         );
      case ADD_PACKAGE_DISCOUNT:
         return mergeWithUpdatedPrices({
            ...state,
            packageDiscount: [...state.packageDiscount, action.payload.data]
         });
      case UPDATE_PACKAGE_DISCOUNT:
         return mergeWithUpdatedPrices({
            ...state,
            packageDiscount: [
               ...state.packageDiscount.slice(0, action.payload.discountIndex),
               action.payload.data,
               ...state.packageDiscount.slice(action.payload.discountIndex + 1)
            ]
         });
      case REMOVE_PACKAGE_DISCOUNT:
         return mergeWithUpdatedPrices({
            ...state,
            packageDiscount: state.packageDiscount.filter((disc) => disc.id !== action.payload.id)
         });
      case ADD_QUOTATION_SET_DISCOUNT:
         return mergeWithUpdatedPrices({
            ...state,
            quotationSetDiscount: [...state.quotationSetDiscount, action.payload.data]
         });
      case UPDATE_QUOTATION_SET_DISCOUNT:
         return mergeWithUpdatedPrices({
            ...state,
            quotationSetDiscount: [
               ...state.quotationSetDiscount.slice(0, action.payload.discountIndex),
               action.payload.data,
               ...state.quotationSetDiscount.slice(action.payload.discountIndex + 1)
            ]
         });
      case REMOVE_QUOTATION_SET_DISCOUNT:
         return mergeWithUpdatedPrices({
            ...state,
            quotationSetDiscount: state.quotationSetDiscount.filter((disc) => disc.id !== action.payload.id)
         });
      case SET_ERROR:
         return {
            ...state,
            error: action.payload.error
         };
      case CLEAN_UP_QUOTATION_SET:
         return {
            ...initialState
         };
      default:
         return state;
   }
}
