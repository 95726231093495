import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core/';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Checkbox from '@material-ui/core/Checkbox'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import axiosToken from 'src/common/AxiosToken';
import { useDialog } from 'src/hooks/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerOrders } from 'src/redux/actions/customerOrdersAction';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Tag as TagIcon, Users as UsersIcon, Edit3 as Edit } from 'react-feather';
import { calculateDiscountedPrice, calculateUnusePrice, parseCustomDecimal, parseDecimalForm, parseNumber, parsePriceForm } from 'src/utils/orderUtils';
import { usePermissions } from 'src/hooks/usePermissions';
import { getOrderById, displayLoading } from 'src/redux/actions/customerOrderAction';
import DialogDate from './DialogDate';
import BigNumber from 'bignumber.js';

const useStyles = makeStyles((theme) => ({
   invoiceIcon: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[3]
   },
   btnCancel: {
      backgroundColor: '#fb4c6ee0',
      color: '#fff'
   },
   companyName: {
      fontSize: 13
   },
   packageLabel: {
      marginRight: 16
   },
   packageName: {},
   featureText: {
      marginLeft: 8
   },
   invoiceInfo: {
      borderRadius: 20,
      marginTop: 8,
      padding: 8,
      background: 'linear-gradient(91.64deg, rgba(21, 137, 255, 0.65) 0.18%, rgba(0, 190, 59, 0.46) 100%)',
      [theme.breakpoints.down('xs')]: {
         padding: 16
      }
   },
   invoiceInfoRow: {
      paddingLeft: 8,
      marginTop: 8,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      flexDirection: 'row',
      '& .invoiceInfoRowTitle': {
         minWidth: '160px',
         color: '#fff',
         [theme.breakpoints.down('xs')]: {
            fontSize: '12px'
         }
      },
      '& .invoiceInfoRowDetail': {
         color: '#fff',
         fontWeight: 600,
         marginLeft: 24
      },
      [theme.breakpoints.down('500')]: {
         paddingLeft: 0,
         flexDirection: 'column',
         alignItems: 'center'
      }
   },
   seatsIcon: {
      marginRight: 8,
      color: theme.palette.text.secondary
   },
   discountIcon: {
      marginRight: 8,
      color: theme.palette.text.secondary
   },
   pdfBtn: {
      wordBreak: 'break-word',
      backgroundColor: '#f8f8f8',
      width: 300,
      fontWeight: 600,
      textTransform: 'capitalize'
   },
   packageInfo: {
      marginTop: 8,
      marginBottom: 8,
      padding: '8px 32px',
      '& .package_dt': {
         flexWrap: 'wrap',
         gap: 20,
         [theme.breakpoints.down('sm')]: {
            gap: 4
         }
      },
      [theme.breakpoints.down('sm')]: {
         padding: '8px 16px'
      },
      [theme.breakpoints.down('xs')]: {
         padding: '8px 0'
      }
   },
   addonsBox: {
      flexDirection: 'column',
      gap: 0,
      [theme.breakpoints.down('sm')]: {
         gap: 8
      },
      [theme.breakpoints.down('xs')]: {
         gap: 16
      }
   }
}));

// EXCELLENT DIGITAL DEVELOPMENT COMPANY LIMITED (Headquarters)
// 128/202-203, Payathai Plaza Bldg. 19th Floor, Suite A,B. Payathai Road,
// Thungpayathai Sub-district, Rajthavee District Bangkok, THAILAND 10400
// Email : contact@enablesurvey.com   Phone : +66-2090-9099
// TAX ID No. : 0105561054090

const companyData = {
   logo: '/static/images/EDD-Logo.png',
   name: 'EXCELLENT DIGITAL DEVELOPMENT COMPANY LIMITED (Headquarters)',
   address1: '128/202-203, Payathai Plaza Bldg. 19th Floor, Suite A,B.',
   address2: 'Payathai Road, Thungpayathai Sub-district, Rajthavee District',
   address3: 'Bangkok, THAILAND 10400',
   email: 'contact@enablesurvey.com',
   phone: '+66-2090-9099',
   taxId: '0105561054090'
};

const CompanyInfo = () => {
   const classes = useStyles();
   return (
      <Box
         p={{ xs: 0, md: 2 }}
         display="flex"
         flexGrow={1}
         flexDirection={{ xs: 'column', sm: 'row' }}
         justifyContent="space-between"
         style={{ gap: 8 }}
      >
         <Box>
            <img src={companyData.logo} alt="company logo" width={100} height="auto" />
         </Box>
         <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="body1" color="textPrimary" className={classes.companyName}>
               <b>{companyData.name}</b>
            </Typography>
            <Typography variant="caption" color="textSecondary">
               {companyData.email}
            </Typography>
            <Typography variant="caption" color="textSecondary">
               {companyData.phone}
            </Typography>
            <Typography variant="caption" color="textSecondary">
               TAX ID No.{' ' + companyData.taxId}
            </Typography>
         </Box>
         <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            maxWidth={{ xs: '100%', sm: '50%', md: '30%' }}
         >
            <Typography variant="caption" color="textSecondary">
               {companyData.address1} + {companyData.address2} + {companyData.address3}
            </Typography>
         </Box>
      </Box>
   );
};

const OrderInvoice = ({ order, pdfHost, disabled, handleLoadOrderFeature }) => {
   const param = useParams();
   const classes = useStyles();
   const dialog = useDialog();
   const dispatch = useDispatch();
   const { can, cannot } = usePermissions();
   const unuseDays = useSelector((state) => state.customerOrder.unuseDays);
   const { enqueueSnackbar } = useSnackbar();
   const [credit, setCredit] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [openDialog, setOpenDialog] = useState(false);
   const payCurrencyCode = useSelector((state) => state.customerOrder.order?.pay_currency_code);
   const payCurrencyRate = useSelector((state) => state.customerOrder.order?.pay_currency_rate);
   const currencyDecimal = useSelector((state) => state.customerOrder.order?.currency_decimal);
   // const [isAddCredit, setIsAddCredit] = useState(false)
   const handleOpenDialog = () => {
      setOpenDialog(true);
   };

   const handleCloseDialog = async (type) => {
      if (type === 'save') {
         setOpenDialog(false);
         dispatch(displayLoading(true));
         const shouldLoading = false;
         await dispatch(getOrderById(order.id, shouldLoading));
         await dispatch(displayLoading(false));
      } else {
         setOpenDialog(false);
      }
   };

   const isOnPackageDuration = () => {
      if (order.package?.package_expired_at && order.package?.package_begin_at) {
         const packageBeginAt = moment(order.package.package_begin_at);
         const packageExpiredAt = moment(order.package.package_expired_at);
         // return moment().isBetween(packageBeginAt, packageExpiredAt);
         return moment().isBefore(packageExpiredAt);
      }
      return false;
   };

   const handleClickGetInvoice = (locale) => {
      window.open(`${pdfHost}/api/${locale}/order/invoice/${order.id}`, '_blank');
   };

   const handleSaveCredit = async () => {
      const toNum = Number(credit);
      let isConfirm = null;
      if (isNaN(toNum) || toNum === 0) {
         await dialog.error('Credits should be at least 1');
         return;
      }
      if (toNum > order?.package?.package_duration_days) {
         await dialog.error('Credits should less than package duration.');
         return;
      }
      if (order.credit_days === null) {
         isConfirm = await dialog.confirm('Are you sure to add credit for this customer ?');
      } else {
         isConfirm = await dialog.confirm('Are you sure to edit credit for this customer ?');
      }
      if (!isConfirm) {
         return;
      }
      setIsLoading(true);
      try {
         let res = null;
         if (order.credit_days === null) {
            res = await axiosToken.post(`order2/${order.id}/add-credit`, { credit });
         } else {
            res = await axiosToken.put(`order2/${order.id}/edit-credit`, { credit });
         }
         enqueueSnackbar(res.data.message, {
            variant: 'success',
            autoHideDuration: 2000
         });
         const shouldLoading = false;
         await dispatch(getOrderById(order.id, shouldLoading));
         handleLoadOrderFeature();
         setIsLoading(false);
      } catch (error) {
         setIsLoading(false);
         dialog.error(error);
      }
   };
   useEffect(() => {
      if (order.credit_days) {
         setCredit(order.credit_days);
      }
   }, [order.credit_days]);

   const disabledAddCredit =
      order.credit_days !== null || order.status !== 'invoice' || !isOnPackageDuration() || isLoading;

   const disabledEditCredit = cannot(['EDIT_CREDIT'], 'ORDER') || isLoading;

   const calSubtotal = () => {
      if (!order.package) return 0;
      const packagePrice = calculateDiscountedPrice(order.package?.total_price, order.package.discount);
      const addonsPrice =
         order.addons?.reduce((prev, cur) => {
            const allTotalPrice = calculateDiscountedPrice(parseNumber(cur.total_price), cur.discount);
            return allTotalPrice + prev;
         }, 0) || 0;
      const subtotal = packagePrice + addonsPrice;
      return subtotal;
   };

   const calUnusePrice = () => {
      if (!order.package) return 0;
      const packagePrice = calculateDiscountedPrice(order.package?.total_price, order.package?.discount);
      return calculateUnusePrice(packagePrice, order.discount, unuseDays, order.package?.package_duration_days);
   };
   if (!order.package) {
      return (
         <div>
            <Box display="flex" height="190px" alignItems="center" justifyContent="center">
               <Typography width="auto" component={Box} textAlign="center">
                  <b>Package not found ..!!</b>
                  <p>Make sure package in quotation tab is selected or this order is not canceled</p>
               </Typography>
            </Box>
         </div>
      );
   }

   if (cannot(['OPERATE', 'VIEW'], 'ORDER_INVOICE')) {
      return (
         <Box display="flex" alignItems="center" justifyContent="center" height="120px">
            <Typography>You are not permission.</Typography>
         </Box>
      );
   }

   return (
      <Box border="1px solid #ddd" borderRadius={8} p={2}>
         <CompanyInfo />

         <Box p={2}>
            <Box className={classes.invoiceInfo}>
               <Box className={classes.invoiceInfoRow}>
                  <Typography variant="h4" color="textPrimary" className="invoiceInfoRowTitle">
                     Invoice No. :
                  </Typography>
                  <Typography variant="h4" color="textPrimary" className="invoiceInfoRowDetail">
                     {order.invoice.invoice_no}
                  </Typography>
               </Box>
               {order.invoice_no_2c2p && (
                  <Box className={classes.invoiceInfoRow}>
                     <Typography variant="body1" color="textPrimary" className="invoiceInfoRowTitle">
                        Invoice No. (2c2p) :
                     </Typography>
                     <Typography variant="body1" color="textPrimary" className="invoiceInfoRowDetail">
                        {order.invoice_no_2c2p}
                     </Typography>
                  </Box>
               )}
               <Box className={classes.invoiceInfoRow}>
                  <Typography variant="body1" color="textPrimary" className="invoiceInfoRowTitle">
                     Invoice Date :
                  </Typography>
                  <Box display={'flex'} alignItems="center">
                     <Typography variant="body1" color="textPrimary" className="invoiceInfoRowDetail">
                        {order.invoice?.invoice_date
                           ? moment(order.invoice?.invoice_date).format('DD/MM/YYYY HH:mm')
                           : 'Unknown'}
                     </Typography>
                     {can(['EDIT'], 'ORDER_INVOICE') ? (
                        <Tooltip title="Edit Invoice Date" arrow>
                           <IconButton
                              marginLeft="15px"
                              size="small"
                              style={{ color: '#FFF' }}
                              onClick={handleOpenDialog}
                           >
                              <Edit width={'70%'} />
                           </IconButton>
                        </Tooltip>
                     ) : null}
                  </Box>
               </Box>
               <Box className={classes.invoiceInfoRow}>
                  <Typography variant="body1" color="textPrimary" className="invoiceInfoRowTitle">
                     Issued Date :
                  </Typography>
                  <Typography variant="body1" color="textPrimary" className="invoiceInfoRowDetail">
                     {order.invoice?.created_at
                        ? moment(order.invoice?.created_at).format('DD/MM/YYYY HH:mm')
                        : 'Unknown'}
                  </Typography>
               </Box>
               <Box className={classes.invoiceInfoRow}>
                  <Typography variant="body1" color="textPrimary" className="invoiceInfoRowTitle">
                     Due Date :
                  </Typography>
                  <Typography variant="body1" color="textPrimary" className="invoiceInfoRowDetail">
                     {order.invoice?.expired_at
                        ? moment(order.invoice?.expired_at).format('DD/MM/YYYY HH:mm')
                        : 'Unknown'}
                  </Typography>
               </Box>
            </Box>
         </Box>
         <Box className={classes.packageInfo}>
            <Box className="package_dt" display="flex" alignItems="center">
               <Box display="flex" alignItems="center" flexWrap="wrap">
                  <Typography className={classes.packageLabel} variant="body1" color="textPrimary">
                     <b>Package</b>
                  </Typography>
                  <Typography className={classes.packageName} variant="body1" color="textSecondary">
                     {(order.package?.name || '') + ' (' + (order?.package?.detail?.name || '') + ')'}
                  </Typography>
               </Box>
               <Box display="flex" alignItems="center" border="1px solid #ddd" px={1} borderRadius={4}>
                  <UsersIcon size={20} className={classes.seatsIcon} />
                  <Typography className={classes.packageName} variant="body1" color="textSecondary">
                     Seats {order.package.seats}
                  </Typography>
               </Box>
               {order.package.discount?.length > 0 && (
                  <Box display="flex" alignItems="center" border="1px solid #ddd" px={1} py={0.25} borderRadius={4}>
                     <TagIcon size={20} className={classes.discountIcon} />
                     <Typography variant="caption" color="textSecondary" >
                        {order.package.discount
                           .map((dc) => (dc.discount_cal_type === 'direct' ? `${parseDecimalForm(new BigNumber(dc.discount).multipliedBy(payCurrencyRate))}` : Number(dc.discount).toFixed(2)) + (dc.discount_cal_type === 'direct' ? `${payCurrencyCode === 'THB' ? '฿' : ' (' + payCurrencyCode + ')'}` : '%'))
                           .join(' , ')}
                     </Typography>
                  </Box>
               )}
               <Box pr={2} flexGrow={1} style={{ textAlign: 'right' }}>
                  <Typography variant="body1" color="textPrimary">
                     <b>
                        {`${parseDecimalForm(new BigNumber(calculateDiscountedPrice(order.package?.total_price, order.package.discount)).multipliedBy(payCurrencyRate))}`}
                     </b>
                  </Typography>
               </Box>
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Package Type {' : '}
                  </Typography>
               </Box>
               <Box ml={1}>
                  <Typography variant="caption" color="textSecondary">
                     {(order.package?.package_type || 'Unknown').toUpperCase()}{' '}
                  </Typography>
               </Box>
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Subscription type {' : '}
                  </Typography>
               </Box>
               <Box ml={1}>
                  <Typography variant="caption" color="textSecondary">
                     {(order.order_type || 'Unknown').toUpperCase()}
                     {order.remain_receipt_no ? ' (from. ' + order.remain_receipt_no + ')' : ''}
                  </Typography>
               </Box>
            </Box>
            <Box py={1} display={(order.addons?.length ?? 0) === 0 ? 'block' : 'none'}>
               <Divider />
            </Box>
            <Box
               display={(order.addons?.length ?? 0) > 0 ? 'flex' : 'none'}
               className={classes.addonsBox}
               my={1}
               border="1px solid #ddd"
               borderRadius={4}
               p={1}
            >
               <Box display="flex" borderBottom="1px solid #ddd" flexWrap="wrap">
                  <Box
                     width={{ xs: '100%', md: '45%' }}
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                     style={{ fontWeight: 'bold' }}
                  >
                     Items
                  </Box>
                  <Box
                     width={{ xs: '33%', md: '10%' }}
                     textAlign={{ xs: 'left', md: 'center' }}
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                  >
                     Price
                  </Box>
                  <Box
                     width={{ xs: '33%', md: '10%' }}
                     textAlign="center"
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                  >
                     Quantity
                  </Box>
                  <Box
                     width={{ xs: '33%', md: '10%' }}
                     textAlign={{ xs: 'right', md: 'center' }}
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                  >
                     Use Days
                  </Box>
                  <Box
                     width={{ xs: '50%', md: '15%' }}
                     textAlign={{ xs: 'left', md: 'center' }}
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                  >
                     Discounts
                  </Box>
                  <Box
                     width={{ xs: '50%', md: '10%' }}
                     textAlign={{ xs: 'right', md: 'center' }}
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                  >
                     Total Price
                  </Box>
               </Box>
               {(order.addons || []).map((addon, index) => (
                  <Box display="flex" alignItems="center" key={addon.feature_code + '--' + index} flexWrap="wrap">
                     <Box width={{ xs: '100%', md: '45%' }}>
                        <Typography variant="caption" color="textSecondary" style={{ fontWeight: 'bold' }}>
                           Addon {index + 1}
                        </Typography>
                        <Typography variant="caption" color="textPrimary" className={classes.featureText}>
                           {addon.detail?.feature_label || addon.feature_code}
                        </Typography>
                     </Box>
                     <Box
                        display="flex"
                        alignItems="center"
                        width={{ xs: '33%', md: '10%' }}
                        justifyContent={{ xs: 'flex-start', md: 'center' }}
                     >
                        <Typography
                           variant="caption"
                           color="textSecondary"
                        //  className={classes.featureText}
                        >
                           {payCurrencyCode === 'THB' ? parsePriceForm(addon.price) + '฿' : `${parseDecimalForm(new BigNumber(addon.price).multipliedBy(payCurrencyRate))}` + ' ' + payCurrencyCode}
                        </Typography>
                     </Box>
                     <Box display="flex" alignItems="center" width={{ xs: '33%', md: '10%' }} justifyContent="center">
                        <Typography
                           variant="caption"
                           color="textSecondary"
                        //  className={classes.featureText}
                        >
                           {addon.quantity + ' ' + (addon.unit ?? (addon.feature_type ? 'Days' : ''))}
                        </Typography>
                     </Box>
                     <Box
                        display="flex"
                        alignItems="center"
                        width={{ xs: '33%', md: '10%' }}
                        justifyContent={{ xs: 'flex-end', md: 'center' }}
                     >
                        <Typography variant="caption" color="textSecondary">
                           {addon.feature_type === 'amount' ? '-' : (addon.days ? addon.days : 0) + ' Days'}
                        </Typography>
                     </Box>
                     <Box
                        display="flex"
                        alignItems="center"
                        width={{ xs: '50%', md: '15%' }}
                        justifyContent={{ xs: 'flex-start', md: 'center' }}
                     >
                        {addon.discount.length > 0 ? (
                           <Box display="flex" alignItems="center">
                              <Typography
                                 variant="caption"
                                 color="textSecondary"
                              //  className={classes.featureText}
                              >
                                 {addon.discount
                                    .map((dc) => (dc.discount_cal_type === 'direct' ? `${parseDecimalForm(new BigNumber(dc.discount).multipliedBy(payCurrencyRate))}` : Number(dc.discount).toFixed(2)) + (dc.discount_cal_type === 'direct' ? `${payCurrencyCode === 'THB' ? '฿' : ' (' + payCurrencyCode + ')'}` : '%'))
                                    .join(' , ')}
                              </Typography>
                           </Box>
                        ) : (
                           <Typography variant="caption" color="textSecondary">
                              {' - '}
                           </Typography>
                        )}
                     </Box>

                     <Box
                        display="flex"
                        alignItems="center"
                        width={{ xs: '50%', md: '10%' }}
                        justifyContent={{ xs: 'flex-end', md: 'center' }}
                     >
                        <Typography variant="caption" color="textPrimary">
                           <b>
                              {`${parseDecimalForm(new BigNumber(calculateDiscountedPrice(parseNumber(addon.total_price), addon.discount)).multipliedBy(payCurrencyRate))}`}
                           </b>
                        </Typography>
                     </Box>
                  </Box>
               ))}
            </Box>
            <Box display="flex" pr={{ xs: 1, md: 4 }}>
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Sub Total
                  </Typography>
               </Box>
               <Box ml={2} width={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>{`${parseDecimalForm(new BigNumber(calSubtotal()).multipliedBy(payCurrencyRate) || 0)}`}</b>
                  </Typography>
               </Box>
            </Box>
            {order.discount.length > 0 && (
               <Box display="flex" pr={{ xs: 1, md: 4 }}>
                  <Box flexGrow={1} />
                  <Box>
                     <Typography variant="caption" color="textPrimary">
                        Discount
                     </Typography>
                  </Box>
                  <Box ml={2} minWidth={70} textAlign="right">
                     <Typography variant="caption" color="textPrimary">
                        <b>
                           {order.discount
                              .map((dc) => (dc.discount_cal_type === 'direct' ? `${parseDecimalForm(new BigNumber(dc.discount).multipliedBy(payCurrencyRate))}` : Number(dc.discount).toFixed(2)) + (dc.discount_cal_type === 'direct' ? `${payCurrencyCode === 'THB' ? '฿' : ' (' + payCurrencyCode + ')'}` : '%'))
                              .join(' , ')}
                        </b>
                     </Typography>
                  </Box>
               </Box>
            )}
            {parseNumber(order.order_package_remain_price) > 0 && (
               <Box display="flex" pr={{ xs: 1, md: 4 }}>
                  <Box flexGrow={1} />
                  <Box>
                     <Typography variant="caption" color="textPrimary">
                        Remain
                     </Typography>
                  </Box>
                  <Box ml={2} width={70} textAlign="right">
                     <Typography variant="caption" color="textPrimary">
                        <b>{`${parseDecimalForm(new BigNumber(order.order_package_remain_price).multipliedBy(payCurrencyRate) || '')}`}</b>
                     </Typography>
                  </Box>
               </Box>
            )}
            {calUnusePrice() > 0 && (
               <Box display="flex" pr={{ xs: 1, md: 4 }}>
                  <Box flexGrow={1} />
                  <Box>
                     <Typography variant="caption" color="textPrimary">
                        Unuse Price
                     </Typography>
                  </Box>
                  <Box ml={2} width={70} textAlign="right">
                     <Typography variant="caption" color="textPrimary">
                        <b>{`${parseDecimalForm(new BigNumber(calUnusePrice()).multipliedBy(payCurrencyRate) || '')}`}</b>
                     </Typography>
                  </Box>
               </Box>
            )}
            <Box display="flex" pr={{ xs: 1, md: 4 }}>
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Grand Total
                  </Typography>
               </Box>
               <Box ml={2} width={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>{`${parseDecimalForm(new BigNumber(order.total_price_include_vat).multipliedBy(payCurrencyRate) || '')}`}</b>
                  </Typography>
               </Box>
            </Box>
            <Box display="flex" pr={{ xs: 1, md: 4 }}>
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Withholding tax
                  </Typography>
               </Box>
               <Box ml={2} width={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>{order.additional_tax_rate == 0 ? 0 : order.additional_tax_rate || ''}%</b>
                  </Typography>
               </Box>
            </Box>
            <Box display="flex" pr={{ xs: 1, md: 4 }}>
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Net price
                  </Typography>
               </Box>
               <Box ml={2} width={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>{parsePriceForm(parseCustomDecimal(order?.summary_price * payCurrencyRate, currencyDecimal)) || '0'}</b>
                  </Typography>
               </Box>
            </Box>
         </Box>
         <Box display="flex" justifyContent="center" my={2}>
            <Button
               // size="small"
               onClick={() => handleClickGetInvoice('th')}
               variant="text"
               color="primary"
               className={classes.pdfBtn}
               startIcon={<PictureAsPdfIcon />}
            >
               Invoice TH
            </Button>
            <Box ml={1} mt={1} />
            <Button
               // size="small"
               onClick={() => handleClickGetInvoice('en')}
               variant="text"
               color="primary"
               className={classes.pdfBtn}
               startIcon={<PictureAsPdfIcon />}
            >
               Invoice EN
            </Button>
         </Box>
         <Box textAlign="center" mt={2}>
            <Box display="flex" alignItems="center" justifyContent="center">
               <Box mx={1} display="flex" alignItems="flex-start">
                  <Box mx={1} mt={1}>
                     <Typography color="textPrimary">Credit (Days)</Typography>
                  </Box>
                  <TextField
                     size="small"
                     label=""
                     name="credit"
                     onChange={(e) => setCredit(e.target.value)}
                     required
                     type="number"
                     value={credit || ''}
                     variant="outlined"
                     disabled={
                        ['credit', 'receive'].includes(order.status)
                           ? disabledEditCredit
                           : disabledAddCredit || disabled
                     }
                     InputProps={{
                        inputProps: { min: 0 }
                     }}
                     helperText={
                        !isOnPackageDuration()
                           ? // ? 'ไม่สามารถให้ credit package ที่สั่งซื้อล่วงหน้าหรือให้ credit ย้อนหลังได้'
                           'ไม่สามารถให้ credit ย้อนหลังได้'
                           : null
                     }
                  />
                  <Box width={10} />
                  <Button
                     onClick={handleSaveCredit}
                     disabled={
                        ['credit', 'receive'].includes(order.status)
                           ? disabledEditCredit
                           : disabledAddCredit || disabled
                     }
                     variant="contained"
                     color="secondary"
                  >
                     {isLoading ? <CircularProgress size={24} /> : 'Save'}
                  </Button>
               </Box>
               {order.credit_expired_at && new Date(order.credit_expired_at) < new Date() && (
                  <Box mx={1}>
                     <Typography variant="subtitle1" color="error">
                        *Expired
                     </Typography>
                  </Box>
               )}
            </Box>
            {/* <Box my={2}>
                    <Button variant="contained">Cancel Credit</Button>
                </Box>
                <Box my={2} width={300} margin="auto">
                    <Button className={classes.btnCancel} fullWidth variant="contained" >Cancel order</Button>
                </Box> */}
         </Box>
         <DialogDate open={openDialog} close={handleCloseDialog} data={order} type={'invoice'} />
      </Box>
   );
};

export default OrderInvoice;
