import * as React from "react";
import { ReactSurveyElement, SurveyElementBase, SurveyQuestionElementBase } from "./reactquestion_element";
import { QuestionImagePickerModel } from "survey-core";
import { ImageItemValue, SurveyModel } from "survey-core";
import { ReactQuestionFactory } from "./reactquestion_factory";
import { ReactSurveyElementsWrapper } from "./reactsurveymodel";
import { SurveyQuestionCommentItem } from "./reactquestion_comment";
import { chunkArray } from "../utils/utils";
import { CssClassBuilder } from "../utils/cssClassBuilder";

export class SurveyQuestionImagePicker extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
  }
  protected get question(): QuestionImagePickerModel {
    return this.questionBase as QuestionImagePickerModel;
  }
  
  protected extraRootClasses = () => {
    return new CssClassBuilder()
    .append(this.question.getSelectBaseRootCss())
    .append("choices-wrapper-col-" + this.question.colCount, this.question.hasColumns)
    .append("choices-wrapper-nocolumn", !this.question.hasColumns)
    .append("gap-"+this.question.gap, !!this.question.gap)
    .toString();
  }

  protected renderElement(): JSX.Element {
    var cssClasses = this.question.cssClasses;
    // enable survey => update className and remove column structure 
    return (
      <div className={"enablesurvey-image-picker" + (this.question.isMobile ? ' on-mobile' : '')}>
        <fieldset className={cssClasses.root + " " +this.extraRootClasses()}>
          <legend
            role="radio"
            aria-label={this.question.locTitle?.renderedHtml} />
            { this.question.colCount > 1 ? this.getColumnsFlex(cssClasses) : this.getItems(cssClasses)}
            { this.question.hasOther ? this.getItemOther(cssClasses) : null }
            { this.question.isOtherSelected ? this.renderOther() : null }
        </fieldset>
      </div>
    );
  }

  protected getColumnsFlex(cssClasses: any) {
    var choiceRows = chunkArray(this.question.visibleChoices?.filter((ch: ImageItemValue) => ch.value!=='other'), this.question.colCount)
    return choiceRows.map((rowItems: ImageItemValue[],ci: number) => {
      var items = rowItems.map((item: ImageItemValue, ii: number) =>
        this.renderItem(
          "item" + ii,
          item,
          cssClasses
        )
      );
      return (
        <div 
          key={"row-" + ci} 
          className={this.question.getColumnClass()+ " row-im-picker-item gap-"+(this.question.gap ?? "")} 
          style={{gap: (this.question.gap || 3) * 8}} 
          role="presentation">
          {items}
        </div>
      );
    })
    
    
  }

  protected getColumns(cssClasses: any) {
    return this.question.columns.map((column: any, ci: number) => {
      var items = column.map((item: any, ii: number) =>
        this.renderItem(
          "item" + ii,
          item,
          cssClasses
        )
      );
      return (
        <div key={"column" + ci} className={this.question.getColumnClass()} role="presentation">
          {items}
        </div>
      );
    });
  }

  protected getItems(cssClasses: any): Array<any> {
    var items: JSX.Element[] = [];
    for (var i = 0; i < this.question.visibleChoices.length; i++) {
      var item = this.question.visibleChoices[i];
      if (item.value === 'other') continue;
      var key = "item" + i;
      items.push(this.renderItem(key, item as ImageItemValue, cssClasses));
    }
    return items;
  }
  protected getItemOther(cssClasses: any): any {
    var item = this.question.visibleChoices?.find((ch: ImageItemValue) => ch.value === 'other');
    return this.renderItem('item other', item as ImageItemValue, cssClasses);
  }
  protected get textStyle(): any {
    return { marginLeft: "3px", display: "inline", position: "static" };
  }
  protected renderItem(
    key: string,
    item: ImageItemValue,
    cssClasses: any
  ): JSX.Element {
    const renderedItem = <SurveyQuestionImagePickerItem key={key} question={this.question} item={item} cssClasses={cssClasses}></SurveyQuestionImagePickerItem>;
    const survey = this.question.survey as SurveyModel;
    let wrappedItem: JSX.Element | null = null;
    if(!!survey) {
      wrappedItem = ReactSurveyElementsWrapper.wrapItemValue(survey, renderedItem, this.question, item);
    }
    return wrappedItem ?? renderedItem;
  }
  protected renderOther(): JSX.Element {
    let cssClasses = this.question.cssClasses;
    return (
      <div className="form-group">
        <SurveyQuestionCommentItem
          question={this.question}
          otherCss={cssClasses.other}
          cssClasses={cssClasses}
          isDisplayMode={this.isDisplayMode}
        />
      </div>
    );
  }
}
export class SurveyQuestionImagePickerItem extends ReactSurveyElement {
  protected imageRef: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.imageRef = React.createRef();
  }
  protected getStateElement() {
    return this.item;
  }
  componentDidMount() {
    super.componentDidMount();
    this.reactOnStrChanged();
  }
  componentWillUnmount() {
    super.componentWillUnmount();
    this.item.locImageLink.onChanged = function () {};
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    super.componentDidUpdate(prevProps, prevState);
    this.reactOnStrChanged();
  }
  private reactOnStrChanged() {
    this.item.locImageLink.onChanged = () => {
      this.setState({ locImageLinkchanged: !!this.state && this.state.locImageLink ? this.state.locImageLink + 1 : 1 });
    };
  }
  protected get cssClasses() {
    return this.props.cssClasses;
  }

  protected get item() {
    return this.props.item;
  }

  protected get question() {
    return this.props.question;
  }

  handleOnChange(event: any) {
    if (this.question.multiSelect) {
      if (event.target.checked) {
        if (event.target.value === '__na') {
          this.question.renderedValue = ['__na'];
        }else{
          let questionValue = this.question.renderedValue || this.question.value || [];
          questionValue = Array.isArray(questionValue) ? questionValue : []
          questionValue = questionValue.concat(event.target.value)
          const naIndex = questionValue.indexOf('__na')
          if (naIndex >= 0) {
            questionValue.splice(naIndex, 1);
          }
          this.question.renderedValue = questionValue;
        }
      } else {
        var currValue = this.question.renderedValue;
        currValue.splice(this.question.renderedValue.indexOf(event.target.value), 1);
        this.question.renderedValue = currValue;
      }
    } else {
      this.question.value = event.target.value;
    }
    this.setState({ value: this.question.value });
  }

  handleZoomChange  =  (shouldZoom: boolean) => {
    const zoomContainer = this.imageRef.current;
    if (zoomContainer) {
      setTimeout(function() {
        zoomContainer.style.display = "none";
      }, 400);
      this.setState({ isZoomed: shouldZoom });
    }
  }
  handleZoomClick = (event: any) => {
    const zoomContainer = this.imageRef.current;
    if (zoomContainer) {
      zoomContainer.style.display = "block";
    }
    this.setState({ isZoomed: true });
  }

  protected renderOtherItem(): JSX.Element {
    const item = this.item;
    const question = this.question;
    const cssClasses = this.cssClasses;
    var isChecked = question.isItemSelected(item);
    var itemClass = question.getItemClass(item);
    var text: JSX.Element | null = (
        <span
          className={question.cssClasses.itemText + (item.hasText ? " item-label" : " item-label no-text")}
        >
          {item.hasText ? SurveyElementBase.renderLocString(item.locText) || 'loc-other' : 'ortherrr'}
        </span>
      );
  
    const imageIndex: number = question.visibleChoices.findIndex((image: ImageItemValue) => image.value === item.value)

    return (
      <div className={itemClass + " otherItem"} title={item.hasText ? item.locText?.renderedHtml : imageIndex + 1}>
        <label className={cssClasses.label} style={{ display:'flex', alignItems:'center' }}>
          <input
            className={cssClasses.itemControl}
            id={this.question.getItemId(item)}
            type={this.question.inputType}
            name={this.question.questionName}
            checked={isChecked}
            value={item.value}
            disabled={!this.question.getItemEnabled(item)}
            onChange={this.handleOnChange}
            aria-required={this.question.ariaRequired}
            aria-label={this.question.ariaLabel}
            aria-invalid={this.question.ariaInvalid}
            aria-describedby={this.question.ariaDescribedBy}
          />
          {text}
        </label>
      </div>

    );
  }

  protected renderElement(): JSX.Element {
    const item = this.item;
    const question = this.question;
    const cssClasses = this.cssClasses;
    var isChecked = question.isItemSelected(item);
    var itemClass = question.getItemClass(item);
    var text: JSX.Element | null = null;
    if (question.showLabel) {
      text = (
        <span
          className={question.cssClasses.itemText + (item.hasText ? " item-label" : " item-label no-text")}
        >
          {item.hasText ? SurveyElementBase.renderLocString(item.locText) : ''}
        </span>
      );
    }

    var style: any = { objectFit: this.question.imageFit };
    
    var zoom: JSX.Element | null = null;
    var control: JSX.Element | null = null;
    const imageIndex: number = question.visibleChoices.findIndex((image: ImageItemValue) => image.value === item.value)
    if (item.locImageLink?.renderedHtml && this.question.contentMode === "image") {
      control = (
        <img
          className={cssClasses.image}
          src={item.locImageLink?.renderedHtml}
          width={ this.question.renderedImageWidth }
          height={ this.question.renderedImageHeight }
          alt={item.hasText ? item.locText?.renderedHtml : imageIndex + 1}
          style={style}
          onLoad={(event: any) => { this.question["onContentLoaded"](item, event.nativeEvent); }}
        />
      );
      zoom = <div className="container-zoom-image">
         <button title='Zoom Image' className="btn-zoom-img" onClick={this.handleZoomClick} tabIndex={-1}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><rect x="0" y="0" width="24" height="24" fill="none" stroke="none" /><path fill="currentColor" d="M3 21v-6h2v2.6l3.1-3.1l1.4 1.4L6.4 19H9v2Zm12 0v-2h2.6l-3.1-3.1l1.4-1.4l3.1 3.1V15h2v6ZM8.1 9.5L5 6.4V9H3V3h6v2H6.4l3.1 3.1Zm7.8 0l-1.4-1.4L17.6 5H15V3h6v6h-2V6.4Z"/></svg>
         </button>
          <div ref={this.imageRef} id={'q-name' + this.question.name} className={!!this.state?.isZoomed ? 'showing img-picker-modal' : 'img-picker-modal'} onClick={() =>this.handleZoomChange(false)}>
            <img
              className={!!this.state?.isZoomed ? "image-picker-zoomed img-modal-content" : 'image-picker-zoomout img-modal-content'}
              alt={item.hasText ? item.locText.textOrHtml : imageIndex + 1}
              src={item.locImageLink?.renderedHtml}
            />
          </div>
      </div>
    }
    if (item.locImageLink?.renderedHtml && this.question.contentMode === "video") {
      control = (
        <video controls
          className={cssClasses.image}
          src={item.locImageLink?.renderedHtml}
          width={ this.question.renderedImageWidth }
          height={ this.question.renderedImageHeight }
          style={style}
          onLoadedMetadata={(event: any) => { this.question["onContentLoaded"](item, event.nativeEvent); }}
        ></video>
      );
    }
    if (!item.locImageLink?.renderedHtml) {
      let style: any = {
        width: this.question.renderedImageWidth,
        height: this.question.renderedImageHeight,
        objectFit: this.question.imageFit
      };
      control = (
        <div
          className={cssClasses.itemNoImage}
          style={style}
        >
          {
            cssClasses.itemNoImageSvgIcon ?
              <svg className={cssClasses.itemNoImageSvgIcon}>
                <use xlinkHref={cssClasses.itemNoImageSvgIconId}></use>
              </svg>:
              null
          }
        </div>
      );
    }

    // enable survey => add title={item.text || imageIndex + 1}
    const renderedItem = (
      <div className={itemClass + " sv-q-col-" + this.question.colCount} title={item.hasText ? item.locText?.renderedHtml : imageIndex + 1}>
        {zoom}
        <label className={cssClasses.label} style={{ maxWidth: this.question.renderedImageWidth }}>
          <input
            style={{ display: "none" }}
            className={cssClasses.itemControl}
            id={this.question.getItemId(item)}
            type={this.question.inputType}
            name={this.question.questionName}
            checked={isChecked}
            value={item.value}
            disabled={!this.question.getItemEnabled(item)}
            onChange={this.handleOnChange}
            aria-required={this.question.ariaRequired}
            aria-label={this.question.ariaLabel}
            aria-invalid={this.question.ariaInvalid}
            aria-describedby={this.question.ariaDescribedBy}
          />
          <div className={this.question.cssClasses.itemDecorator}>
            <div className={this.question.cssClasses.imageContainer}>
              {control}
            </div>
          </div>
          {text}
        </label>
      </div>
    );
    
    if (item.value === 'other') return this.renderOtherItem();
    return renderedItem;
  }
}

ReactQuestionFactory.Instance.registerQuestion("imagepicker", (props) => {
  return React.createElement(SurveyQuestionImagePicker, props);
});
