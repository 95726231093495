import React, { createContext, useContext } from 'react';
import { Ability, AbilityBuilder } from '@casl/ability';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const ability = new Ability();

const updateUserRules = (permissions) => {
   ability.update(defineRulesFor(permissions));
};

const defineRulesFor = (permissions) => {
   const { can, rules } = new AbilityBuilder();
   if (permissions && Array.isArray(permissions)) {
      permissions.forEach((per) => {
         const [subject, action] = per.split('__');
         can(action, subject);
      });
   }

   return rules;
};

const abilityCanDo = (actions, subject) => {
   if (Array.isArray(actions) && Array.isArray(subject)) {
      // [(edit, delete],[user, cutomer] ) =>  can edit or delete  each "user" and "cutomer"; (if cant delete/edit some of them => false)
      // edit->user ok  and  edit->cutomer ok => true
      // delete->user ok  and  delete->cutomer ok => true
      // edit->user ok  and  edit->cutomer notok => false
      // edit->user ok  and  edit->cutomer notok   and      delete->user ok  and  delete->cutomer ok  => true
      // edit->user notok  and  edit->cutomer notok   and      delete->user notok  and  delete->cutomer notok  => false
      return actions.some((action) => subject.every((sub) => ability.can(action, sub)));
   }
   if (Array.isArray(actions)) {
      // ([edit, delete],user)  =>  can edit or delete   user
      return actions.some((action) => ability.can(action, subject));
   }
   if (Array.isArray(subject)) {
      // (edit, [user, cutomer])  =>  can edit each "user" and "cutomer"  (if cant edit some of them => false)
      return subject.every((sub) => ability.can(actions, sub));
   }
   return ability.can(actions, subject);
};
const abilityCannotDo = (actions, subject) => {
   if (Array.isArray(actions) && Array.isArray(subject)) {
      return actions.every((action) => subject.every((sub) => ability.cannot(action, sub)));
   }
   if (Array.isArray(actions)) {
      return actions.every((action) => ability.cannot(action, subject));
   }
   if (Array.isArray(subject)) {
      return subject.every((sub) => ability.cannot(actions, sub));
   }
   return ability.cannot(actions, subject);
};
const getAllMyRules = () => {
   return ability.rules;
};

const roleAbilty =
   (currentRoles = []) =>
   (target, isSkipPrimaryAdmin = false) => {
      if (!isSkipPrimaryAdmin) {
         const isPrimaryAdmin = currentRoles.some((role) => role === 'PRIMARY_ADMIN');
         if (isPrimaryAdmin) {
            return true;
         }
      }
      return currentRoles.some((role) => role === target);
   };

const PermissionServiceContext = createContext({
   can: (actions, subject) => void 0,
   cannot: (actions, subject) => void 0,
   getAllMyRules: () => void 0,
   role: () => void 0
});

export const usePermissions = () => useContext(PermissionServiceContext);

export const PermissionsProvider = ({ children, store }) => {
   let currentPermissions;
   store.subscribe(() => {
      const prevPermissions = currentPermissions;
      currentPermissions = store.getState().authen.user?.operations;
      if (!_.isEqual(prevPermissions, currentPermissions)) {
         updateUserRules(currentPermissions);
      }
   });
   const roles = useSelector((state) => state.authen.user?.role_code);
   const permissions = {
      can: abilityCanDo,
      cannot: abilityCannotDo,
      getAllMyRules: getAllMyRules,
      role: roleAbilty(roles)
   };
   return <PermissionServiceContext.Provider value={permissions}>{children}</PermissionServiceContext.Provider>;
};

// ------------------How to use---------------------
// note
//can(action: string|array, subject: sting): boolean

// can("EDIT","USERS")
// can(["VIEW","EDIT"],"USERS")

// -------example-----------
// import { usePermissions } from 'src/hooks/usePermissions';

// const { can } = usePermissions();

// <Button disabled={!can("ADD","USERS")} variant="contained" color="primary">
// add user
// </Button>

// <Button disabled={!can(["ADD","EDIT"],"USERS")} variant="contained" color="primary">
// add or edit user
// </Button>

// if (!can(["EDIT","VIEW","ADD"], "ROLE")) {
//     return <NoPermissionView />
// }

// -------------------------

// REFF DOC
// [
//   {
//     subject: 'PACKAGE',
//     action: ['ADD', 'DELETE', 'EDIT', 'VIEW']
//   },
//   {
//     subject: 'ROLE',
//     action: ['ADD', 'DELETE', 'EDIT', 'VIEW']
//   },
//   {
//     subject: 'USERS',
//     action: ['ADD', 'DELETE', 'EDIT', 'VIEW', 'RESET']
//   }
// ];
