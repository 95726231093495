import { useStore, useDispatch } from 'react-redux';
import { axiosToken } from 'src/common/AxiosToken';
import { setToken, loggedOut } from 'src/redux/actions/authenAction';

export function useAuth() {
   const store = useStore();
   const dispatch = useDispatch();
   const { isAuth, user } = store.getState().authen;

   const login = async (submitData) => {
      const linklogin = '/session';
      try {
         const { data } = await axiosToken.post(linklogin, submitData);
         const result = await dispatch(setToken(data.token));
         if (result.data === 'unsuccess') {
            throw result.error;
         }
         return { status: 'login-success' };
      } catch (error) {
         throw error;
      }
   };

   const logout = async () => {
      const linkDeltoken = '/session';
      try {
         await axiosToken.delete(linkDeltoken);
         localStorage.removeItem('access-token');
         localStorage.removeItem('userid');
         dispatch(loggedOut());
         return { status: 'logout-success' };
      } catch (error) {
         console.log('error', error);
         throw error;
      }
   };

   return {
      isAuth,
      user,
      login,
      logout
   };
}
