import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import _, { toLower, toUpper } from 'lodash';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

// import {
//     getCustomerOrders,
//     // displayLoading
// } from 'src/redux/actions/customerOrdersAction';
import { axiosToken } from 'src/common/AxiosToken';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useDialog } from 'src/hooks/dialog';
import LoadingBackdrop from 'src/components/loading/LoadingBackdrop';
import { getOrderById, displayLoading } from 'src/redux/actions/customerOrderAction';
import Autocomplete from 'src/components/Autocomplete';

const EditReceipt = ({ open, close, data, orderId }) => {
   const dispatch = useDispatch();
   const isMountedRef = useIsMountedRef();
   const { enqueueSnackbar } = useSnackbar();
   const dialog = useDialog();

   const [loading, setLoading] = useState([]);
   const [paymentInfo, setPaymentInfo] = useState({
      country_code: 'th',
      postal_code: '',
      info: {
         province: {},
         amphure: {},
         district: {}
      }
   });
   const [countries, setCountries] = useState([]);
   const [provinces, setProvinces] = useState([]);
   const [amphures, setAmphures] = useState([]);
   const [districts, setDistricts] = useState([]);
   const [getTaxInvoice, setGetTaxInvoice] = useState(false);
   const [errors, setErrors] = useState({});

   const handleChange = (event) => {
      if (event.target.name === 'country_code') {
         if (event.target.value === 'foreign') {
            setPaymentInfo({
               ...paymentInfo,
               [event.target.name]: event.target.value,
               type: 'individual',
               need_tax: false,
               info: {}
            });
         } else {
            setPaymentInfo({
               ...paymentInfo,
               [event.target.name]: event.target.value,
               info: {
                  province: {},
                  amphure: {},
                  district: {}
               }
            });
         }
      } else {
         setPaymentInfo({
            ...paymentInfo,
            [event.target.name]: event.target.value
         });
      }

      if (event.target.name === 'type') {
         setGetTaxInvoice(event.target.value === 'company');
      }
      setErrors({ ...errors, [event.target.name]: null });
   };
   // const handleChangeInfo = (event) => {
   //     // for save taxid and branch
   //     setPaymentInfo({
   //         ...paymentInfo,
   //         info: {
   //             ...paymentInfo.info,
   //             [event.target.name]: event.target.value
   //         }
   //     })
   // }

   const handleSave = () => {
      const validateFullTax = {
         info: Yup.object().when('country_code', {
            is: 'th',
            then: Yup.object({
               province: Yup.object({
                  id: Yup.number().required('Province is required')
               }),
               amphure: Yup.object({
                  id: Yup.number().required('Amphure is required')
               }),
               district: Yup.object({
                  id: Yup.number().required('District is required')
               })
            })
         }),
         postal_code: Yup.string()
            .max(5, 'Must be exactly 5 digits')
            .min(5, 'Must be exactly 5 digits')
            .required('Post Code is required')
            .nullable(),
         tax_id: Yup.string()
            .when(['country_code', 'type'], {
               is: (country_code, type) => country_code === 'th' && type === 'company',
               then: Yup.string()
                  .min(10, 'Minimum 10 digits')
                  .max(15, 'Maximum 15 digits')
                  .matches(/^\d+$/, 'Data is not valid')
                  .required('Tax id is required')
            })
            .when(['country_code', 'type'], {
               is: (country_code, type) => country_code === 'th' && type === 'individual',
               then: Yup.string()
                  .min(13, 'Must be exactly 13 digits')
                  .max(13, 'Must be exactly 13 digits')
                  .matches(/^\d+$/, 'Data is not valid')
                  .required('Tax id is required')
            })
            .nullable()
      };
      const validateCompany = {
         contact_name: Yup.string().max(255).required('Contact name is required').nullable(),
         branch: Yup.string().max(255).required('Branch is required').nullable()
      };
      const validationSchema = Yup.object().shape({
         address: Yup.string()
            .max(255)
            .max(255).nullable(),
         // .when('country_code', {
         //    is: 'foreign',
         //    then: Yup.string().required('Address is required').nullable(),
         //    otherwise: Yup.string().nullable()
         // }),
         // cc_email: Yup.string().email('Must be a valid email').max(255).nullable(),
         country_code: Yup.mixed()
            .oneOf(countries.map(({ code }) => code))
            .required('Country is required'),
         email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
         name: Yup.string().max(255).required('Name is required'),
         // phone_no: Yup.string().when('type', {
         //       is: 'individual',
         //       then: Yup.string().matches(/^[0-9]{9,10}$/, 'The phone number is malformed.').required('Phone is required')
         //    }),
         // type: Yup.mixed().oneOf(['individual', 'company']).required('Type is required'),
         type: Yup.string()
            .when('country_code', {
               is: 'th',
               then: Yup.string().oneOf(['individual', 'company']).required('Type is required')
            })
            .nullable(),
         ...(getTaxInvoice ? validateFullTax : {}),
         ...(paymentInfo.type === 'company' ? validateCompany : {})
      });

      validationSchema
         .validate(paymentInfo, { abortEarly: false })
         .then(async (res) => {
            setLoading((prevLoad) => [...prevLoad, 'saving']);
            try {
               dispatch(displayLoading(true));
               const result = await axiosToken.put(`order/${res.id}/edit-payment-info`, {
                  ...paymentInfo,
                  need_tax: getTaxInvoice
               });
               enqueueSnackbar(result.data.message, {
                  variant: result.data.status
               });
               const shouldLoading = false;
               dispatch(getOrderById(orderId, shouldLoading));
               setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'saving'));
               close();
            } catch (error) {
               if (error.response?.data) {
                  setErrors(error.response?.data);
               } else {
                  dialog.error(error);
               }
               setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'saving'));
               dispatch(displayLoading(false));
            }
         })
         .catch((err) => {
            console.log('err', err);
            const errorsState = _.mapValues(_.keyBy(err.inner, 'path'), 'errors');
            console.log('errorsState', errorsState);
            setErrors(errorsState);
         });
   };

   const debounceGetAddressByZipcode = useCallback(
      _.debounce(async (zipcode) => {
         if (zipcode === '') return;
         setLoading((prevLoad) => [...prevLoad, 'zipcode']);
         try {
            const res = await axiosToken.get(`location/thai/zipcode/${zipcode}`);
            if (res.data.length) {
               const addresses = res.data;
               setAmphures([]);
               setDistricts([]);
               setErrors((prevErr) => ({
                  ...prevErr,
                  'info.province.id': null,
                  'info.amphure.id': null,
                  'info.district.id': null
               }));
               setPaymentInfo((prevPaymentInfo) => ({
                  ...prevPaymentInfo,
                  info: {
                     ...prevPaymentInfo.info,
                     ...addresses[0]
                  }
               }));
            } else {
               setErrors((prevErr) => ({
                  ...prevErr,
                  postal_code: ['The postal code is invalid.']
               }));
            }
            setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'zipcode'));
         } catch (error) {
            setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'zipcode'));
            setErrors((prevErr) => ({
               ...prevErr,
               postal_code: error.response?.data?.zip_code || [
                  'An error occurred while getting addresse info. please try again later'
               ]
            }));
         }
      }, 1000),
      []
   );

   const handleZipcodeChange = async (event) => {
      const zipcodeReg = /^\d+$/;
      if (event.target.value !== '' && !zipcodeReg.test(event.target.value)) return;

      setErrors((prevErr) => ({ ...prevErr, postal_code: null }));
      setPaymentInfo({
         ...paymentInfo,
         [event.target.name]: event.target.value
      });
      debounceGetAddressByZipcode(event.target.value);
   };

   const handleProvinceChange = (e) => {
      setErrors({
         ...errors,
         'info.province.id': null
      });
      setAmphures([]);
      setDistricts([]);
      setPaymentInfo({
         ...paymentInfo,
         postal_code: '',
         info: {
            ...paymentInfo.info,
            province: provinces.find((province) => province.id === e.target.value) || {},
            amphure: {},
            district: {}
         }
      });
   };
   const handleAmphureChange = (e) => {
      setErrors({
         ...errors,
         'info.amphure.id': null
      });
      setDistricts([]);
      setPaymentInfo({
         ...paymentInfo,
         postal_code: '',
         info: {
            ...paymentInfo.info,
            amphure: amphures.find((amphure) => amphure.id === e.target.value) || {},
            district: {}
         }
      });
   };
   const handleDistrictChange = (e) => {
      setErrors({
         ...errors,
         postal_code: null,
         'info.district.id': null
      });
      setPaymentInfo({
         ...paymentInfo,
         info: {
            ...paymentInfo.info,
            district: districts.find((district) => district.id === e.target.value) || {}
         },
         postal_code: districts.find((district) => district.id === e.target.value)?.zip_code || ''
      });
   };

   const getDistricts = useCallback(
      (amphureId) => {
         setLoading((prevLoad) => [...prevLoad, 'districts']);
         axiosToken
            .get(`location/thai/amphure/${amphureId}/districts`)
            .then((response) => {
               if (isMountedRef.current) {
                  setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'districts'));
                  setDistricts(response.data);
               }
            })
            .catch((error) => {
               setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'districts'));
               console.log(error);
            });
      },
      [isMountedRef]
   );

   const getAmphures = useCallback(
      (provinceId) => {
         setLoading((prevLoad) => [...prevLoad, 'amphures']);
         axiosToken
            .get(`location/thai/province/${provinceId}/amphures`)
            .then((response) => {
               if (isMountedRef.current) {
                  setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'amphures'));
                  setAmphures(response.data);
               }
            })
            .catch((error) => {
               setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'amphures'));
               console.log(error);
            });
      },
      [isMountedRef]
   );

   const getProvinces = useCallback(() => {
      setLoading((prevLoad) => [...prevLoad, 'provinces']);
      axiosToken
         .get('location/thai/provinces')
         .then((response) => {
            if (isMountedRef.current) {
               setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'provinces'));
               setProvinces(response.data);
            }
         })
         .catch((error) => {
            setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'provinces'));
            console.log(error);
         });
   }, [isMountedRef]);

   const getCountries = useCallback(() => {
      setLoading((prevLoad) => [...prevLoad, 'countries']);
      axiosToken
         .get('location/countries')
         .then((response) => {
            if (isMountedRef.current) {
               setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'countries'));
               setCountries(response.data.map((c) => ({ ...c, code: c.code.toLowerCase() })));
            }
         })
         .catch((error) => {
            setLoading((prevLoad) => prevLoad.filter((ld) => ld !== 'countries'));
            console.log(error);
         });
   }, [isMountedRef]);

   useEffect(() => {
      getCountries();
   }, [getCountries]);

   useEffect(() => {
      getProvinces();
   }, [getProvinces]);

   useEffect(() => {
      if (paymentInfo.info.province?.id) {
         getAmphures(paymentInfo.info.province.id);
      }
   }, [getAmphures, paymentInfo.info.province]);

   useEffect(() => {
      if (paymentInfo.info.amphure?.id) {
         getDistricts(paymentInfo.info.amphure.id);
      }
   }, [getDistricts, paymentInfo.info.amphure]);

   useEffect(() => {
      if (open && data) {
         setPaymentInfo({
            ...data,
            info: JSON.parse(data.info) || {}
         });

         if (data?.tax_id && data.tax_id !== '' && data.country_code === 'th') {
            setGetTaxInvoice(true);
         } else {
            setGetTaxInvoice(false);
         }
      } else {
         setErrors({})
      }
   }, [open, data]);

   useEffect(() => {
      if (getTaxInvoice === false) {
         setPaymentInfo({
            id: paymentInfo?.id || null,
            country_code: paymentInfo.country_code,
            type: paymentInfo.type,
            email: paymentInfo.email,
            phone_no: paymentInfo.phone_no,
            name: paymentInfo.name,
            postal_code: '',
            info: {
               province: {},
               amphure: {},
               district: {}
            }
         });
      }
      // eslint-disable-next-line
   }, [getTaxInvoice]);

   const hasError = (field) => {
      return Boolean(errors[field]);
   };

   const onSelectAutocomleteChange = (key) => (e, value, action) => {
      let data;
      if (action === 'select-option') {
         data = value.id.toLowerCase();
      } else if (action === 'clear') {
         data = null;
      }

      let addition;
      if (key === 'country_code') {
         addition = {
            info: {},
            type: 'individual',
         }
         setGetTaxInvoice(false);
      }
      setPaymentInfo((prevState) => ({
         ...prevState,
         ...addition,
         [key]: data
      }));
   };

   const requiredField = <span style={{ color: 'red', marginLeft: '4px' }}>*</span>;

   return (
      <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
         <DialogTitle style={{ textAlign: 'center' }} id="form-dialog-title">
            แก้ไขข้อมูล
         </DialogTitle>
         <DialogContent>
            {loading.length > 0 && <LoadingBackdrop loading={loading.length > 0} />}
            <FormControl fullWidth>
               <Grid container alignItems="center">
                  <Grid item xs={12}>
                     <Typography variant="body2">ประเทศ:{requiredField}</Typography>
                     <Autocomplete
                        fullWidth
                        size="small"
                        id="country_code"
                        disableClearable
                        options={countries.map((option) => ({ id: option.code, name: option.name_en }))}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, { value }) => option.id === value}
                        value={paymentInfo.country_code}
                        onChange={onSelectAutocomleteChange('country_code')}
                        renderInput={(inputParams) => (
                           <TextField {...inputParams} variant="outlined" margin="dense" />
                        )}
                        style={{ width: '390px' }}
                        renderOption={(option) => `${option.name}`}
                     />
                     {hasError('country_code') && (
                        <Typography color="error" variant="body2">
                           {errors.type[0]}
                        </Typography>
                     )}
                  </Grid>

                  {paymentInfo.country_code === 'th' && (
                     <>
                        <Grid item xs={12}>
                           <Typography variant="body2">ออกในนาม:{requiredField}</Typography>
                           <RadioGroup
                              name="type"
                              onChange={handleChange}
                              value={paymentInfo.type || ''}
                              row
                              aria-label="position"
                              defaultValue="individual"
                           >
                              <FormControlLabel value="individual" control={<Radio color="primary" />} label="บุคคล" />
                              <FormControlLabel value="company" control={<Radio color="primary" />} label="นิติบุคคล" />
                           </RadioGroup>
                           {hasError('type') && (
                              <Typography color="error" variant="body2">
                                 {errors.type[0]}
                              </Typography>
                           )}
                        </Grid>
                        {paymentInfo.type === 'individual' && (
                           <>
                              <Grid item xs={12}>
                                 <Typography variant="body2">ชื่อ:{requiredField}</Typography>
                                 <TextField
                                    multiline
                                    value={paymentInfo.name || ''}
                                    margin="dense"
                                    autoFocus
                                    id="name"
                                    name="name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    error={hasError('name')}
                                    helperText={hasError('name') && errors.name[0]}
                                    style={{ width: '390px' }}
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <Typography variant="body2">อีเมล:{requiredField}</Typography>
                                 <TextField
                                    value={paymentInfo.email || ''}
                                    margin="dense"
                                    id="email"
                                    type="email"
                                    name="email"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    error={hasError('email')}
                                    helperText={hasError('email') && errors.email[0]}
                                    style={{ width: '390px' }}
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <Typography variant="body2">เบอร์โทร:</Typography>
                                 <TextField
                                    value={paymentInfo.phone_no || ''}
                                    margin="dense"
                                    id="phone_no"
                                    type="phone_no"
                                    name="phone_no"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    error={hasError('phone_no')}
                                    helperText={hasError('phone_no') && errors.phone_no[0]}
                                    style={{ width: '390px' }}
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <Box py={1} />
                                 <Divider />
                                 <Box display="flex" alignItems="center">
                                    <Checkbox
                                       color="secondary"
                                       onChange={(event) => setGetTaxInvoice(event.target.checked)}
                                       checked={getTaxInvoice}
                                       name="getTaxInvoice"
                                    />
                                    <Typography
                                       variant="body2"
                                       style={{
                                          fontWeight: 'bold'
                                       }}
                                    >
                                       ขอใบกำกับภาษีอย่างเต็ม
                                    </Typography>
                                 </Box>
                              </Grid>

                              {getTaxInvoice && (
                                 <Grid item xs={12}>
                                    <Typography variant="body2">
                                       เลขบัตรประชาชน:
                                       {requiredField}
                                    </Typography>
                                    <TextField
                                       value={paymentInfo.tax_id || ''}
                                       name="tax_id"
                                       margin="dense"
                                       id="tax_id"
                                       type="text"
                                       fullWidth
                                       variant="outlined"
                                       onChange={handleChange}
                                       error={hasError('tax_id')}
                                       helperText={hasError('tax_id') && errors.tax_id[0]}
                                       style={{ width: '390px' }}
                                    />
                                 </Grid>
                              )}
                           </>
                        )}
                        {paymentInfo.type === 'company' && (
                           <>
                              <Grid item xs={12}>
                                 <Typography variant="body2">ชื่อผู้ติดต่อ:{requiredField}</Typography>
                                 <TextField
                                    multiline
                                    value={paymentInfo.contact_name || ''}
                                    margin="dense"
                                    autoFocus
                                    id="contact_name"
                                    name="contact_name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    error={hasError('contact_name')}
                                    helperText={hasError('contact_name') && errors.contact_name[0]}
                                    style={{ width: '390px' }}
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <Typography variant="body2">อีเมล:{requiredField}</Typography>
                                 <TextField
                                    value={paymentInfo.email || ''}
                                    margin="dense"
                                    id="email"
                                    type="email"
                                    name="email"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    error={hasError('email')}
                                    helperText={hasError('email') && errors.email[0]}
                                    style={{ width: '390px' }}
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <Typography variant="body2">เบอร์โทร:</Typography>
                                 <TextField
                                    value={paymentInfo.phone_no || ''}
                                    margin="dense"
                                    id="phone_no"
                                    type="phone_no"
                                    name="phone_no"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    error={hasError('phone_no')}
                                    helperText={hasError('phone_no') && errors.phone_no[0]}
                                    style={{ width: '390px' }}
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <Typography variant="body2">ชื่อบริษัท:{requiredField}</Typography>
                                 <TextField
                                    multiline
                                    value={paymentInfo.name || ''}
                                    margin="dense"
                                    autoFocus
                                    id="name"
                                    name="name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    error={hasError('name')}
                                    helperText={hasError('name') && errors.name[0]}
                                    style={{ width: '390px' }}
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <Typography variant="body2">สำนักงาน/สาขา:{requiredField}</Typography>
                                 <TextField
                                    margin="dense"
                                    id="branch"
                                    name="branch"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={paymentInfo.branch || ''}
                                    error={hasError('branch')}
                                    helperText={hasError('branch') && errors.branch[0]}
                                    style={{ width: '390px' }}
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <Typography variant="body2">
                                    เลขประจำตัวผู้เสียภาษี:
                                    {requiredField}
                                 </Typography>
                                 <TextField
                                    value={paymentInfo.tax_id || ''}
                                    name="tax_id"
                                    margin="dense"
                                    id="tax_id"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    error={hasError('tax_id')}
                                    helperText={hasError('tax_id') && errors.tax_id[0]}
                                    style={{ width: '390px' }}
                                 />
                              </Grid>
                           </>
                        )}
                        {getTaxInvoice && paymentInfo.type && (
                           <>
                              <Grid item xs={12}>
                                 <Typography variant="body2">รหัสไปรษณีย์:{requiredField}</Typography>
                                 <TextField
                                    margin="dense"
                                    name="postal_code"
                                    id="postal_code"
                                    // onChange={handleChange}
                                    onChange={handleZipcodeChange}
                                    required
                                    value={paymentInfo.postal_code || ''}
                                    variant="outlined"
                                    type="text"
                                    readOnly={true}
                                    error={hasError('postal_code')}
                                    helperText={hasError('postal_code') && errors.postal_code[0]}
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 <Typography variant="body2">จังหวัด:{requiredField}</Typography>
                                 <FormControl
                                    margin="dense"
                                    fullWidth
                                    error={hasError('info.province.id')}
                                    style={{ width: '390px' }}
                                 >
                                    <Select
                                       displayEmpty
                                       name="province"
                                       id="province"
                                       onChange={handleProvinceChange}
                                       required
                                       value={paymentInfo.info.province?.id || ''}
                                       variant="outlined"
                                    >
                                       <MenuItem value="">--เลือกจังหวัด--</MenuItem>
                                       {provinces.map((province) => (
                                          <MenuItem key={province?.id} value={province?.id}>
                                             {province.name_th}
                                          </MenuItem>
                                       ))}
                                    </Select>
                                    <FormHelperText>
                                       {hasError('info.province.id') ? errors['info.province.id'][0] : null}
                                    </FormHelperText>
                                 </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                 <Typography variant="body2">เขต/อำเภอ:{requiredField}</Typography>
                                 <FormControl
                                    margin="dense"
                                    fullWidth
                                    error={hasError('info.amphure.id')}
                                    style={{ width: '390px' }}
                                 >
                                    <Select
                                       name="amphure"
                                       id="amphure"
                                       onChange={handleAmphureChange}
                                       required
                                       displayEmpty
                                       value={paymentInfo.info?.amphure?.id || ''}
                                       variant="outlined"
                                       error={hasError('info.amphure.id')}
                                    // disabled={amphures.length === 0}
                                    >
                                       <MenuItem value="">--เลือกเขต/อำเภอ--</MenuItem>
                                       {amphures.length === 0 && paymentInfo.info.amphure?.id && (
                                          <MenuItem value={paymentInfo.info.amphure?.id}>--loading...--</MenuItem>
                                       )}
                                       {amphures.map((amphure) => (
                                          <MenuItem key={amphure?.id} value={amphure?.id}>
                                             {amphure?.name_th}
                                          </MenuItem>
                                       ))}
                                    </Select>
                                    <FormHelperText>
                                       {hasError('info.amphure.id') ? errors['info.amphure.id'][0] : null}
                                    </FormHelperText>
                                 </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                 <Typography variant="body2">แขวง/ตำบล:{requiredField}</Typography>
                                 <FormControl
                                    margin="dense"
                                    fullWidth
                                    error={hasError('info.district.id')}
                                    style={{ width: '390px' }}
                                 >
                                    <Select
                                       displayEmpty
                                       name="district"
                                       id="district"
                                       onChange={handleDistrictChange}
                                       required
                                       value={paymentInfo.info.district?.id || ''}
                                       variant="outlined"
                                       error={hasError('info.district.id')}
                                    // disabled={districts.length === 0}
                                    >
                                       <MenuItem value="">--เลือกแขวง/ตำบล--</MenuItem>
                                       {districts.length === 0 && paymentInfo.info.district?.id && (
                                          <MenuItem value={paymentInfo.info.district?.id}>--loading...--</MenuItem>
                                       )}
                                       {districts.map((district) => (
                                          <MenuItem key={district?.id} value={district?.id}>
                                             {district?.name_th}
                                          </MenuItem>
                                       ))}
                                    </Select>
                                    <FormHelperText>
                                       {hasError('info.district.id') ? errors['info.district.id'][0] : null}
                                    </FormHelperText>
                                 </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                 <Typography variant="body2">
                                    ที่อยู่: {paymentInfo.country_code === 'th' && '(เพิ่มเติม)'}:
                                 </Typography>
                                 <TextField
                                    multiline
                                    minRows="3"
                                    margin="dense"
                                    id="address"
                                    name="address"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={paymentInfo.address || ''}
                                    onChange={handleChange}
                                    error={hasError('address')}
                                    helperText={hasError('address') && errors.address[0]}
                                    style={{ width: '390px' }}
                                 />
                              </Grid>
                              <Grid item xs={12} style={{ marginBottom: 10 }}>
                                 <Box
                                    mt={1}
                                    p={1}
                                    bgcolor="#F5F5F5"
                                    borderRadius="4px"
                                    style={{ width: '400px', overflowY: 'auto' }}
                                 >
                                    <Typography variant="body2">ที่อยู่:</Typography>
                                    <Typography
                                       variant="body2"
                                       style={{
                                          maxWidth: '400px',
                                          whiteSpace: 'pre-wrap',
                                          overflowWrap: 'break-word' // Handle long words
                                       }}
                                    >
                                       {`${paymentInfo.address || ''}`}{' '}
                                    </Typography>
                                    <Typography>
                                       {` ${paymentInfo.info?.district?.name_th ? 'แขวง/ตำบล :' + paymentInfo.info.district.name_th : ''} 
                                          ${paymentInfo.info?.amphure?.name_th ? 'เขต/อำเภอ : ' + paymentInfo.info.amphure.name_th : ''}
                                       `}
                                    </Typography>
                                    <Typography variant="body2">
                                       {` ${paymentInfo.info?.province?.name_th ? 'จังหวัด :' + paymentInfo.info.province.name_th : ''}
                                          ${paymentInfo.postal_code ? 'รหัสไปรษณีย์ : ' + paymentInfo.postal_code : ''}
                                       `}
                                       <br />
                                    </Typography>
                                 </Box>
                              </Grid>
                           </>
                        )}
                     </>
                  )}

                  {paymentInfo.country_code !== 'th' && (
                     <>
                        <Grid item xs={12}>
                           <Typography variant="body2">ชื่อ:{requiredField}</Typography>
                           <TextField
                              value={paymentInfo.name || ''}
                              margin="dense"
                              autoFocus
                              id="name"
                              name="name"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={handleChange}
                              error={hasError('name')}
                              helperText={hasError('name') && errors.name[0]}
                              style={{ width: '400px' }}
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <Typography variant="body2">อีเมล:{requiredField}</Typography>
                           <TextField
                              value={paymentInfo.email || ''}
                              margin="dense"
                              id="email"
                              type="email"
                              name="email"
                              fullWidth
                              variant="outlined"
                              onChange={handleChange}
                              error={hasError('email')}
                              helperText={hasError('email') && errors.email[0]}
                              style={{ width: '400px' }}
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <Typography variant="body2">เบอร์โทร:</Typography>
                           <TextField
                              value={paymentInfo.phone_no || ''}
                              margin="dense"
                              id="phone_no"
                              type="phone_no"
                              name="phone_no"
                              fullWidth
                              variant="outlined"
                              onChange={handleChange}
                              error={hasError('phone_no')}
                              helperText={hasError('phone_no') && errors.phone_no[0]}
                              style={{ width: '400px' }}
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant="body2">
                              ที่อยู่:
                           </Typography>
                           <TextField
                              multiline
                              minRows={3}
                              margin="dense"
                              id="address"
                              name="address"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={paymentInfo.address || ''}
                              onChange={handleChange}
                              error={hasError('address')}
                              helperText={hasError('address') && errors.address[0]}
                              style={{ width: '400px' }}
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant="body2">Tax id:</Typography>
                           <TextField
                              value={paymentInfo.tax_id || ''}
                              name="tax_id"
                              margin="dense"
                              id="tax_id"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={handleChange}
                              error={hasError('tax_id')}
                              helperText={hasError('tax_id') && errors.tax_id[0]}
                              style={{ width: '400px' }}
                           />
                        </Grid>
                     </>
                  )}
               </Grid>
            </FormControl>
         </DialogContent>
         <DialogActions style={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={close} color="primary">
               ยกเลิก
            </Button>
            <Button variant="contained" onClick={handleSave} color="primary">
               บันทึก
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default EditReceipt;
