import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
// import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import NProgress from 'nprogress';
import { useSnackbar } from 'notistack';
import { useDialog } from 'src/hooks/dialog';
import Logo from 'src/components/logo/NormalLogo';
import { useAuth } from 'src/hooks/useAuth';
import { loggedOut } from 'src/redux/actions/authenAction';

const useStyles = makeStyles(() => ({
   root: {},
   avatar: {
      width: 60,
      height: 60
   }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
   const classes = useStyles();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();
   const dialog = useDialog();
   const { logout } = useAuth();
   // const [notifications] = useState(["noti1", "noti2"]);

   const handleLogoutClick = async () => {
      const res = await dialog.confirm('Confirm Logout ? ');
      if (res) {
         NProgress.start();
         try {
            const result = await logout();
            NProgress.done();
            if (result.status === 'logout-success') {
               enqueueSnackbar('Logout success', {
                  variant: 'success',
                  autoHideDuration: 2000
               });
               navigate('/login', { replace: true });
            } else {
               dialog.error('Ops !! some error occurred please contact your system administrator');
            }
         } catch (error) {
            NProgress.done();
            dialog.error(error);
            console.log('something wrong !!', error);
            if (!Boolean(localStorage.getItem('access-token'))) {
               dispatch(loggedOut());
               navigate('/login', { replace: true });
            }
         }
      }
   };
   return (
      <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
         <Toolbar>
            <RouterLink to="/">
               <Logo />
            </RouterLink>
            <Box flexGrow={1} />
            <Hidden mdDown>
               {/* <IconButton color="inherit">
                        <Badge
                            badgeContent={notifications.length}
                            color="error"
                            // variant="dot"
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton> */}
               <IconButton color="inherit" onClick={handleLogoutClick}>
                  <InputIcon />
               </IconButton>
            </Hidden>
            <Hidden lgUp>
               <IconButton color="inherit" onClick={onMobileNavOpen}>
                  <MenuIcon />
               </IconButton>
            </Hidden>
         </Toolbar>
      </AppBar>
   );
};

TopBar.propTypes = {
   className: PropTypes.string,
   onMobileNavOpen: PropTypes.func
};

export default TopBar;
